.container {
  /* width: 100%; */
  border: 1px solid rgb(255, 255, 255);
}


.main-about-container-about {
  padding-left: 120px;
  margin-top: 10%;
}

.content-aboutdiv {
  display: flex;
}

.about-content-about {
  /* width: 100%; */
  /* border: 1px solid black; */
  margin-top: 2%;
  margin-left: 5%;
}

.about-image-about {
  /* height: 600px !important; */
  width: 40%;
  margin-left: 2%;
  /* border: 1px solid black; */
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  overflow: hidden;
  margin-top: 2%;
  z-index: 999;
}

.about-image-about img {
  overflow: hidden !important;
  z-index: 999;
}

.about-info-about {
  width: 30%;
  margin-left: 2%;
  /* border: 1px solid black; */
  margin-top: 2%;
}

.about-info-about h2 {
  margin-top: 30px;
  font-size: 28px;
  font-weight: 400;
  text-align: justify;
  /* line-height: 1px; */
  color: #757575;
}

.vision-mission-container {
  /* border: 1px solid black; */
  display: flex;
  padding-left: 5%;
  /* padding-right: 22%; */
  padding-top: 2%;
  /* justify-content: space-between; */
}

/* .vision {
  width: 70%;
  padding: 50px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  box-shadow: 0px 0px 5px gray;
} */

.vision {
  width: 70%;
  /* border: 1px solid #88d5ff; */
  padding: 50px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  box-shadow: 0px 0px 10px #9bdafc;
}

.vision h2 {
  text-align: start;
  font-size: 30px;
  font-weight: 700;
}

.vision-img {
  text-align: start;
}

.vision-img img {
  height: 100px;
}

.vision-text {
  width: 90%;
  text-align: start;
}

.vision-text p {
  font-size: 20px;
  color: rgb(80, 78, 78);
}

.mission {
  width: 70%;
  /* border: 1px solid black; */
  padding: 50px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  /* box-shadow: 0px 0px 5px gray; */
  box-shadow: 0px 0px 10px #9bdafc;

}

.mission h2 {
  text-align: start;
  font-size: 30px;
  font-weight: 700;
}

.mission-img {
  text-align: start;
}

.mission-img img {
  height: 100px;
}

.mission-text {
  width: 90%;
  text-align: start;
}

.mission-text p {
  font-size: 20px;
  color: rgb(80, 78, 78);
}


.blur-image-about {
  width: 100%;
  /* height: fit-content; */
  /* padding-top: 150px; */
  overflow: hidden;
  /* border: 1px solid black; */
  margin-top: 30%;
  margin-left: 20%;
}

.blur-image-about img {
  margin-left: -10%;
  height: 450px;
  margin-top: 50px;
  /* border: 1px solid black; */
  animation: zoom-in-zoom-out 3s ease infinite;
}

.blur-image-left {
  width: 100%;
  /* height: fit-content; */
  /* padding-top: 150px; */
  overflow: hidden;
  /* border: 1px solid black; */
  margin-top: -20%;
  margin-left: -55%;

}

.blur-image-left img {
  margin-left: -11%;
  height: 450px;
  margin-top: 50px;
  /* border: 1px solid black; */
  animation: zoom-in-zoom-out 3s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    opacity: 100;
    transform: scale(1, 1);
  }

  50% {
    opacity: 100;
    transform: scale(1.5, 1.5);
  }

  100% {
    opacity: 100;
    transform: scale(1, 1);
  }
}

.journey-container {
  /* border: 1px solid black; */
  margin-top: 4%;

}

.waveimage {
  margin-top: 8%;
  /* animation: 5s slide; */
  /* animation-direction:reverse; */
  /* animation-timing-function:ease-out; */
}

@keyframes slide {
  from {
    margin-left: -100%;
    width: 100%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

/* ======eg. ==== */

svg {
  height: 60vh;
  width: 100%;
  background-color: #1D1F22;
}

path {

  stroke-dasharray: 400;
  stroke-linecap: round;
  animation-timing-function: ease-in;
  animation: draw 6s;
  animation-direction: reverse;
}

@keyframes draw {
  from {
    stroke-dashoffset: 0
  }

  to {
    stroke-dashoffset: -400;
  }
}

/* ======eg. end==== */

.pin1 {
  width: 20%;
  /* border: 1px solid black; */
  margin-left: 12%;
}

.pin4 {
  width: 20%;
  /* border: 1px solid black; */
  margin-left: -1%;
}

.pin2 {
  width: 20%;
  /* border: 1px solid black; */
  margin-top: -8%;
  margin-left: 2%;
}

.pin3 {
  width: 20%;
  /* border: 1px solid black; */
  margin-top: -10%;
  margin-left: -1%;
}

.horizontaldiv {
  margin-top: -130px;

}

.main-pin {
  margin-left: 20% !important;
}

.pinside {
  /* border: 1px solid black; */
  text-align: start;
  margin-top: 5px;
  width: 50%;
}

.pinside p {
  font-size: 30px;
  color: #474A54;
  font-weight: 600;
  margin-left: 20px;
}

.downtext {
  text-align: start;

}

.pin-info-text {
  width: 90%;
  /* border: 1px solid rgb(0, 0, 0); */
  border: 1px solid rgb(255, 255, 255);
  margin-top: 50px;
  padding: 10px;
  margin-left: 10px;
  padding-top: 20px;
  border-top-left-radius: 5px !important;
  box-shadow: 1px 1px 70px rgb(243, 243, 243);
  padding-bottom: 20px;
  background-color: white;
  /* border-color: white; */
  border-radius: 50px;

}

.pin-info-text p {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.downtext img {
  height: 200px;
  /* box-shadow: 0.1px 0.1px 2px gray; */
}

.pinside2 {
  /* border: 1px solid black; */
  text-align: start;
  width: 50%;
  margin-top: 0px;
}

.pin-info-text1 {
  width: 90%;
  /* border: 1px solid rgb(0, 0, 0); */
  border: 1px solid rgb(255, 255, 255);
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px;
  padding-top: 0px;
  border-bottom-left-radius: 5px !important;
  box-shadow: 1px 1px 70px rgb(243, 243, 243);
  padding-bottom: 20px;
  background-color: white;
  /* border-color: white; */
  border-radius: 50px;

}

.pin-info-text1 p {
  font-size: 14px !important;
  font-weight: 700 !important;
  margin-top: 30px !important;
}

.pinside2 p {
  font-size: 30px;
  color: #474A54;
  font-weight: 600;
  margin-left: 20px;
  margin-top: 80px;
}

.downtext2 {
  text-align: start;
}

.downtext2 img {
  height: 200px;
  /* box-shadow: 0.1px 0.1px 2px gray; */
}

.pinside3 {
  /* border: 1px solid black; */
  text-align: start;
  width: 60%;
  margin-top: 0px;
}

.pin-info-text3 {
  width: 90%;
  /* border: 1px solid rgb(0, 0, 0); */
  border: 1px solid rgb(255, 255, 255);
  margin-top: 20px;
  margin-left: 10px;
  padding: 10px;
  padding-top: 0px;
  border-bottom-left-radius: 5px !important;
  box-shadow: 1px 1px 70px rgb(243, 243, 243);
  padding-bottom: 20px;
  background-color: white;
  /* border-color: white; */
  border-radius: 50px;

}

.pin-info-text3 p {
  font-size: 14px !important;
  font-weight: 700 !important;
  margin-top: 30px !important;
}

.pinside3 p {
  font-size: 30px;
  color: #474A54;
  font-weight: 600;
  margin-left: 20px;
  margin-top: 85px;
}

.downtext3 {
  text-align: start;
}

.downtext3 img {
  height: 200px;
  /* box-shadow: 0.1px 0.1px 2px gray; */
}

.hidden {
  display: none;
}

.visible {
  /* width: 100px; */
  /* height: 200px; */
  /* background-color: lightblue; */
  /* margin: 10px; */
}

.slide {
  height: 500px;
  width: 400px !important;
  
  margin-top: 10px;
  /* background-color: red; */
  border-top-right-radius: 70px;
  border-bottom-left-radius: 70px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 50px rgb(236, 236, 236);
  /* margin-left: 50px !important; */
}

.slider-container-about {
  margin-top: 100px;
  /* border: 1px solid black; */
  height: 550px;
}

.slide-image {
  height: 381px;
  /* border: 1px solid black; */
  border-top-right-radius: 70px;
}

.slide-image img {
  width: -webkit-fill-available !important;
  border-top-right-radius: 70px;
  height: 400px;
  cursor: pointer;
}

.slide h1 {
  font-size: 28px;
  font-weight: 700;
  margin-top: 28px;
}

.slide h3 {
  font-size: 20px;
  font-weight: 600;
  margin-top: -15px;
  color: #0090E0;
}

#recteleup {
  display: none;
}

.vision-mission-container-mob {
  display: none;
}

.journey-container-mob {
  display: none;
}

.videoabout {
  border-top-right-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

@media (max-width:450px) {
  .main-about-container-about {
    padding-left: 10px;
    margin-top: 25%;
  }

  .content-aboutdiv {
    display: block;
  }

  .about-info-about {
    width: 90%;
    margin-left: 2%;
    /* border: 1px solid black; */
    margin-top: 2%;
  }

  .about-info-about h2 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    /* line-height: 1px; */
    color: #757575;
  }

  .about-info-about img {
    height: 50px;
  }

  #ele1 {
    display: none;
  }

  .about-image-about {
    /* height: 600px !important; */
    width: 100%;
    margin-top: -50px !important;
    margin-left: -4%;
    /* border: 1px solid black; */
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    z-index: 999 !important;
  }

  .about-image-about img {
    overflow: hidden !important;
    z-index: 999;
    border-top-right-radius:50px !important;
     border-bottom-left-radius:50px !important
  }

  .videoabout {
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
  }

  #rectele {
    text-align: start;
    z-index: -1;
  }

  #rectele img {
    height: 80px !important;
    margin-top: -20px !important;
    margin-left: 121px;
    z-index: -1;
  }

  #levelid1 {
    display: none;
  }

  #rectid1 {
    display: none;
  }

  #recteleup {
    display: block;
    text-align: end;
    z-index: -1;
  }

  #searchid1 {
    display: none;
  }

  #recteleup img {
    height: 80px !important;
    margin-top: 20px !important;
    /* margin-left: 321px; */
    z-index: -1;
  }

  /* ==================visionmission container ================ */
  .vision-mission-container {
    display: none;
  }

  .vision-mission-container-mob {
    display: block;
  }

  .vission-missiondivmob {
    /* border: 1px solid black; */
    width: 90%;
    margin-left: 5%;
  }

  .missionmob {
    /* border: 1px solid black; */
    display: flex;

  }

  .visionmob {
    /* border: 1px solid black; */
    display: flex;
    margin-top: 20px;
  }

  .our-missionelemob {
    width: 30%;
    /* border: 1px solid black; */
  }

  .vision {
    width: 70%;
    /* border: 1px solid black; */
    padding: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 8px rgb(241, 241, 241);
  }

  .vision h2 {
    text-align: start;
    font-size: 22px;
    font-weight: 700;
    margin-top: 5px;
  }

  .vision-img {
    text-align: start;
  }

  .vision-img img {
    height: 60px;
  }

  .vision-text {
    width: 100%;
    text-align: start;
  }

  .vision-text p {
    font-size: 15px;
    margin-top: -8px;
    color: rgb(80, 78, 78);
  }

  .blur-image-slider-mob {
    /* width: 100%; */
    margin-top: -80px;
    overflow: hidden;
    /* border: 1px solid black; */
    margin-left: -20px;

  }

  .blur-image-slider-mob img {
    margin-left: -10%;
    width: 100%;
    height: 180px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  .blur-image-vision-mob {
    /* width: 100%; */
    margin-top: -50px;
    overflow: hidden;
    /* border: 1px solid black; */
    margin-left: -200px;

  }

  .blur-image-vision-mob img {
    margin-left: -10%;
    width: 100%;
    height: 180px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  #bagelevision img {
    height: 70px;
    margin-top: 30px;
  }

  #levelidmission {
    text-align: start;
  }

  #levelidmission IMG {
    height: 70px;
  }

  .journey-container {
    display: none;
  }

  .journey-container-mob {
    display: block;
    margin-top: -80px;
  }

  #bagelejourney {
    display: none;
  }

  .journey-main-mob {
    background-image: url("../../assets/images/about-images/journeylinemob.svg") !important;
    background-repeat: no-repeat;
    background-position: center !important;
    margin-top: 20px;
    height: 810px;
    margin-left: -10px;
  }

  .journey-mob-pin1 {
    /* border: 1px solid black; */
    margin-top: 20px;
    padding: 20px;
  }

  .journey-mob-pin2 {
    /* border: 1px solid black; */
    margin-top: 20px;
    padding-right: 20px;
  }

  .journeytextbg1 img {
    height: 140px;
    box-shadow: 1px 1px 5px gray;
  }

  .journeytextbg2 img {
    height: 140px;
    box-shadow: 1px 1px 5px gray;
    margin-left: 72px;
  }

  .journeytextbg1 {
    display: flex !important;
    margin-left: 10px;
  }

  .journeytextbg2 {
    display: flex !important;
    margin-left: 10px;
  }

  .journeytextbg1 h3 {
    margin-left: 65px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
    color: #474A54;
  }

  .journeytextbg2 h3 {
    margin-left: 95px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
    color: #474A54;
  }

  .pillars-container {
    margin-top: 100px;
  }

  .pillarheading img {
    height: 50px;
  }

  .slider-container-about {
    margin-top: 30px;
  }



  .slide {
    height: 449px;
    width: 300px !important;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    box-shadow: 0px 0px 50px rgb(236, 236, 236);
  }

  .slider-container-about {
    margin-top: 30px;
    /* border: 1px solid black; */
    height: 550px;
  }

  .slide-image {
    height: 320px;
    /* border: 1px solid black; */
    border-top-right-radius: 70px;
  }

  .slide-image img {
    width: -webkit-fill-available;
    border-top-right-radius: 70px;
    height: 351px;
  }

  .slide h1 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 46px;
  }

  .slide h3 {
    font-size: 18px;
    font-weight: 600;
    color: #0090E0;
  }

}

@media (min-width: 450px) and (max-width: 576px) {
  .main-about-container-about {
    padding-left: 10px;
    margin-top: 25%;
  }

  .content-aboutdiv {
    display: block;
  }

  .about-info-about {
    width: 90%;
    margin-left: 2%;
    /* border: 1px solid black; */
    margin-top: 2%;
  }

  .about-info-about h2 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    /* line-height: 1px; */
    color: #757575;
  }

  .about-info-about img {
    height: 50px;
  }

  #ele1 {
    display: none;
  }

  .about-image-about {
    /* height: 600px !important; */
    width: 90%;
    margin-top: -50px !important;
    margin-left: 2%;
    /* border: 1px solid black; */
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    z-index: 999;
  }

  .about-image-about img {
    overflow: hidden !important;
    z-index: 999;
  }

  #rectele {
    text-align: start;
    z-index: -1;
  }

  #rectele img {
    height: 80px !important;
    margin-top: -20px !important;
    margin-left: 121px;
    z-index: -1;
  }

  #levelid1 {
    display: none;
  }

  #rectid1 {
    display: none;
  }

  #recteleup {
    display: block;
    text-align: end;
    z-index: -1;
  }

  #searchid1 {
    display: none;
  }

  #recteleup img {
    height: 80px !important;
    margin-top: 20px !important;
    /* margin-left: 321px; */
    z-index: -1;

  }

  /* ==================visionmission container ================ */
  .vision-mission-container {
    display: none;
  }

  .vision-mission-container-mob {
    display: block;
  }

  .vission-missiondivmob {
    /* border: 1px solid black; */
    width: 90%;
    margin-left: 5%;
  }

  .missionmob {
    /* border: 1px solid black; */
    display: flex;

  }

  .visionmob {
    /* border: 1px solid black; */
    display: flex;
    margin-top: 20px;
  }

  .our-missionelemob {
    width: 30%;
    /* border: 1px solid black; */
  }

  .vision {
    width: 70%;
    /* border: 1px solid black; */
    padding: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: 0.5px 0.5px 2px gray;
  }

  .vision h2 {
    text-align: start;
    font-size: 22px;
    font-weight: 700;
    margin-top: 5px;
  }

  .vision-img {
    text-align: start;
  }

  .vision-img img {
    height: 60px;
  }

  .vision-text {
    width: 100%;
    text-align: start;
  }

  .vision-text p {
    font-size: 15px;
    margin-top: -8px;
    color: rgb(80, 78, 78);
  }

  .blur-image-slider-mob {
    /* width: 100%; */
    margin-top: -80px;
    overflow: hidden;
    /* border: 1px solid black; */
    margin-left: -20px;

  }

  .blur-image-slider-mob img {
    margin-left: -10%;
    width: 100%;
    height: 180px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  .blur-image-vision-mob {
    /* width: 100%; */
    margin-top: -50px;
    overflow: hidden;
    /* border: 1px solid black; */
    margin-left: -200px;

  }

  .blur-image-vision-mob img {
    margin-left: -10%;
    width: 100%;
    height: 180px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  #bagelevision img {
    height: 70px;
    margin-top: 30px;
  }

  #levelidmission {
    text-align: start;
  }

  #levelidmission IMG {
    height: 70px;
  }

  .journey-container {
    display: none;
  }

  .journey-container-mob {
    display: block;
    margin-top: -80px;
  }

  #bagelejourney {
    display: none;
  }

  .journey-main-mob {
    background-image: url("../../assets/images/about-images/journeylinemob.svg") !important;
    background-repeat: no-repeat;
    background-position: center !important;
    margin-top: 20px;
    height: 810px;
  }

  .journey-mob-pin1 {
    /* border: 1px solid black; */
    margin-top: 20px;
    padding: 20px;
  }

  .journey-mob-pin2 {
    /* border: 1px solid black; */
    margin-top: 20px;
    padding-right: 20px;
  }

  .journeytextbg1 img {
    height: 140px;
    box-shadow: 1px 1px 5px gray;
  }

  .journeytextbg2 img {
    height: 140px;
    box-shadow: 1px 1px 5px gray;
    margin-left: 72px;
  }

  .journeytextbg1 {
    display: flex !important;
    margin-left: 50px;
  }

  .journeytextbg2 {
    display: flex !important;
    margin-left: 125px;
  }

  .journeytextbg1 h3 {
    margin-left: 100px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
    color: #474A54;
  }

  .journeytextbg2 h3 {
    margin-left: 40px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
    color: #474A54;
  }

  .pillars-container {
    margin-top: 100px;
  }

  .pillarheading img {
    height: 50px;
  }

  .slider-container-about {
    margin-top: 30px;
  }



  .slide {
    height: 455px;
    width: 350px !important;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    box-shadow: 0px 0px 50px rgb(236, 236, 236);
  }

  .slider-container-about {
    margin-top: 30px;
    /* border: 1px solid black; */
    height: 560px;
  }

  .slide-image {
    height: 345px;
    /* border: 1px solid black; */
    border-top-right-radius: 70px;
  }

  .slide-image img {
    width: -webkit-fill-available;
    border-top-right-radius: 70px;
    height: 370px;
  }

  .slide h1 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 32px;
  }

  .slide h3 {
    font-size: 18px;
    font-weight: 600;
    color: #0090E0;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .main-about-container-about {
    padding-left: 10px;
    margin-top: 25%;
  }

  .content-aboutdiv {
    display: block;
  }

  .about-info-about {
    width: 90%;
    margin-left: 2%;
    /* border: 1px solid black; */
    margin-top: 2%;
  }

  .about-info-about h2 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    /* line-height: 1px; */
    color: #757575;
  }

  .about-info-about img {
    height: 50px;
  }

  #ele1 {
    display: none;
  }

  .about-image-about {
    /* height: 600px !important; */
    width: 90%;
    margin-top: -50px !important;
    margin-left: 2%;
    /* border: 1px solid black; */
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    z-index: 999;
  }

  #rectele {
    text-align: start;
    z-index: -1;
  }

  #rectele img {
    height: 80px !important;
    margin-top: -20px !important;
    margin-left: 121px;
    z-index: -1;
  }

  #levelid1 {
    display: none;
  }

  #rectid1 {
    display: none;
  }

  #recteleup {
    display: block;
    text-align: end;
    z-index: -1;
  }

  #searchid1 {
    display: none;
  }

  #recteleup img {
    height: 80px !important;
    margin-top: 20px !important;
    /* margin-left: 321px; */
    z-index: -1;

  }

  /* ==================visionmission container ================ */
  .vision-mission-container {
    display: none;
  }

  .vision-mission-container-mob {
    display: block;
  }

  .vission-missiondivmob {
    /* border: 1px solid black; */
    width: 90%;
    margin-left: 5%;
  }

  .missionmob {
    /* border: 1px solid black; */
    display: flex;

  }

  .visionmob {
    /* border: 1px solid black; */
    display: flex;
    margin-top: 20px;
  }

  .our-missionelemob {
    width: 30%;
    /* border: 1px solid black; */
  }

  .vision {
    width: 70%;
    /* border: 1px solid black; */
    padding: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: 0.5px 0.5px 2px gray;
  }

  .vision h2 {
    text-align: start;
    font-size: 22px;
    font-weight: 700;
    margin-top: 5px;
  }

  .vision-img {
    text-align: start;
  }

  .vision-img img {
    height: 60px;
  }

  .vision-text {
    width: 100%;
    text-align: start;
  }

  .vision-text p {
    font-size: 15px;
    margin-top: -8px;
    color: rgb(80, 78, 78);
  }

  .blur-image-slider-mob {
    /* width: 100%; */
    margin-top: -80px;
    overflow: hidden;
    /* border: 1px solid black; */
    margin-left: -20px;

  }

  .blur-image-slider-mob img {
    margin-left: -10%;
    width: 100%;
    height: 180px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  .blur-image-vision-mob {
    /* width: 100%; */
    margin-top: -50px;
    overflow: hidden;
    /* border: 1px solid black; */
    margin-left: -200px;

  }

  .blur-image-vision-mob img {
    margin-left: -10%;
    width: 100%;
    height: 180px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  #bagelevision img {
    height: 70px;
    margin-top: 30px;
  }

  #levelidmission {
    text-align: start;
  }

  #levelidmission IMG {
    height: 70px;
  }

  .journey-container {
    display: none;
  }

  .journey-container-mob {
    display: block;
    margin-top: -80px;
  }

  #bagelejourney {
    display: none;
  }

  .journey-main-mob {
    background-image: url("../../assets/images/about-images/journeylinemob.svg") !important;
    background-repeat: no-repeat;
    background-position: center !important;
    margin-top: 20px;
    height: 810px;
  }

  .journey-mob-pin1 {
    /* border: 1px solid black; */
    margin-top: 20px;
    padding: 20px;
  }

  .journey-mob-pin2 {
    /* border: 1px solid black; */
    margin-top: 20px;
    padding-right: 20px;
  }

  .journeytextbg1 img {
    height: 140px;
    box-shadow: 1px 1px 5px gray;
  }

  .journeytextbg2 img {
    height: 140px;
    box-shadow: 1px 1px 5px gray;
    margin-left: 155px;
  }

  .journeytextbg1 {
    display: flex !important;
    margin-left: 110px;
  }

  .journeytextbg2 {
    display: flex !important;
    margin-left: 100px;
    /* padding: 40px; */
  }

  .journeytextbg1 h3 {
    margin-left: 140px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
    color: #474A54;
  }

  .journeytextbg2 h3 {
    margin-left: 100px;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
    /* padding-right: 40px; */
    color: #474A54;
  }

  .pillars-container {
    margin-top: 100px;
  }

  .pillarheading img {
    height: 50px;
  }

  .slider-container-about {
    margin-top: 30px;
  }



  .slide {
    height: 449px;
    width: 300px !important;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    box-shadow: 0px 0px 50px rgb(236, 236, 236);
  }

  .slider-container-about {
    margin-top: 30px;
    /* border: 1px solid black; */
    height: 550px;
  }

  .slide-image {
    height: 320px;
    /* border: 1px solid black; */
    border-top-right-radius: 70px;
  }

  .slide-image img {
    width: -webkit-fill-available;
    border-top-right-radius: 70px;
    height: 357px;
  }

  .slide h1 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 47px;
  }

  .slide h3 {
    font-size: 18px;
    font-weight: 600;
    color: #0090E0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    /* width: 100%; */
    /* border: 1px solid black; */
  }


  .main-about-container-about {
    padding-left: 30px;
    margin-top: 15%;
  }

  .content-aboutdiv {
    display: flex;
  }

  .about-content-about {
    /* width: 100%; */
    /* border: 1px solid black; */
    margin-top: 2%;
    margin-left: 2%;
  }

  .about-image-about {
    /* height: 600px !important; */
    width: 60% !important;
    margin-left: 0%;
    /* border: 1px solid black; */
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .about-image-about img {
    width: 60%;
  }

  .aboutnameabout img {
    height: 50px;
  }

  .about-info-about {
    width: 50%;
    margin-left: 0%;
    /* border: 1px solid black; */
    margin-top: 2%;
  }

  #ele1 img {
    height: 60px;
    margin-top: 50px;
  }

  #rectele img {
    height: 80px;
    margin-top: 20px;
    margin-left: 30px;
    z-index: -1;
  }

  #rectid1 img {
    height: 80px;
    margin-top: 120px;
    margin-left: 50px;
    z-index: -1;
  }

  #searchid1 img {
    height: 60px;
  }

  #levelid1 img {
    height: 60px;
    margin-left: 0px;
    margin-top: -100px;
  }

  .about-info-about h2 {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    /* line-height: 1px; */
    color: #757575;
  }

  .vision-mission-container {
    /* border: 1px solid black; */
    display: flex;
    padding-left: 0%;
    /* padding-right: 22%; */
    padding-top: 2%;
    /* justify-content: space-between; */
  }

  .vision {
    width: 70%;
    /* border: 1px solid black; */
    padding: 30px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 0px 0px 5px gray;

  }

  .vision h2 {
    text-align: start;
    font-size: 24px;
    font-weight: 700;
  }

  .vision-img {
    text-align: start;
  }

  .vision-img img {
    height: 70px;
  }

  .vision-text {
    width: 90%;
    text-align: start;
  }

  .vision-text p {
    font-size: 16px;
    color: rgb(80, 78, 78);
  }

  .mission {
    width: 70%;
    /* border: 1px solid black; */
    padding: 30px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 0px 0px 5px gray;

  }

  .mission h2 {
    text-align: start;
    font-size: 24px;
    font-weight: 700;
  }

  .mission-img {
    text-align: start;
  }

  .mission-img img {
    height: 70px;
  }

  .mission-text {
    width: 100%;
    text-align: start;
  }

  .mission-text p {
    font-size: 16px;
    color: rgb(80, 78, 78);
  }


  .blur-image-about {
    width: 100%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    /* border: 1px solid black; */
    margin-top: 70%;
    margin-left: 0%;
  }

  .blur-image-about img {
    margin-left: -10%;
    height: 200px;
    margin-top: 50px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  .blur-image-left {
    width: 100%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    /* border: 1px solid black; */
    margin-top: -20%;
    margin-left: 5%;

  }

  .blur-image-left img {
    margin-left: -11%;
    height: 200px;
    margin-top: 50px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .journey-container {
    /* border: 1px solid black; */
    margin-top: 15%;
  }

  .jounrneyname img {
    height: 60px;
  }

  .waveimage {
    margin-top: 8%;
    /* animation: 5s slide; */
    /* animation-direction:reverse; */
    /* animation-timing-function:ease-out; */
  }

  @keyframes slide {
    from {
      margin-left: -100%;
      width: 100%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  /* ======eg. ==== */

  svg {
    height: 60vh;
    width: 100%;
    background-color: #1D1F22;
  }

  path {

    stroke-dasharray: 400;
    stroke-linecap: round;
    animation-timing-function: ease-in;
    animation: draw 6s;
    animation-direction: reverse;
  }

  @keyframes draw {
    from {
      stroke-dashoffset: 0
    }

    to {
      stroke-dashoffset: -400;
    }
  }

  /* ======eg. end==== */

  .pin1 {
    width: 20%;
    /* border: 1px solid black; */
    margin-left: 12%;
  }

  .pin4 {
    width: 20%;
    /* border: 1px solid black; */
    margin-left: -1%;
  }

  .pin2 {
    width: 20%;
    /* border: 1px solid black; */
    margin-top: -18%;
    margin-left: 2%;
  }

  .pin3 {
    width: 20%;
    /* border: 1px solid black; */
    margin-top: -20%;
    margin-left: -1%;
  }

  .pin-info-text3 {
    width: 90%;
    /* border: 1px solid rgb(0, 0, 0); */
    border: 1px solid rgb(255, 255, 255);
    margin-top: 20px;
    padding: 5px;
    margin-left: 10px;
    padding-top: 10px;
    border-top-left-radius: 5px !important;
    box-shadow: 1px 1px 70px rgb(243, 243, 243);
    padding-bottom: 20px;
    background-color: white;
    /* border-color: white; */
    border-radius: 50px;

  }

  .pin-info-text3 p {
    font-size: 10px !important;
    font-weight: 700 !important;
  }

  .pin-info-text1 {
    width: 90%;
    /* border: 1px solid rgb(0, 0, 0); */
    border: 1px solid rgb(255, 255, 255);
    margin-top: 20px;
    padding: 5px;
    margin-left: 10px;
    padding-top: 10px;
    border-top-left-radius: 5px !important;
    box-shadow: 1px 1px 70px rgb(243, 243, 243);
    padding-bottom: 20px;
    background-color: white;
    /* border-color: white; */
    border-radius: 50px;

  }

  .pin-info-text1 p {
    font-size: 10px !important;
    font-weight: 700 !important;
  }

  .pin-info-text {
    width: 90%;
    /* border: 1px solid rgb(0, 0, 0); */
    border: 1px solid rgb(255, 255, 255);
    margin-top: 50px;
    padding: 10px;
    margin-left: 10px;
    padding-top: 15px;
    border-top-left-radius: 5px !important;
    box-shadow: 1px 1px 70px rgb(243, 243, 243);
    padding-bottom: 20px;
    background-color: white;
    /* border-color: white; */
    border-radius: 50px;

  }

  .pin-info-text p {
    font-size: 10px !important;
    font-weight: 700 !important;
  }


  .horizontaldiv {
    margin-top: -130px;

  }

  .main-pin {
    margin-left: 20% !important;
  }

  .pinside {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 5px;
    width: 85%;
  }

  .pinside p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
  }

  .downtext {
    text-align: start;
  }

  .downtext img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .pinside2 {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 40px;
    width: 85%;
  }

  .pinside2 p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 85px;
  }

  .downtext2 {
    text-align: start;
  }

  .downtext2 img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .pinside3 {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 40px;
    width: 100%;
  }

  .pinside3 p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 85px;
  }

  .downtext3 {
    text-align: start;
  }

  .downtext3 img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .hidden {
    display: none;
  }

  .visible {
    /* width: 100px; */
    /* height: 200px; */
    /* background-color: lightblue; */
    /* margin: 10px; */
  }


  .pillarheading img {
    height: 50px;
  }

  .slide {
    height: 470px;
    width: 367px !important;
    /* background-color: red; */
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    box-shadow: 0px 0px 50px rgb(236, 236, 236);
    /* margin-left: 50px !important; */
  }

  .slider-container-about {
    margin-top: 100px;
    /* border: 1px solid black; */
    height: 550px;
  }

  .slide-image {
    height: 365px;
    /* border: 1px solid black; */
    border-top-right-radius: 70px;
  }

  .slide-image img {
    width: fit-content;
    border-top-right-radius: 70px;
  }

  .slide h1 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
  }

  .slide h3 {
    font-size: 18px;
    font-weight: 600;
    color: #0090E0;
  }

  #recteleup {
    display: none;
  }

  .vision-mission-container-mob {
    display: none;
  }

  .journey-container-mob {
    display: none;
  }

}

@media (min-width: 991px) and (max-width: 1200px) {
  .container {
    /* width: 100%; */
    /* border: 1px solid black; */
  }


  .main-about-container-about {
    padding-left: 30px;
    margin-top: 15%;
  }

  .content-aboutdiv {
    display: flex;
  }

  .about-content-about {
    /* width: 100%; */
    /* border: 1px solid black; */
    margin-top: 2%;
    margin-left: 2%;
  }

  .about-image-about {
    /* height: 600px !important; */
    width: 60% !important;
    margin-left: 0%;
    /* border: 1px solid black; */
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .about-image-about img {
    width: 60%;
  }

  .aboutnameabout img {
    height: 50px;
  }

  .about-info-about {
    width: 50%;
    margin-left: 2%;
    /* border: 1px solid black; */
    margin-top: 5%;
  }

  #ele1 img {
    height: 60px;
    margin-top: 50px;
  }

  #rectele img {
    height: 80px;
    margin-top: 20px;
    margin-left: 30px;
    z-index: -1;
  }

  #rectid1 img {
    height: 80px;
    margin-top: 120px;
    margin-left: 50px;
    z-index: -1;
  }

  #searchid1 img {
    height: 60px;
  }

  #levelid1 img {
    height: 60px;
    margin-left: 0px;
    margin-top: -100px;
  }

  .about-info-about h2 {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    /* line-height: 1px; */
    color: #757575;
  }

  .vision-mission-container {
    /* border: 1px solid black; */
    display: flex;
    padding-left: 0%;
    /* padding-right: 22%; */
    padding-top: 2%;
    /* justify-content: space-between; */
  }

  .vision {
    width: 70%;
    /* border: 1px solid black; */
    padding: 30px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 0px 0px 5px gray;

  }

  .vision h2 {
    text-align: start;
    font-size: 24px;
    font-weight: 700;
  }

  .vision-img {
    text-align: start;
  }

  .vision-img img {
    height: 70px;
  }

  .vision-text {
    width: 90%;
    text-align: start;
  }

  .vision-text p {
    font-size: 16px;
    color: rgb(80, 78, 78);
  }

  .mission {
    width: 70%;
    /* border: 1px solid black; */
    padding: 30px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 0px 0px 5px gray;

  }

  .mission h2 {
    text-align: start;
    font-size: 24px;
    font-weight: 700;
  }

  .mission-img {
    text-align: start;
  }

  .mission-img img {
    height: 70px;
  }

  .mission-text {
    width: 100%;
    text-align: start;
  }

  .mission-text p {
    font-size: 16px;
    color: rgb(80, 78, 78);
  }


  .blur-image-about {
    width: 100%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    /* border: 1px solid black; */
    margin-top: 70%;
    margin-left: 0%;
  }

  .blur-image-about img {
    margin-left: -10%;
    height: 200px;
    margin-top: 50px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  .blur-image-left {
    width: 100%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    /* border: 1px solid black; */
    margin-top: -20%;
    margin-left: 5%;

  }

  .blur-image-left img {
    margin-left: -11%;
    height: 200px;
    margin-top: 50px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .journey-container {
    /* border: 1px solid black; */
    margin-top: 11%;
  }

  .jounrneyname img {
    height: 60px;
  }

  .waveimage {
    margin-top: 8%;
    /* animation: 5s slide; */
    /* animation-direction:reverse; */
    /* animation-timing-function:ease-out; */
  }

  @keyframes slide {
    from {
      margin-left: -100%;
      width: 100%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  /* ======eg. ==== */

  svg {
    height: 60vh;
    width: 100%;
    background-color: #1D1F22;
  }

  path {

    stroke-dasharray: 400;
    stroke-linecap: round;
    animation-timing-function: ease-in;
    animation: draw 6s;
    animation-direction: reverse;
  }

  @keyframes draw {
    from {
      stroke-dashoffset: 0
    }

    to {
      stroke-dashoffset: -400;
    }
  }

  /* ======eg. end==== */

  .pin1 {
    width: 20%;
    /* border: 1px solid black; */
    margin-left: 12%;
  }

  .pin4 {
    width: 20%;
    /* border: 1px solid black; */
    margin-left: -1%;
  }

  .pin2 {
    width: 20%;
    /* border: 1px solid black; */
    margin-top: -16%;
    margin-left: 2%;
  }

  .pin3 {
    width: 20%;
    /* border: 1px solid black; */
    margin-top: -17%;
    margin-left: -1%;
  }

  .horizontaldiv {
    margin-top: -130px;

  }

  .main-pin {
    margin-left: 20% !important;
  }

  .pinside {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 5px;
    width: 80%;
  }

  .pinside p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
  }

  .downtext {
    text-align: start;
  }

  .downtext img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .pinside2 {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 0px;
    width: 80%;
  }

  .pinside2 p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 120px;
  }

  .downtext2 {
    text-align: start;
  }

  .downtext2 img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .pinside3 {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 0px;
    width: 100%;
  }

  .pinside3 p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 85px;
  }

  .downtext3 {
    text-align: start;
  }

  .downtext3 img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .hidden {
    display: none;
  }

  .visible {
    /* width: 100px; */
    /* height: 200px; */
    /* background-color: lightblue; */
    /* margin: 10px; */
  }

  .pillars-container {
    margin-top: -3%;
  }

  .pillarheading img {
    height: 50px;
  }

  .slide {
    height: 444px;
    width: 300px !important;
    /* background-color: red; */
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    box-shadow: 0px 0px 50px rgb(236, 236, 236);
    /* margin-left: 50px !important; */
  }

  .slider-container-about {
    margin-top: 100px;
    /* border: 1px solid black; */
    height: 550px;
  }

  .slide-image {
    height: 365px;
    /* border: 1px solid black; */
    border-top-right-radius: 70px;
  }

  .slide-image img {
    width: fit-content;
    border-top-right-radius: 70px;
    height: 350px;
  }

  .slide h1 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 0px;
  }

  .slide h3 {
    font-size: 18px;
    font-weight: 600;
    color: #0090E0;
  }

  #recteleup {
    display: none;
  }

  .vision-mission-container-mob {
    display: none;
  }

  .journey-container-mob {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .container {
    width: 100%;
    /* border: 1px solid black; */
  }


  .main-about-container-about {
    padding-left: 30px;
    margin-top: 15%;
  }

  .content-aboutdiv {
    display: flex;
  }

  .about-content-about {
    /* width: 100%; */
    /* border: 1px solid black; */
    margin-top: 2%;
    margin-left: 2%;
  }

  .about-image-about {
    height: 600px !important;
    width: 60% !important;
    margin-left: 0%;
    /* border: 1px solid black; */
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .about-image-about video {
    width: 100% !important;
  }

  .aboutnameabout img {
    height: 50px;
  }

  .about-info-about {
    width: 50%;
    margin-left: 2%;
    /* border: 1px solid black; */
    margin-top: 8%;
  }

  #ele1 img {
    height: 60px;
    margin-top: 50px;
  }

  #rectele img {
    height: 80px;
    margin-top: 20px;
    margin-left: 30px;
    z-index: -1;
  }

  #rectid1 img {
    height: 80px;
    margin-top: 120px;
    margin-left: 50px;
    z-index: -1;
  }

  #searchid1 img {
    height: 60px;
  }

  #levelid1 img {
    height: 60px;
    margin-left: 0px;
    margin-top: -100px;
  }

  .about-info-about h2 {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 400;
    text-align: start;
    /* line-height: 1px; */
    color: #757575;
  }

  .vision-mission-container {
    /* border: 1px solid black; */
    display: flex;
    padding-left: 0%;
    /* padding-right: 22%; */
    padding-top: 2%;
    /* justify-content: space-between; */
  }

  .vision {
    width: 70%;
    /* border: 1px solid black; */
    padding: 30px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 0px 0px 5px gray;

  }

  .vision h2 {
    text-align: start;
    font-size: 24px;
    font-weight: 700;
  }

  .vision-img {
    text-align: start;
  }

  .vision-img img {
    height: 70px;
  }

  .vision-text {
    width: 90%;
    text-align: start;
  }

  .vision-text p {
    font-size: 16px;
    color: rgb(80, 78, 78);
  }

  .mission {
    width: 70%;
    /* border: 1px solid black; */
    padding: 30px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 0px 0px 5px gray;

  }

  .mission h2 {
    text-align: start;
    font-size: 24px;
    font-weight: 700;
  }

  .mission-img {
    text-align: start;
  }

  .mission-img img {
    height: 70px;
  }

  .mission-text {
    width: 100%;
    text-align: start;
  }

  .mission-text p {
    font-size: 16px;
    color: rgb(80, 78, 78);
  }


  .blur-image-about {
    width: 100%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    /* border: 1px solid black; */
    margin-top: 70%;
    margin-left: 0%;
  }

  .blur-image-about img {
    margin-left: -10%;
    height: 200px;
    margin-top: 50px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  .blur-image-left {
    width: 100%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    /* border: 1px solid black; */
    margin-top: -20%;
    margin-left: 5%;

  }

  .blur-image-left img {
    margin-left: -11%;
    height: 200px;
    margin-top: 50px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .journey-container {
    /* border: 1px solid black; */
    margin-top: 11%;
  }

  .jounrneyname img {
    height: 60px;
  }

  .waveimage {
    margin-top: 8%;
    /* animation: 5s slide; */
    /* animation-direction:reverse; */
    /* animation-timing-function:ease-out; */
  }

  @keyframes slide {
    from {
      margin-left: -100%;
      width: 100%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  /* ======eg. ==== */

  svg {
    height: 60vh;
    width: 100%;
    background-color: #1D1F22;
  }

  path {

    stroke-dasharray: 400;
    stroke-linecap: round;
    animation-timing-function: ease-in;
    animation: draw 6s;
    animation-direction: reverse;
  }

  @keyframes draw {
    from {
      stroke-dashoffset: 0
    }

    to {
      stroke-dashoffset: -400;
    }
  }

  /* ======eg. end==== */

  .pin1 {
    width: 20%;
    /* border: 1px solid black; */
    margin-left: 12%;
  }

  .pin4 {
    width: 20%;
    /* border: 1px solid black; */
    margin-left: -1%;
  }

  .pin2 {
    width: 20%;
    /* border: 1px solid black; */
    margin-top: -10%;
    margin-left: 2%;
  }

  .pin3 {
    width: 20%;
    /* border: 1px solid black; */
    margin-top: -12%;
    margin-left: -1%;
  }

  .horizontaldiv {
    margin-top: -130px;

  }

  .main-pin {
    margin-left: 20% !important;
  }

  .pinside {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 5px;
    width: 80%;
  }

  .pin-info-text {
    width: 90%;
    /* border: 1px solid rgb(0, 0, 0); */
    border: 1px solid rgb(255, 255, 255);
    margin-top: 50px;
    padding: 10px;
    margin-left: 10px;
    padding-top: 20px;
    border-top-left-radius: 5px !important;
    box-shadow: 1px 1px 70px rgb(243, 243, 243);
    padding-bottom: 20px;
    background-color: white;
    /* border-color: white; */
    border-radius: 50px;

  }

  .pin-info-text p {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  .pinside p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
  }

  .downtext {
    text-align: start;
  }

  .downtext img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .pinside2 {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 0px;
    width: 80%;
  }

  .pinside2 p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 110px;
  }

  .downtext2 {
    text-align: start;
  }

  .downtext2 img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .pinside3 {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: -20px;
    width: 80%;
  }

  .pin-info-text3 {
    width: 90%;
    /* border: 1px solid rgb(0, 0, 0); */
    border: 1px solid rgb(255, 255, 255);
    margin-top: 20px;
    padding: 10px;
    margin-left: 10px;
    padding-top: 20px;
    border-top-left-radius: 5px !important;
    box-shadow: 1px 1px 70px rgb(243, 243, 243);
    padding-bottom: 20px;
    background-color: white;
    /* border-color: white; */
    border-radius: 50px;

  }

  .pin-info-text3 p {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  .pinside3 p {
    font-size: 30px;
    color: #474A54;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 80px;
  }

  .downtext3 {
    text-align: start;
  }

  .downtext3 img {
    height: 200px;
    /* box-shadow: 0.1px 0.1px 2px gray; */
  }

  .hidden {
    display: none;
  }

  .visible {
    /* width: 100px; */
    /* height: 200px; */
    /* background-color: lightblue; */
    /* margin: 10px; */
  }

  .pillars-container {
    margin-top: -3%;
  }

  .pillarheading img {
    height: 50px;
  }

  .slide {
    height:497px;
    width: 367px !important;
    /* background-color: red; */
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    box-shadow: 0px 0px 50px rgb(236, 236, 236);
    /* margin-left: 50px !important; */
  }

  .slider-container-about {
    margin-top: 100px;
    /* border: 1px solid black; */
    height: 550px;
  }

  .slide-image {
    height: 370px;
    /* border: 1px solid black; */
    border-top-right-radius: 70px;
  }

  .slide-image img {
    width: fit-content;
    border-top-right-radius: 70px;
  }

  .slide h1 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 40px;
  }

  .slide h3 {
    font-size: 20px;
    font-weight: 600;
    color: #0090E0;
  }

  #recteleup {
    display: none;
  }

  .vision-mission-container-mob {
    display: none;
  }

  .journey-container-mob {
    display: none;
  }
  #rectid1{
    margin-top: -418px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {

  .pinside3 {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: -20px;
    width: 70%;
  }

  .pinside2 {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 0px;
    width: 60%;
  }

  .pinside {
    /* border: 1px solid black; */
    text-align: start;
    margin-top: 5px;
    width: 70%;
  }

  .pin1 {
    width: 20%;
    /* border: 1px solid black; */
    margin-left: 12%;
  }

  .pin4 {
    width: 20%;
    /* border: 1px solid black; */
    margin-left: -1%;
  }

  .pin2 {
    width: 20%;
    /* border: 1px solid black; */
    margin-top: -10%;
    margin-left: 2%;
  }

  .pin3 {
    width: 20%;
    /* border: 1px solid black; */
    margin-top: -12%;
    margin-left: -1%;
  }

  .blur-image-left {
    width: 100%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    /* border: 1px solid black; */
    margin-top: -20%;
    margin-left: -5%;
  }

  .blur-image-about {
    width: 100%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    /* border: 1px solid black; */
    margin-top: 30%;
    margin-left: 0%;
  }

  .blur-image-about img {
    margin-left: -18%;
    height: 450px;
    margin-top: 50px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  .vision-mission-container {
    /* border: 1px solid black; */
    display: flex;
    padding-left: 0%;
    /* padding-right: 22%; */
    padding-top: 2%;
    /* justify-content: space-between; */
  }

  .pin-info-text3 {
    width: 90%;
    /* border: 1px solid rgb(0, 0, 0); */
    border: 1px solid rgb(255, 255, 255);
    margin-top: 20px;
    padding: 10px;
    margin-left: 10px;
    padding-top: 20px;
    border-top-left-radius: 5px !important;
    box-shadow: 1px 1px 70px rgb(243, 243, 243);
    padding-bottom: 20px;
    background-color: white;
    /* border-color: white; */
    border-radius: 50px;

  }

  .pin-info-text3 p {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  .about-image-about {
    height: 600px !important;
    width: 60% !important;
    margin-left: 0%;
    /* border: 1px solid black; */
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    margin-top: 2%;
  }

  .about-info-about {
    width: 40%;
    margin-left: 2%;
    /* border: 1px solid black; */
    margin-top: 2%;
  }

  .about-info-about h2 {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
    text-align: justify;
    /* line-height: 1px; */
    color: #757575;
  }

  #rectid1 {
    margin-top: -346px !important;
    margin-left: -80px;
    z-index: -1;

  }

  .slide {
    width: 370px !important;
  }
   .slide-image img {
    width: fit-content;
    border-top-right-radius: 70px;
    height: 372px;
  }

}