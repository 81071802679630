.performance-container {
    /* border: 1px solid black; */
    padding-top: 4%;
}
:where(.css-dev-only-do-not-override-1b0bdye).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name, :where(.css-dev-only-do-not-override-1b0bdye).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon {
    color: #1677ff;
}
.perform-name {
    text-align: start;
    padding: 5%;
    padding-bottom: 0%;
    display: flex;
    justify-content: space-between;
}

.slide-career {
    /* border: 1px solid black; */
    text-align: center !important;
    margin-top: -20%;
    padding: 10px;
}

.slide-image-career {
    /* border: 1px solid black; */
    /* width: 70%; */
    box-shadow: 1px 1px 30px rgb(236, 236, 236);
    margin: 83px;
    border-radius: 8px;
    padding-top: 0px;
    padding-bottom: 5px;
}
.slide-image-career {
    /* border: 1px solid black; */
    width: 82%;
    box-shadow: 1px 1px 30px rgb(236, 236, 236);
    margin: 83px;
    border-radius: 8px;
    padding-top: 0px;
    padding-bottom: 5px;
}
.slide-image-career {
    box-shadow: 1px 1px 30px rgb(236, 236, 236);
    margin: 40px;
    border-radius: 10px;
    padding-top: 0px;
    padding-bottom: 5px;
    margin-top: 100px !important;
}
.slide-image-career img {
    text-align: center !important;
    /* border: 1px solid black; */
    justify-content: center !important;
    border-top-left-radius: 10px;
    cursor: pointer;
    border-top-right-radius: 10px;
    margin-left: 5%;
    width: 100%;
    height: 480px;
    margin-left: 0%;
    /* border: 1px solid black; */
}

.life-at-ide-container {
    margin-top: 5%;
}

.perform-ele-levelup img {}

.elements {
    animation: moveElements 5s infinite;
}

@keyframes moveElements {

    /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
    0% {
        transform: translate(-2%, -2%);
    }

    25% {
        transform: translateX(-3vw) translateY(-3%);
    }

    50% {
        transform: translateX(-1vw) translateY(-3vh);
    }

    75% {
        transform: translateX(-1%) translateY(-3vh);
    }

    100% {
        transform: translate(-1%, -3%);
    }
}

.perform-ele-search {
    text-align: start;
    margin-left: 25%;
}

.life-at-main-div {
    /* border: 1px solid black; */
    width: 80%;
    margin-left: 10%;
    display: flex;
}

.life-text-div {
    /* border: 1px solid black; */
    width: 50%;
}

.life-img-div {
    /* border: 1px solid black; */
    width: 50%;
}

.row-1 {
    width: 100%;
    /* border: 1px solid black; */
    display: flex;
}

.row-2 {
    width: 100%;
    /* border: 1px solid black; */
    display: flex;
}

.row-3 {
    width: 100%;
    /* border: 1px solid black; */
    display: flex;
}

.row-content1 {
    width: 50%;
    /* border: 1px solid black; */

}

.row-content2 {
    width: 50%;
    /* border: 1px solid black; */

}

.inner-content1 {
    margin-left: 20px;
}

.inner-content2 {
    margin-left: 0px;
}

.inner-content1 h2 {
    margin-left: 20px;
}

.inner-content2 h2 {
    margin-left: 20px;
}

.innter-text {
    width: 80%;
    /* border: 1px solid black; */
    margin-left: 10%;
    margin-top: -15px;
}

.innter-text p {
    text-align: start;
    font-size: 18px;
    font-weight: 600;
    color: #000000B2;
}

/* .image{
    border: 1px solid black;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
} */
.image video {
    width: 100%;
    height: 550px !important;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
}

.life-at-ide-container-mob {
    display: none;
}

.modal-name {
    margin-top: 20px;
}

svg {
    height: 30px;
    width: 100%;
    background-color: #ffffff;
}

.modalapplybtn {
    height: 35px;
    width: 150px;
    border-radius: 74px;
    margin-top: 50px;
    background-color: #797979;
    border-color: #4e4e4e;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    margin-left: 35%;
    cursor: pointer;
    color: white;
    font-size: 18px;
    margin-bottom: 30px;
    /* font-weight: 500; */
}

.modal-content {
    margin-top: 30px;
}

@media (max-width:450px) {

    /* =================job opening start ================== */
    .main-jobopening-container-mobile {
        display: block;
        width: 100vw;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .jobopening-col {
        padding: 0px;
        margin-top: 100px;
        width: 100%;
        height: 280px;
        /* margin-left: 4%; */
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 60px;
        border-bottom-left-radius: 60px;
        margin-left: 25px !important;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
        /* display: flex !important; */
    }

    .jobopening-col img {
        padding-top: 20px;
        height: 60px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 18px;
        font-weight: 500;
        margin-top: -30px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 14px;
        font-weight: 400;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 35px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 10px;
        display: block;
        margin-top: 35px;
        margin-left: 45%;
        font-size: 12px;
    }

    .submit:hover {
        color: white !important;
    }

    .main-jobopening-container-mobile {
        display: block;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .job-row {
        width: 100vw;
        height: 500px;
        padding: 0px;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* ======================job opening end=============== */



    .nameimg img {
        height: 45px;
    }

    .performance-container {
        padding-top: 12%;
    }

    .perform-ele-levelup {
        display: none;
    }

    .slide-image-career {
        font-size: 18px;
        box-shadow: 1px 1px 30px rgb(236, 236, 236);
        margin: 10px;
        border-radius: 10px;
        padding-top: 0px;
        padding-bottom: 5px;
        width: 90%;
        margin-top: 80px;
    }
    .slide-image-career img{
        height: 380px;
        margin-left: 0%;
        cursor: pointer;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 100%;
     }
    .slide-image-career h2 {
        font-size: 16px;
    }

    .life-at-ide-container {
        display: none;
    }

    .life-at-ide-container-mob {
        display: block;
    }

    .ide-life-main-div {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }

    .ide-life-main-div1 {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        margin-top: 50px;
    }

    .ide-life-image video {
        width: 100%;
    }

    .row-content1 {
        width: 100%;
    }

    .inner-content1 {
        margin-left: 20px;
    }

    .inner-content1 h2 {
        font-size: 20px;
        font-weight: 700;
    }

    .innter-text {
        width: 80%;
        /* border: 1px solid black; */
        margin-left: 14%;
        margin-top: -20px;
    }

    .innter-text p {
        text-align: start;
        font-size: 16px;
        font-weight: 600;
        color: #000000B2;
    }
}

@media (min-width: 450px) and (max-width: 576px) {
    /* =================job opening start ================== */

    .main-jobopening-container-mobile {
        display: block;
        width: 100vw;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .jobopening-col {
        padding: 0px;
        margin-top: 100px;
        width: 85%;
        height: 280px;
        /* margin-left: 4%; */
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 60px;
        border-bottom-left-radius: 60px;
        margin-left: 25px !important;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
        /* display: flex !important; */
    }

    .jobopening-col img {
        padding-top: 20px;
        height: 60px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 18px;
        font-weight: 500;
        margin-top: -30px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 14px;
        font-weight: 400;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 35px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 10px;
        display: block;
        margin-top: 35px;
        margin-left: 45%;
        font-size: 12px;
    }

    .submit:hover {
        color: white !important;
    }

    .main-jobopening-container-mobile {
        display: block;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .job-row {
        width: 100vw;
        height: 500px;
        padding: 0px;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* ======================job opening end=============== */



    .nameimg img {
        height: 45px;
    }

    .performance-container {
        padding-top: 12%;
    }

    .perform-ele-levelup {
        display: none;
    }

    .slide-image-career {
        font-size: 18px;
        box-shadow: 1px 1px 30px rgb(236, 236, 236);
        margin: 10px;
        border-radius: 10px;
        padding-top: 0px;
        padding-bottom: 5px;
        width: 90%;
        margin-top: 60px !important;
    }
    .slide-image-career img{
        height: 300px;
        margin-left: 0%;
        cursor: pointer;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 100%;
     }
    .slide-image-career h2 {
        font-size: 16px;
    }

    .life-at-ide-container {
        display: none;
    }

    .life-at-ide-container-mob {
        display: block;
    }

    .ide-life-main-div {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }

    .ide-life-main-div1 {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        margin-top: 50px;
    }

    .ide-life-image video {
        width: 100%;
    }

    .row-content1 {
        width: 100%;
    }

    .inner-content1 {
        margin-left: 20px;
    }

    .inner-content1 h2 {
        font-size: 20px;
        font-weight: 700;
    }

    .innter-text {
        width: 80%;
        /* border: 1px solid black; */
        margin-left: 14%;
        margin-top: -20px;
    }

    .innter-text p {
        text-align: start;
        font-size: 16px;
        font-weight: 600;
        color: #000000B2;
    }
    .slide-image-career img {
        text-align: center !important;
        /* border: 1px solid black; */
        justify-content: center !important;
        border-top-left-radius: 10px;
        cursor: pointer;
        border-top-right-radius: 10px;
        margin-left: 5%;
        width: 100%;
        height: 380px;
        margin-left: 0%;
        /* border: 1px solid black; */
    }
    
}

@media (min-width: 576px) and (max-width: 768px) {

    /* =====================Job opening start ======================= */
    .main-jobopening-container-mobile {
        display: block;
        width: 100vw;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .jobopening-col {
        padding: 0px;
        margin-top: 100px;
        width: 85%;
        height: 280px;
        /* margin-left: 4%; */
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 60px;
        border-bottom-left-radius: 60px;
        margin-left: 25px !important;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
        /* display: flex !important; */
    }

    .jobopening-col img {
        padding-top: 20px;
        height: 60px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 18px;
        font-weight: 500;
        margin-top: -30px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 14px;
        font-weight: 400;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 35px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 10px;
        display: block;
        margin-top: 35px;
        margin-left: 45%;
        font-size: 12px;
    }

    .submit:hover {
        color: white !important;
    }

    .main-jobopening-container-mobile {
        display: block;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .job-row {
        width: 100vw;
        height: 500px;
        padding: 0px;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* ======================job opening end=============== */



    .nameimg img {
        height: 50px;
    }

    .performance-container {
        padding-top: 12%;
    }

    .perform-ele-levelup {
        display: none;
    }

    .slide-image-career {
        width: 90%;
        font-size: 18px;
        box-shadow: 1px 1px 30px rgb(236, 236, 236);
        margin: 10px;
        border-radius: 10px;
        padding-top: 0px;
        padding-bottom: 5px;
        margin-top: 60px !important;
    }
    .slide-image-career img{
       height: 320px;
       margin-left: 0%;
       cursor: pointer;
       width: 100%;
    }

    .slide-image-career h2 {
        font-size: 16px;
    }

    .life-at-ide-container {
        display: none;
    }

    .life-at-ide-container-mob {
        display: block;
    }

    .ide-life-main-div {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }

    .ide-life-main-div1 {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        margin-top: 50px;
    }

    .ide-life-image video {
        width: 100%;
    }

    .row-content1 {
        width: 100%;
    }

    .inner-content1 {
        margin-left: 20px;
    }

    .inner-content1 h2 {
        font-size: 20px;
        font-weight: 700;
    }

    .innter-text {
        width: 80%;
        /* border: 1px solid black; */
        margin-left: 14%;
        margin-top: -20px;
    }

    .innter-text p {
        text-align: start;
        font-size: 16px;
        font-weight: 600;
        color: #000000B2;
    }
}

@media (min-width: 768px) and (max-width: 900px) {


    .nameimg img {
        height: 50px;
    }

    .performance-container {
        padding-top: 12%;
    }

    .perform-ele-levelup {
        display: none;
    }

    .slide-image-career {
        font-size: 18px;
        box-shadow: 1px 1px 30px rgb(236, 236, 236);
        margin: 10px;
        border-radius: 10px;
        padding-top: 0px;
        padding-bottom: 5px;
        width: 80%;
        margin-top: 90px !important;
    }
    .slide-image-career img{
        height: 400px;
        width: 100%;
        cursor: pointer;
        margin-left: 0%;
    }

    .slide-image-career h2 {
        font-size: 16px;
    }

    .life-at-ide-container {
        display: none;
    }

    .life-at-ide-container-mob {
        display: block;
    }

    .ide-life-main-div {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }

    .ide-life-main-div1 {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        margin-top: 50px;
    }

    .ide-life-image video {
        width: 100%;
        height: 681px;
    }

    .row-content1 {
        width: 100%;
    }

    .inner-content1 {
        margin-left: 20px;
    }

    .inner-content1 h2 {
        font-size: 20px;
        font-weight: 700;
    }

    .innter-text {
        width: 80%;
        /* border: 1px solid black; */
        margin-left: 14%;
        margin-top: -20px;
    }

    .innter-text p {
        text-align: start;
        font-size: 16px;
        font-weight: 600;
        color: #000000B2;
    }
    .slide-image-career img {
        text-align: center !important;
        /* border: 1px solid black; */
        justify-content: center !important;
        border-top-left-radius: 10px;
        cursor: pointer;
        border-top-right-radius: 10px;
        margin-left: 5%;
        width: 100%;
        height: 450px;
        margin-left: 0%;
        /* border: 1px solid black; */
    }
    
}

@media (min-width: 900px) and (max-width: 991px) {
    .main-jobopening-container {
        /* margin-left: -120px; */
        margin-top: 3%;
    }

    .job-row {
        width: 100wh;
        height: 400px;
        padding: 20px;
      
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
    height: 650px;
    border: 1px solid #faf9f9;
    background-color: #5fc7ff;
    box-shadow: 1px 1px 1px gray;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
} */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .jobopeningdiv {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
} */
    .jobopening-col {
        width: 32%;
        height: 320px;
        margin-left: 5%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        margin-top: 20px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .jobopening-col1 {
        width: 38%;
        height: 280px;
        margin-left: 2%;
        margin-top: 15px;
        margin-bottom: 25px;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
    }

    .job-opening-row1 {
        margin-top: 15px;
        margin-left: 15%;
    }

    .jobopening-col img {
        padding: 30px;
        height: 70px;
        /* height: 160px; */
    }

    .jobopening-col1 img {
        padding: 30px;
        height: 70px;
        /* height: 160px; */
    }

    .jobopening-col h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
        /* font-weight: 900; */
    }

    .jobopening-col1 h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
    }

    .jobopening-col1 h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 50%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 90%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 8px;
        display: block;
        margin-top: 25px;
        margin-left: 55%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    }


    /* ==============job application end ===================== */

    .main-jobopening-container {
        /* margin-left: -120px; */
        margin-top: 3%;
    }

    .job-row {
        width: 100wh;
        height: 400px;
        padding: 20px;
       
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
height: 650px;
border: 1px solid #faf9f9;
background-color: #5fc7ff;
box-shadow: 1px 1px 1px gray;
border-top-right-radius: 100px;
border-bottom-left-radius: 100px;
} */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
.jobopeningdiv {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
}
} */
    .jobopening-col {
        width: 32%;
        height: 320px;
        margin-left: 5%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        margin-top: 20px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .jobopening-col1 {
        width: 36%;
        height: 280px;
        margin-left: 2%;
        margin-top: 15px;
        margin-bottom: 25px;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
    }

    .job-opening-row1 {
        margin-top: 15px;
        margin-left: 10%;
    }

    .jobopening-col img {
        padding: 30px;
        height: 70px;
        /* height: 160px; */
    }

    .jobopening-col1 img {
        padding: 30px;
        height: 70px;
        /* height: 160px; */
    }

    .jobopening-col h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
        /* font-weight: 900; */
    }

    .jobopening-col1 h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
    }

    .jobopening-col1 h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 50%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 90%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 8px;
        display: block;
        margin-top: 25px;
        margin-left: 55%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    }

    /* =================job application end=============== */

    .performance-container {
        /* border: 1px solid black; */
        padding-top: 5%;
    }

    .perform-name {
        text-align: start;
        padding: 5%;
        padding-bottom: 0%;
        display: flex;
        justify-content: space-between;
    }

    .slide-career {
        /* border: 1px solid black; */
        text-align: center !important;
    }

    .slide-image-career {
        width: 86%;
        box-shadow: 1px 1px 30px rgb(236, 236, 236);
        margin: 20px;
        border-radius: 10px;
        padding-top: 0px;
        padding-bottom: 5px;
        margin-top: 83px !important;
    }

    .slide-image-career img {
        text-align: center !important;
        /* border: 1px solid black; */
        justify-content: center !important;
        cursor: pointer;
        margin-left: 0%;
        width: 100%;
        height: 520px !important;
        /* border: 1px solid black; */
    }

    .life-at-ide-container {
        margin-top: 8%;
    }

    .perform-ele-levelup img {
        height: 70%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    .bagelecareer {
        height: 80px;
    }

    @keyframes moveElements {

        /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .perform-ele-search {
        text-align: start;
        margin-left: 25%;
    }

    .perform-ele-search img {
        height: 70px;
    }

    .life-at-main-div {
        /* border: 1px solid black; */
        width: 95%;
        margin-left: 3%;
        display: flex;
    }

    .life-text-div {
        /* border: 1px solid black; */
        width: 50%;
        margin-top: -10px;
    }

    .life-img-div {
        /* border: 1px solid black; */
        width: 50%;
    }

    .row-1 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
    }

    .row-2 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
        margin-top: -15px;
    }

    .row-3 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
        margin-top: -15px;

    }

    .row-content1 {
        width: 50%;
        /* border: 1px solid black; */

    }

    .row-content2 {
        width: 50%;
        /* border: 1px solid black; */

    }

    .inner-content1 {
        margin-left: 20px;
    }


    .inner-content2 {
        margin-left: 0px;
    }

    .inner-content1 h2 {
        margin-left: 10px;
        font-size: 18px;
    }

    .inner-content2 h2 {
        margin-left: 10px;
        font-size: 18px;

    }

    .innter-text {
        width: 90%;
        /* border: 1px solid black; */
        margin-left: 10%;
        margin-top: -25px;
    }

    .innter-text p {
        text-align: start;
        font-size: 14px;
        font-weight: 600;
        color: #000000B2;
    }

    /* .image{
    border: 1px solid black;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
} */
    .image video {
        width: 100%;
        height: 390px !important;
    }

    .life-at-ide-container-mob {
        display: none;
    }
}



@media (min-width: 991px) and (max-width: 1200px) {
    .main-jobopening-container {
        /* margin-left: -120px; */
        margin-top: 3%;
    }

    .job-row {
        width: 100wh;
        height: 400px !important;
        padding: 20px;
       
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
    height: 650px;
    border: 1px solid #faf9f9;
    background-color: #5fc7ff;
    box-shadow: 1px 1px 1px gray;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
} */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .jobopeningdiv {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
} */
    .jobopening-col {
        width: 32%;
        height: 320px;
        margin-left: 5%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        margin-top: 20px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .jobopening-col1 {
        width: 36%;
        height: 280px;
        margin-left: 2%;
        margin-top: 15px;
        margin-bottom: 25px;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
    }

    .job-opening-row1 {
        margin-top: 15px;
        margin-left: 10%;
    }

    .jobopening-col img {
        padding: 30px;
        height: 70px;
        /* height: 160px; */
    }

    .jobopening-col1 img {
        padding: 30px;
        height: 70px;
        margin-top: -15px !important;
        /* height: 160px; */
    }

    .jobopening-col h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -20px !important;
        /* font-weight: 900; */
    }

    .jobopening-col1 h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
    }

    .jobopening-col1 h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 50%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 90%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 8px;
        display: block;
        margin-top: 25px;
        margin-left: 55%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    }

    /* =================job application end=============== */

    .performance-container {
        /* border: 1px solid black; */
        padding-top: 5%;
    }

    .perform-name {
        text-align: start;
        padding: 5%;
        padding-bottom: 0%;
        display: flex;
        justify-content: space-between;
    }

    .slide-career {
        /* border: 1px solid black; */
        text-align: center !important;
    }

   
    .slide-image-career {
        width: 80%;
        box-shadow: 1px 1px 30px rgb(236, 236, 236);
        margin: 20px;
        border-radius: 10px;
        padding-top: 0px;
        padding-bottom: 5px;
        margin-top: 63px !important;
    }
    .slide-image-career img {
        text-align: center !important;
        /* border: 1px solid black; */
        justify-content: center !important;
        margin-left: 0%;
        width: 100%;
        cursor: pointer;
        height: 350px !important;
        /* border: 1px solid black; */
    }
    .slide-image-career h2{
        font-size: 18px;
    }
    .life-at-ide-container {
        margin-top: 8%;
    }

    .perform-ele-levelup img {
        height: 70%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    .bagelecareer {
        height: 80px;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .perform-ele-search {
        text-align: start;
        margin-left: 25%;
    }

    .perform-ele-search img {
        height: 70px;
    }

    .life-at-main-div {
        /* border: 1px solid black; */
        width: 95%;
        margin-left: 3%;
        display: flex;
    }

    .life-text-div {
        /* border: 1px solid black; */
        width: 50%;
        margin-top: -10px;
    }

    .life-img-div {
        /* border: 1px solid black; */
        width: 50%;
    }

    .row-1 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
    }

    .row-2 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
        margin-top: -15px;
    }

    .row-3 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
        margin-top: -15px;

    }

    .row-content1 {
        width: 50%;
        /* border: 1px solid black; */

    }

    .row-content2 {
        width: 50%;
        /* border: 1px solid black; */

    }

    .inner-content1 {
        margin-left: 20px;
    }


    .inner-content2 {
        margin-left: 0px;
    }

    .inner-content1 h2 {
        margin-left: 20px;
        font-size: 20px;
    }

    .inner-content2 h2 {
        margin-left: 20px;
        font-size: 20px;

    }

    .innter-text {
        width: 90%;
        /* border: 1px solid black; */
        margin-left: 10%;
        margin-top: -25px;
    }

    .innter-text p {
        text-align: start;
        font-size: 14px;
        font-weight: 600;
        color: #000000B2;
    }

    /* .image{
        border: 1px solid black;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    } */
    .image video {
        width: 100%;
        height: 370px !important;
    }

    .life-at-ide-container-mob {
        display: none;
    }
}


@media (min-width: 1201px) and (max-width: 1500px) {
    .main-jobopening-container {
        /* margin-left: -120px; */
        margin-top: 8%;
    }

    .job-row {
        width: 100wh;
        height: 500px;
        padding: 10px;
        padding-top: 20px;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
        height: 650px;
        border: 1px solid #faf9f9;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    } */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .jobopeningdiv {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */
    .jobopening-col {
        width: 20%;
        height: 360px;
        margin-left: 2%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        margin-left: 50px !important;
        margin-top: 20px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 25px;
    }

    .jobopening-col img {
        padding: 40px;
        height: 80px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -15px !important;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 8px;
        display: block;
        margin-top: 40px;
        margin-left: 45%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    }

    /* =================job application end=============== */
    .performance-container {
        /* border: 1px solid black; */
        padding-top: 5%;
    }

    .perform-name {
        text-align: start;
        padding: 5%;
        padding-bottom: 0%;
        display: flex;
        justify-content: space-between;
    }

    .slide-career {
        /* border: 1px solid black; */
        text-align: center !important;
    }

    
    .slide-image-career {
        width: 85% !important;
        box-shadow: 1px 1px 30px rgb(236, 236, 236);
        margin: 20px !important;
        border-radius: 10px;
        padding-top: 0px;
        padding-bottom: 5px;
        margin-top: 100px !important;
    }
    .slide-image-career img {
        text-align: center !important;
        /* border: 1px solid black; */
        justify-content: center !important;
        margin-left: 0%;
        height: 450px !important;
        cursor: pointer;
        width: 100%;
        /* border: 1px solid black; */
    }

    .life-at-ide-container {
        margin-top: 8%;
    }

    .perform-ele-levelup img {
        height: 70%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    .bagelecareer {
        height: 80px;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .perform-ele-search {
        text-align: start;
        margin-left: 25%;
    }

    .perform-ele-search img {
        height: 70px;
    }

    .life-at-main-div {
        /* border: 1px solid black; */
        width: 95%;
        margin-left: 3%;
        display: flex;
    }

    .life-text-div {
        /* border: 1px solid black; */
        width: 50%;
    }

    .life-img-div {
        /* border: 1px solid black; */
        width: 50%;
    }

    .row-1 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
    }

    .row-2 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
    }

    .row-3 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
    }

    .row-content1 {
        width: 50%;
        /* border: 1px solid black; */

    }

    .row-content2 {
        width: 50%;
        /* border: 1px solid black; */

    }

    .inner-content1 {
        margin-left: 20px;
    }


    .inner-content2 {
        margin-left: 0px;
    }

    .inner-content1 h2 {
        margin-left: 20px;
        font-size: 22px;
    }

    .inner-content2 h2 {
        margin-left: 20px;
        font-size: 22px;

    }

    .innter-text {
        width: 80%;
        /* border: 1px solid black; */
        margin-left: 10%;
        margin-top: -25px;
    }

    .innter-text p {
        text-align: start;
        font-size: 14px;
        font-weight: 600;
        color: #000000B2;
    }

    /* .image{
        border: 1px solid black;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    } */
    .image video {
        width: 100%;
        height: 460px !important;
    }

    .life-at-ide-container-mob {
        display: none;
    }
}


@media (min-width: 1500px) and (max-width: 1700px) {
    .main-jobopening-container {
        /* margin-left: -120px; */
        margin-top: 8%;
    }

    .job-row {
        width: 100wh;
        height: 500px;
        padding: 10px;
        padding-top: 20px;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
        height: 650px;
        border: 1px solid #faf9f9;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    } */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .jobopeningdiv {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */
    .jobopening-col {
        width: 20%;
        height: 360px;
        margin-left: 2%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        margin-left: 50px !important;
        margin-top: 20px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 25px;
    }

    .jobopening-col img {
        padding: 40px;
        height: 80px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px !important;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 8px;
        display: block;
        margin-top: 40px;
        margin-left: 45%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    }

    /* =====================job opening end================== */

    .performance-container {
        /* border: 1px solid black; */
        padding-top: 4%;
    }

    .perform-name {
        text-align: start;
        padding: 5%;
        padding-bottom: 1%;
        display: flex;
        justify-content: space-between;
    }

    .slide-career {
        /* border: 1px solid black; */
        text-align: center !important;
    }

    .slide-image-career {

        box-shadow: 1px 1px 30px rgb(236, 236, 236);
        margin: 20px !important;
        border-radius: 10px;
        padding-top: 0px;
        padding-bottom: 5px;
        margin-top: 100px !important;
    }
    .slide-image-career {
    /* border: 1px solid black; */
    width: 85%;
    box-shadow: 1px 1px 30px rgb(236, 236, 236);
    margin: 83px;
    border-radius: 8px;
    padding-top: 0px;
    padding-bottom: 5px;
}

    .slide-image-career img {
        text-align: center !important;
        /* border: 1px solid black; */
        justify-content: center !important;
        cursor: pointer;
        margin-left: 0%;
        width: 100%;
        height: 440px !important;
        /* border: 1px solid black; */
    }

    .life-at-ide-container {
        margin-top: 5%;
    }

    .perform-ele-levelup img {}

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .perform-ele-search {
        text-align: start;
        margin-left: 25%;
    }

    .life-at-main-div {
        /* border: 1px solid black; */
        width: 90%;
        margin-left: 5%;
        display: flex;
    }

    .life-text-div {
        /* border: 1px solid black; */
        width: 50%;
    }

    .life-img-div {
        /* border: 1px solid black; */
        width: 50%;
    }

    .row-1 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
    }

    .row-2 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
    }

    .row-3 {
        width: 100%;
        /* border: 1px solid black; */
        display: flex;
    }

    .row-content1 {
        width: 50%;
        /* border: 1px solid black; */

    }

    .row-content2 {
        width: 50%;
        /* border: 1px solid black; */

    }

    .inner-content1 {
        margin-left: 20px;
    }

    .inner-content2 {
        margin-left: 0px;
    }

    .inner-content1 h2 {
        margin-left: 20px;
    }

    .inner-content2 h2 {
        margin-left: 20px;
    }

    .innter-text {
        width: 80%;
        /* border: 1px solid black; */
        margin-left: 10%;
        margin-top: -20px;
    }

    .innter-text p {
        text-align: start;
        font-size: 16px;
        font-weight: 600;
        color: #000000B2;
    }

    /* .image{
        border: 1px solid black;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    } */
    .image video {
        width: 100%;
    }

    .life-at-ide-container-mob {
        display: none;
    }
}