.main-service-container-service {
  background-image: url("../../assets/images/services/servicebgcircle.svg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  margin-top: -8%;
  background-position: center;
  /* object-fit: cover; */
}

.imagediv1 {
  display: flex;
  /* border: 1px solid black; */
  /* margin-top: 20%; */
  padding-top: 13%;
  margin-left: 23%;
  margin-right: 28%;
  justify-content: space-between;
}

.eleimage1 img {
  height: 80px;
}

.main-content img {
  padding-top: 0px !important;
}

.eleimage2 img {
  height: 80px;
}

.blur-image-service img {
  height: 450px;
  animation: zoom-in-zoom-out 3s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    opacity: 100;
    transform: scale(1, 1);
  }

  50% {
    opacity: 100;
    transform: scale(1.5, 1.5);
  }

  100% {
    opacity: 100;
    transform: scale(1, 1);
  }
}

.servicenameservice {
  margin-top: -15%;
  display: flex;
  justify-content: center;
}

.servicenameimage img {
  height: 50px;
  margin-left: 150px;
  /* animation: zoom-in-zoom-out 10s ease infinite; */
}

.eleimage3 {
  margin-left: -0px;
}

.eleimage3 img {
  height: 80px;
  margin-top: -50px;
}

.eleimage4 {
  margin-left: 130px;
}

.eleimage4 img {
  height: 80px;
  margin-top: 70px;
}

.imagediv2 {
  display: flex;
  /* border: 1px solid black; */
  margin-top: 20px;
  margin-left: 27%;
  margin-right: 22.5%;
  justify-content: space-between;
}

#elements {
  animation: moveElements 5s infinite;
}

@keyframes moveElements {

  /* 0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15px); 
  }
  100% {
    transform: translateX(0);
  } */
  0% {
    transform: translate(-1%, -1%);
  }

  25% {
    transform: translateX(-1vw) translateY(-2%);
  }

  50% {
    transform: translateX(-1vw) translateY(-2vh);
  }

  75% {
    transform: translateX(1%) translateY(-1vh);
  }

  100% {
    transform: translate(-1%, -2%);
  }
}

.Services-content-start {
  /* border: 1px solid black; */
  margin-top: -200px;
  /* padding: 50px; */
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.contentservicemain1 {
  /* border: 1px solid black; */
  width: 80%;
  margin-left: 10%;
  display: flex;
  margin-top: 50px !important;
}

.serviceimage1 {
  /* border: 1px solid black; */
  width: 60%;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
}

.serviceimage1 img {
  width: 90%;
  height: 450px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  margin-top: 20px;
}

.servicecontent1 {
  /* border: 1px solid black; */
  width: 40%;
  margin-top: 3%;
}

.servicecontent2 {
  /* border: 1px solid black; */
  width: 40%;
  margin-top: 1%;
  margin-left: 2%;
}

.main-service-container-service-mob {
  display: none;
}

.Services-content-start-mob {
  display: none;
}

@media (max-width:450px) {
  .main-service-container-service {
    display: none;
  }

  .Services-content-start {
    display: none;
  }

  .main-service-container-service-mob {
    display: block;
    background-image: url("../../assets/images/services/servicebgcirclemob.svg");
    width: auto;
    /* border: 1px solid black; */
    height: 500px;
    background-repeat: no-repeat;
    margin-top: -8%;
    background-position: center;
  }

  .imagediv1 {
    display: flex;
    /* border: 1px solid black; */
    /* margin-top: 20%; */
    padding-top: 27%;
    margin-left: 0%;
    margin-right: 6%;
    justify-content: space-between;
  }

  .eleimage1 img {
    height: 50px;
  }

  .eleimage2 img {
    height: 50px;
  }

  .blur-image-service img {
    height: 150px;
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .servicenameservice {
    margin-top: -30%;
    display: flex;
    justify-content: center;
  }

  .servicenameimage img {
    height: 15px;
    margin-left: 40px;
    /* animation: zoom-in-zoom-out 10s ease infinite; */
  }

  .eleimage3 {
    margin-left: 0px;
  }

  .eleimage3 img {
    height: 50px;
    margin-top: -10px;
  }

  .eleimage4 {
    margin-left: 20px;
  }

  .eleimage4 img {
    height: 60px;
    margin-top: 20px;
  }

  .imagediv2 {
    display: flex;
    /* border: 1px solid black; */
    margin-top: 10px;
    margin-left: 8%;
    margin-right: 1%;
    justify-content: space-between;
  }

  #elements {
    animation: moveElements 5s infinite;
  }

  .Services-content-start-mob {
    display: block;
    /* border: 1px solid black; */
    margin-top: -40%;
    width: 90%;
    margin-left: 5%;
  }

  .serviceimagemobdiv img {
    width: 100%;
    height: 250px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .maincontentmob {
    text-align: start;
    padding-left: 30px !important;
  }

  .maincontentmob h2 {
    font-size: 20px;
  }

  .contenttextmob {
    width: 95%;
  }

  .contenttextmob p {
    font-size: 17px !important;
    line-height: 25px !important;
  }

}

@media (min-width: 450px) and (max-width: 576px) {
  .main-service-container-service {
    display: none;
  }

  .Services-content-start {
    display: none;
  }

  .main-service-container-service-mob {
    display: block;
    background-image: url("../../assets/images/services/servicebgcirclemob.svg");
    width: auto;
    /* border: 1px solid black; */
    height: 500px;
    background-repeat: no-repeat;
    margin-top: -8%;
    background-position: center;
  }

  .imagediv1 {
    display: flex;
    /* border: 1px solid black; */
    /* margin-top: 20%; */
    padding-top: 20%;
    margin-left: 10%;
    margin-right: 8%;
    justify-content: space-between;
  }

  .eleimage1 img {
    height: 50px;
  }

  .eleimage2 img {
    height: 50px;
  }

  .blur-image-service img {
    height: 150px;
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .servicenameservice {
    margin-top: -22%;
    display: flex;
    justify-content: center;
  }

  .servicenameimage img {
    height: 15px;
    margin-left: 40px;
    /* animation: zoom-in-zoom-out 10s ease infinite; */
  }

  .eleimage3 {
    margin-left: 0px;
  }

  .eleimage3 img {
    height: 50px;
    margin-top: -10px;
  }

  .eleimage4 {
    margin-left: 20px;
  }

  .eleimage4 img {
    height: 60px;
    margin-top: 20px;
  }

  .imagediv2 {
    display: flex;
    /* border: 1px solid black; */
    margin-top: 10px;
    margin-left: 8%;
    margin-right: 1%;
    justify-content: space-between;
  }

  #elements {
    animation: moveElements 5s infinite;
  }

  .Services-content-start-mob {
    display: block;
    /* border: 1px solid black; */
    margin-top: -30%;
    width: 90%;
    margin-left: 5%;
  }

  .serviceimagemobdiv img {
    width: 100%;
    height: 300px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .maincontentmob {
    text-align: start;
    padding-left: 30px !important;
  }

  .maincontentmob h2 {
    font-size: 20px;
  }

  .contenttextmob {
    width: 95%;
  }

  .contenttextmob p {
    font-size: 17px !important;
    line-height: 25px !important;
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .main-service-container-service {
    display: none;
  }

  .Services-content-start {
    display: none;
  }

  .main-service-container-service-mob {
    display: block;
    background-image: url("../../assets/images/services/servicebgcirclemob.svg");
    width: auto;
    /* border: 1px solid black; */
    height: 500px;
    background-repeat: no-repeat;
    margin-top: -8%;
    background-position: center;
  }

  .imagediv1 {
    display: flex;
    /* border: 1px solid black; */
    /* margin-top: 20%; */
    padding-top: 20%;
    margin-left: 8%;
    margin-right: 10%;
    justify-content: space-between;
  }

  .eleimage1 img {
    height: 50px;
  }

  .eleimage2 img {
    height: 50px;
  }

  .blur-image-service img {
    height: 150px;
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .servicenameservice {
    margin-top: -22%;
    display: flex;
    justify-content: center;
  }

  .servicenameimage img {
    height: 15px;
    margin-left: 40px;
    /* animation: zoom-in-zoom-out 10s ease infinite; */
  }

  .eleimage3 {
    margin-left: 0px;
  }

  .eleimage3 img {
    height: 50px;
    margin-top: -10px;
  }

  .eleimage4 {
    margin-left: 20px;
  }

  .eleimage4 img {
    height: 60px;
    margin-top: 20px;
  }

  .imagediv2 {
    display: flex;
    /* border: 1px solid black; */
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 5%;
    justify-content: space-between;
  }

  #elements {
    animation: moveElements 5s infinite;
  }

  .Services-content-start-mob {
    display: block;
    /* border: 1px solid black; */
    margin-top: -20%;
    width: 90%;
    margin-left: 5%;
  }

  .serviceimagemobdiv img {
    width: 100%;
    height: 350px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .maincontentmob {
    text-align: start;
    padding-left: 30px !important;
  }

  .maincontentmob h2 {
    font-size: 20px;
  }

  .contenttextmob {
    width: 95%;
  }

  .contenttextmob p {
    font-size: 17px !important;
    line-height: 25px !important;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .main-service-container-service {
    display: none;
  }

  .Services-content-start {
    display: none;
  }

  .main-service-container-service-mob {
    display: block;
    background-image: url("../../assets/images/services/servicebgcircle.svg");
    width: auto;
    /* border: 1px solid black; */
    height: 500px;
    background-repeat: no-repeat;
    margin-top: -8%;
    background-position: center;
  }

  .imagediv1 {
    display: flex;
    /* border: 1px solid black; */
    /* margin-top: 20%; */
    padding-top: 25%;
    margin-left: 10%;
    margin-right: 12%;
    justify-content: space-between;
  }

  .eleimage1 img {
    height: 50px;
  }

  .eleimage2 img {
    height: 50px;
  }

  .blur-image-service img {
    height: 170px;
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .servicenameservice {
    margin-top: -15%;
    display: flex;
    justify-content: center;
  }

  .servicenameimage img {
    height: 20px;
    margin-left: 40px;
    /* animation: zoom-in-zoom-out 10s ease infinite; */
  }

  .eleimage3 {
    margin-left: 0px;
  }

  .eleimage3 img {
    height: 50px;
    margin-top: -10px;
  }

  .eleimage4 {
    margin-left: 20px;
  }

  .eleimage4 img {
    height: 60px;
    margin-top: 20px;
  }

  .imagediv2 {
    display: flex;
    /* border: 1px solid black; */
    margin-top: 10px;
    margin-left: 8%;
    margin-right: 1%;
    justify-content: space-between;
  }

  #elements {
    animation: moveElements 5s infinite;
  }

  .Services-content-start-mob {
    display: block;
    /* border: 1px solid black; */
    margin-top: -0%;
    width: 90%;
    margin-left: 5%;
  }

  .serviceimagemobdiv img {
    width: 90%;
    height: 400px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .maincontentmob {
    text-align: start;
    padding-left: 30px !important;
  }

  .maincontentmob h2 {
    font-size: 20px;
  }

  .contenttextmob {
    width: 95%;
  }

  .contenttextmob p {
    font-size: 17px !important;
    line-height: 25px !important;
  }

}

@media (min-width: 991px) and (max-width: 1200px) {
  .main-service-container-service {
    background-image: url("../../assets/images/services/servicebgcircle.svg");
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    margin-top: -8%;
    background-position: center;
    /* object-fit: cover; */
  }

  .imagediv1 {
    display: flex;
    /* border: 1px solid black; */
    /* margin-top: 20%; */
    padding-top: 20%;
    margin-left: 8%;
    margin-right: 8%;
    justify-content: space-between;
  }

  .eleimage1 img {
    height: 80px;
  }

  .eleimage2 img {
    height: 80px;
  }

  .blur-image-service img {
    height: 450px;
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .servicenameservice {
    margin-top: -30%;
    display: flex;
    justify-content: center;
  }

  .servicenameimage img {
    height: 40px;
    margin-left: 150px;
    /* animation: zoom-in-zoom-out 10s ease infinite; */
  }

  .eleimage3 {
    margin-left: -0px;
  }

  .eleimage3 img {
    height: 80px;
    margin-top: -50px;
  }

  .eleimage4 {
    margin-left: 130px;
  }

  .eleimage4 img {
    height: 80px;
    margin-top: 70px;
  }

  .imagediv2 {
    display: flex;
    /* border: 1px solid black; */
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 2%;
    justify-content: space-between;
  }

  #elements {
    animation: moveElements 5s infinite;
  }

  @keyframes moveElements {

    /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
    0% {
      transform: translate(-1%, -1%);
    }

    25% {
      transform: translateX(-1vw) translateY(-2%);
    }

    50% {
      transform: translateX(-1vw) translateY(-2vh);
    }

    75% {
      transform: translateX(1%) translateY(-1vh);
    }

    100% {
      transform: translate(-1%, -2%);
    }
  }

  .Services-content-start {
    /* border: 1px solid black; */
    margin-top: -250px;
    /* padding: 50px; */
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .contentservicemain1 {
    /* border: 1px solid black; */
    width: 90%;
    margin-left: 5%;
    display: flex;
    margin-top: 30px !important;
  }
  .parastyle p{
      font-size: 18px !important;
  }

  .serviceimage1 {
    /* border: 1px solid black; */
    width: 60%;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .serviceimage1 img {
    width: 90%;
    height: 350px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .servicecontent1 {
    /* border: 1px solid black; */
    width: 40%;
    margin-top: 0%;
  }

  .servicecontent2 {
    /* border: 1px solid black; */
    width: 40%;
    margin-top: 1%;
    margin-left: 2%;
  }

  .main-service-container-service-mob {
    display: none;
  }

  .Services-content-start-mob {
    display: none;
  }

  .main-content img {
    height: 80px;
  }

  .maincontentmob h2 {
    font-size: 18px !important;
  }

  .maincontentmob {
    padding-left: 20px !important;
  }

  .contenttextmob p {
    font-size: 12px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .main-service-container-service {
    background-image: url("../../assets/images/services/servicebgcircle.svg");
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    margin-top: -8%;
    background-position: center;
    /* object-fit: cover; */
  }

  .imagediv1 {
    display: flex;
    /* border: 1px solid black; */
    /* margin-top: 20%; */
    padding-top: 15%;
    margin-left: 18%;
    margin-right: 22%;
    justify-content: space-between;
  }

  .eleimage1 img {
    height: 80px;
  }

  .eleimage2 img {
    height: 80px;
  }

  .blur-image-service img {
    height: 450px;
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .servicenameservice {
    margin-top: -23%;
    display: flex;
    justify-content: center;
  }

  .servicenameimage img {
    height: 40px;
    margin-left: 150px;
    /* animation: zoom-in-zoom-out 10s ease infinite; */
  }

  .eleimage3 {
    margin-left: -0px;
  }

  .eleimage3 img {
    height: 80px;
    margin-top: -50px;
  }

  .eleimage4 {
    margin-left: 130px;
  }

  .eleimage4 img {
    height: 80px;
    margin-top: 70px;
  }

  .imagediv2 {
    display: flex;
    /* border: 1px solid black; */
    margin-top: 20px;
    margin-left: 12%;
    margin-right: 10%;
    justify-content: space-between;
  }

  #elements {
    animation: moveElements 5s infinite;
  }

  @keyframes moveElements {

    /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
    0% {
      transform: translate(-1%, -1%);
    }

    25% {
      transform: translateX(-1vw) translateY(-2%);
    }

    50% {
      transform: translateX(-1vw) translateY(-2vh);
    }

    75% {
      transform: translateX(1%) translateY(-1vh);
    }

    100% {
      transform: translate(-1%, -2%);
    }
  }

  .Services-content-start {
    /* border: 1px solid black; */
    margin-top: -230px !important;
    /* padding: 50px; */
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .parastyle p {
    font-size: 20px !important;
  }

  .contentservicemain1 {
    /* border: 1px solid black; */
    width: 90%;
    margin-left: 5%;
    display: flex;
  }

  .serviceimage1 {
    /* border: 1px solid black; */
    width: 60%;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .serviceimage1 img {
    width: 90%;
    height: 360px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .servicecontent1 {
    /* border: 1px solid black; */
    width: 40%;
    margin-top: 0%;
  }

  .servicecontent2 {
    /* border: 1px solid black; */
    width: 45%;
    margin-top: 0%;
    margin-left: 0%;
  }

  .main-content {
    padding-top: 40px !important;
  }

  .main-service-container-service-mob {
    display: none;
  }

  .Services-content-start-mob {
    display: none;
  }

  .main-content img {
    height: 80px;
  }

  .maincontentmob h2 {
    font-size: 18px !important;
  }

  .maincontentmob {
    padding-left: 20px !important;
  }

  .contenttextmob p {
    font-size: 12px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .main-service-container-service {
    background-image: url("../../assets/images/services/servicebgcircle.svg");
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    margin-top: -8%;
    background-position: center;
    /* object-fit: cover; */
  }

  .imagediv1 {
    display: flex;
    /* border: 1px solid black; */
    /* margin-top: 20%; */
    padding-top: 15%;
    margin-left: 18%;
    margin-right: 22%;
    justify-content: space-between;
  }

  .eleimage1 img {
    height: 80px;
  }

  .eleimage2 img {
    height: 80px;
  }

  .blur-image-service img {
    height: 450px;
    animation: zoom-in-zoom-out 3s ease infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 100;
      transform: scale(1, 1);
    }

    50% {
      opacity: 100;
      transform: scale(1.5, 1.5);
    }

    100% {
      opacity: 100;
      transform: scale(1, 1);
    }
  }

  .servicenameservice {
    margin-top: -23%;
    display: flex;
    justify-content: center;
  }

  .servicenameimage img {
    height: 40px;
    margin-left: 150px;
    /* animation: zoom-in-zoom-out 10s ease infinite; */
  }

  .eleimage3 {
    margin-left: -0px;
  }

  .eleimage3 img {
    height: 80px;
    margin-top: -50px;
  }

  .eleimage4 {
    margin-left: 130px;
  }

  .eleimage4 img {
    height: 80px;
    margin-top: 70px;
  }

  .imagediv2 {
    display: flex;
    /* border: 1px solid black; */
    margin-top: 20px;
    margin-left: 12%;
    margin-right: 10%;
    justify-content: space-between;
  }

  #elements {
    animation: moveElements 5s infinite;
  }

  @keyframes moveElements {

    /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
    0% {
      transform: translate(-1%, -1%);
    }

    25% {
      transform: translateX(-1vw) translateY(-2%);
    }

    50% {
      transform: translateX(-1vw) translateY(-2vh);
    }

    75% {
      transform: translateX(1%) translateY(-1vh);
    }

    100% {
      transform: translate(-1%, -2%);
    }
  }

  .Services-content-start {
    /* border: 1px solid black; */
    margin-top: -200px !important;
    /* padding: 50px; */
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .parastyle p {
    font-size: 22px !important;
  }

  .contentservicemain1 {
    /* border: 1px solid black; */
    width: 90%;
    margin-left: 5%;
    display: flex;
  }

  .serviceimage1 {
    /* border: 1px solid black; */
    width: 60%;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .serviceimage1 img {
    width: 90%;
    height: 400px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;

  }

  .main-content {
    padding-top: 20px !important;
  }

  .servicecontent1 {
    /* border: 1px solid black; */
    width: 40%;
    margin-top: 3%;
  }


  .servicecontent2 {
    /* border: 1px solid black; */
    width: 40%;
    margin-top: 3%;
    margin-left: 2%;
  }

  .main-service-container-service-mob {
    display: none;
  }

  .Services-content-start-mob {
    display: none;
  }

  .main-content img {
    height: 80px;
  }

  .maincontentmob h2 {
    font-size: 18px !important;
  }

  .maincontentmob {
    padding-left: 20px !important;
  }

  .contenttextmob p {
    font-size: 12px !important;
  }
}