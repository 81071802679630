
.main-footer-container {
    /* margin-left: -120px; */
    margin-top: 7%;
    /* border: 1px solid black; */
    height: 500px;
    background-image: url("../../assets//images/footerpage-images/bgimg.svg");
    /* background-repeat: no-repeat; */
    object-fit: cover;
    padding: 20px;
}

.footer {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    border: 1px solid #0090E0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.footer span {
    /* border: 1px solid black; */
    color: #e0e0e0;
    font-size: 22px;
    font-weight: 300;
    margin-top: 100px !important;
}

.footer-div1 {
    width: 20%;
    /* border: 1px solid black; */
    text-align: start;
    padding-left: 100px;
    padding-top: 60px;
    margin-left: 50px;
}

.footer-div2 {
    width: 20%;
    /* border: 1px solid black; */
    padding-top: 90px;
    /* margin-left: 170px; */
   

}

.footer-div3 {
    width: 18%;
    /* border: 1px solid black; */
}

.footer-div4 {
    width: 18%;
    /* border: 1px solid black; */
    padding-top: 110px;
}

.footerlogoimg {
    width: 55%;
}

.address {
    width: 100%;
    color: white;
    font-weight: 300;
    font-size: 20px;
    word-spacing: 0px;
    margin-top: 70px;
}

.footer-div2 h2 {
    color: #ffff;
    font-size: 20px;
    font-weight: 500;
    /* margin-top: -10px; */
}

.footer-div4 h2 {
    color: #ffff;
    font-size: 20px;
    font-weight: 500;
    margin-top: -10px;
}

.footer-div2 h3 {
    text-align: start;
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-left: 95px;
    cursor: pointer;
    margin-top: -7px;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: none;
    text-decoration-color:#e0e0e0 ;
}

.footer-div1 h2 {
    color: #ffff;
    font-size: 20px;
    font-weight: 500;
}

.footer-div4 h3 {
    text-align: start;
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-left: 110px;
    cursor: pointer;
    margin-top: -7px;
    /* margin-top: 50px; */
}

.socialicon {
    height: 50px;
    cursor: pointer
}
.main-footer-container-mob {
    display: none;
}
.main-footer-container-tab{
    display: none;
}
@media (max-width:450px) {
    .main-footer-container-mob {
        display: block;
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 700px;
        background-image: url("../../assets//images/footerpage-images/footer-mob.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 700px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer-div1 {
        width: 100%;
        /* border: 1px solid black; */
        text-align: center;
        padding: 0px;
        padding-top: 30px;
        margin-left: 0px;
    }

    .footerlogoimg {
        width: 40%;
    }

    .address {
        width: 80%;
        color: white;
        font-weight: 200;
        font-size: 17px;
        word-spacing: 0px;
        text-align: center;
        margin-left: 10%;
        margin-top: 40px;
    }

    .footer-div2 {
        width: 100%;
        /* border: 1px solid black; */
        padding-top: 50px;
        text-align: center;
        /* margin-left: 170px; */
    }

    .footer-div2 h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 0px;
        margin-top: 7px;
    }

    .footer-div2 h4 {
        color: #fff;
    }

    .footer-div1 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 30px;
        margin-top: 0px;
    }

    .socialicon {
        height: 40px;
        cursor: pointer;
        margin-left: 45px;
    }
    .main-footer-container{
        display: none;
    }
}

@media (min-width: 450px) and (max-width: 576px) {
    .main-footer-container-mob {
        display: block;
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 700px;
        background-image: url("../../assets//images/footerpage-images/footer-mob.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }
    .main-footer-container-tab{
        display: none;
    }
    .main-footer-container{
        display: none;
    }
    .footer {
        width: 100%;
        height: 700px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer-div1 {
        width: 100%;
        /* border: 1px solid black; */
        text-align: center;
        padding: 0px;
        padding-top: 30px;
        margin-left: 0px;
    }

    .footerlogoimg {
        width: 40%;
    }

    .address {
        width: 80%;
        color: white;
        font-weight: 200;
        font-size: 17px;
        word-spacing: 0px;
        text-align: center;
        margin-left: 10%;
        margin-top: 40px;
    }

    .footer-div2 {
        width: 100%;
        /* border: 1px solid black; */
        padding-top: 50px;
        text-align: center;
        /* margin-left: 170px; */
    }

    .footer-div2 h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 0px;
        margin-top: 7px;
    }

    .footer-div2 h4 {
        color: #fff;
    }

    .footer-div1 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 30px;
        margin-top: 0px;
    }

    .payment-se {
        margin-top: 140px !important;
    }

    .socialicon {
        height: 40px;
        cursor: pointer;
        margin-left: 45px;
    }


}


@media (min-width: 577px) and (max-width: 768px) {
    
    .main-footer-container-mob {
        display: block;
        margin-top: 10%;
        /* border: 1px solid black; */
        height: 700px;
        background-image: url("../../assets//images/footerpage-images/footer-mob.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }
    .main-footer-container-tab{
        display: none;
    }
    .main-footer-container{
        display: none;
    }

    .footer {
        width: 100%;
        height: 700px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer-div1 {
        width: 100%;
        /* border: 1px solid black; */
        text-align: center;
        padding: 0px;
        padding-top: 30px;
        margin-left: 0px;
    }

    .footerlogoimg {
        width: 40%;
    }

    .address {
        width: 80%;
        color: white;
        font-weight: 200;
        font-size: 17px;
        word-spacing: 0px;
        text-align: center;
        margin-left: 10%;
        margin-top: 40px;
    }

    .footer-div2 {
        width: 100%;
        /* border: 1px solid black; */
        padding-top: 30px;
        text-align: center;
        /* margin-left: 170px; */
    }

    .footer-div2 h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 0px;
        margin-top: 7px;
    }

    .footer-div2 h4 {
        color: #fff;
    }

    .footer-div1 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 30px;
        margin-top: 0px;
    }

    .socialicon {
        height: 40px;
        cursor: pointer;
        margin-left: 45px;
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    
    .main-footer-container-tab {
        /* margin-left: -120px; */
        margin-top: 7%;
        display: block;
        /* border: 1px solid black; */
        height: 450px;
        background-image: url("../../assets//images/footerpage-images/bgimg.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }
    .main-footer-container{
        display: none;
    }
    .footer {
        width: 100%;
        height: 450px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer span {
        /* border: 1px solid black; */
        color: #e0e0e0;
        font-size: 22px;
        font-weight: 300;
        margin-top: 100px !important;
    }

    .footer-div1 {
        width: 25%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 0px;
        padding-top: 40px;
        margin-left: 30px;
    }

    .footer-div5 {
        width: 25%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 0px;
        padding-top: 40px;
        margin-left: 0px;
    }

    .footer-div5 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: 20px !important;
        text-align: center;
    }

    .footer-div2 {
        width: 19%;
        padding: 10px;
        /* border: 1px solid black; */
        padding-top: 40px;
        /* margin-left: 170px; */
    }

    .socialmedia {
        width: 60%;
        /* border: 1px solid black; */
        margin-top: 40px;
        margin-left: 25px;

    }

    .footer-div4 {
        width: 19%;
        /* border: 1px solid black; */
        padding-top: 80px;
        
    }

    .footerlogoimg {
        width: 90%;
    }

    .address {
        width: 100%;
        color: white;
        font-weight: 300;
        font-size: 17px;
        word-spacing: 0px;
        margin-top: 50px;
    }

    .address span {
        font-size: 17px;
        margin-top: 40px;
    }

    .footer-div2 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        /* margin-top: -10px; */
    }

    .footer-div4 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: -22px;
    }

    .footer-div2 h3 {
        text-align: start;
        font-size: 16px;
        font-weight: 400;
        color: white;
        margin-left: 20px;
        cursor: pointer;
        margin-top: -7px;
    }

    .footer-div1 h2 {
        color: #ffff;
        font-size: 18px;
        font-weight: 500;
    }

    .footer-div4 h3 {
        text-align: start;
        font-size: 18px;
        font-weight: 400;
        color: white;
        margin-left: 32px;
        cursor: pointer;
        margin-top: -7px;
        /* margin-top: 50px; */
    }

    .socialicon {
        height: 30px;
        cursor: pointer
    }
    .copyright {
        margin-top: 80px !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    
    .main-footer-container {
        /* margin-left: -120px; */
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 400px;
        background-image: url("../../assets//images/footerpage-images/bgimg.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 400px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer span {
        /* border: 1px solid black; */
        color: #e0e0e0;
        font-size: 22px;
        font-weight: 300;
        margin-top: 100px !important;
    }

    .footer-div1 {
        width: 25%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 0px;
        padding-top: 20px;
        margin-left: 30px;
    }

    .footer-div5 {
        width: 25%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 0px;
        padding-top: 40px;
        margin-left: 0px;
    }

    .footer-div5 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: 50px !important;
        text-align: center;
    }

    .footer-div2 {
        width: 19%;
        padding: 10px;
        /* border: 1px solid black; */
        padding-top: 40px;
        /* margin-left: 170px; */
    }

    .socialmedia {
        width: 60%;
        /* border: 1px solid black; */
        margin-top: 40px;
        margin-left: 25px;

    }

    .footer-div4 {
        width: 19%;
        /* border: 1px solid black; */
        padding-top: 80px;
    }

    .footerlogoimg {
        width: 90%;
    }

    .address {
        width: 100%;
        color: white;
        font-weight: 300;
        font-size: 17px;
        word-spacing: 0px;
        margin-top: 50px;
    }

    .address span {
        font-size: 17px;
        margin-top: 40px;
    }

    .footer-div2 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        /* margin-top: -10px; */
    }

    .footer-div4 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: -22px;
    }

    .footer-div2 h3 {
        text-align: start;
        font-size: 16px;
        font-weight: 400;
        color: white;
        margin-left: 20px;
        cursor: pointer;
        margin-top: -7px;
    }

    .footer-div1 h2 {
        color: #ffff;
        font-size: 18px;
        font-weight: 500;
    }

    .footer-div4 h3 {
        text-align: start;
        font-size: 18px;
        font-weight: 400;
        color: white;
        margin-left: 32px;
        cursor: pointer;
        margin-top: -7px;
        /* margin-top: 50px; */
    }

    .socialicon {
        height: 30px;
        cursor: pointer
    }
    .copyright {
        margin-top: 80px !important;
    }

}

@media (min-width: 1201px) and (max-width: 1500px) {
    
    .main-footer-container {
        /* margin-left: -120px; */
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 500px;
        background-image: url("../../assets//images/footerpage-images/bgimg.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 500px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer span {
        /* border: 1px solid black; */
        color: #e0e0e0;
        font-size: 22px;
        font-weight: 300;
        margin-top: 100px !important;
    }

    .footer-div1 {
        width: 20%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 60px;
        padding-top: 40px;
        margin-left: 20px;
    }

    .footer-div2 {
        width: 20%;
        /* border: 1px solid black; */
        padding-top: 60px;
        /* margin-left: 170px; */
    }

    .footer-div3 {
        width: 18%;
        /* border: 1px solid black; */
    }

    .footer-div4 {
        width: 18%;
        /* border: 1px solid black; */
        padding-top: 90px;
    }

    .footerlogoimg {
        width: 55%;
        margin-top: 30px;
    }

    .address {
        width: 100%;
        color: white;
        font-weight: 300;
        font-size: 20px;
        word-spacing: 0px;
        margin-top: 45px;
    }

    .footer-div2 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
        /* margin-top: -10px; */
    }

    .footer-div4 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
    }

    .footer-div2 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 55px;
        cursor: pointer;
        margin-top: -7px;
    }

    .footer-div1 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
    }

    .footer-div4 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 60px;
        cursor: pointer;
        margin-top: -7px;
        /* margin-top: 50px; */
    }

    .festive{
        margin-top: -100px;
    }
    .copyright {
        margin-top: 100px !important;
    }

    .socialicon {
        margin-top: 20px;
        height: 40px;
        cursor: pointer
    }
}

@media (min-width: 1501px) and (max-width: 1700px) {
    
    .main-footer-container {
        /* margin-left: -120px; */
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 500px;
        background-image: url("../../assets//images/footerpage-images/bgimg.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 500px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer span {
        /* border: 1px solid black; */
        color: #e0e0e0;
        font-size: 22px;
        font-weight: 300;
        margin-top: 100px !important;
    }

    .footer-div1 {
        width: 20%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 60px;
        padding-top: 40px;
        margin-left: 20px;
    }

    .footer-div2 {
        width: 20%;
        /* border: 1px solid black; */
        padding-top: 60px;
        /* margin-left: 170px; */
    }

    .footer-div3 {
        width: 18%;
        /* border: 1px solid black; */
    }

    .footer-div4 {
        width: 18%;
        /* border: 1px solid black; */
        padding-top: 90px;
    }

    .footerlogoimg {
        width: 55%;
    }

    .address {
        width: 100%;
        color: white;
        font-weight: 300;
        font-size: 20px;
        word-spacing: 0px;
        margin-top: 70px;
    }

    .footer-div2 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
        /* margin-top: -10px; */
    }

    .footer-div4 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
    }

    .footer-div2 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 55px;
        cursor: pointer;
        margin-top: -7px;
       
    }

    .footer-div1 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
    }

    .footer-div4 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 60px;
        cursor: pointer;
        margin-top: -7px;
        /* margin-top: 50px; */
    }

    .festive{
        margin-top: -70px;
    }
    .copyright {
        margin-top: 130px !important;
    }

    .socialicon {
        margin-top: 20px;
        height: 40px;
        cursor: pointer
    }
}