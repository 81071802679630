.home-page-container {
    /* width: 100%; */
    /* border: 1px solid black; */
    /* padding-top: 150px; */
}

.main-home-container {
    padding-left: 120px;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name,
:where(.css-dev-only-do-not-override-1b0bdye).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon {
    color: #1677ff;
}

.home-text {
    text-align: start;
    margin-top: 10px !important;
    font-size: 53px !important;
    font-weight: 700;
    line-height: 40.6px;
}



.home-text p {
    font-size: 30px;
    color: #5D5D5D;
    line-height: 20px;
    font-weight: 400;
}

.g-ads-img {
    text-align: start;
    /* margin-top: 20px;  */
}

.homebganimation {
    margin-left: 7%;
    position: absolute;
    margin-top: 4%;
    z-index: 1
}
.animationgif{
    height: 400px
}
.g-ads-img img {
    margin-top: 3%;
    height: 80px;
}

.slider {
    width: 85%;
    height: 200px;
    padding-top: 20px !important;
    padding: 10px;
    /* border: 1px solid black; */
    margin-left: 4%;
    /* margin-top: -20%; */
    border-radius: 20px;
    background-color: #ffff;
    box-shadow: 0px 0.2px 30px rgb(233 233 233 / 50%);
    margin-right: -1%;
    z-index: 0;
}

.slider span {
    font-size: 22px;
    font-weight: 600;
    word-spacing: 4px;
    margin-top: 10px !important;
}

.home {
    width: 100%;
    display: flex;
    /* border: 1px solid black; */
    height: 600px
}

.home-content {
    width: 45%;
    /* border: 1px solid black; */
    height: fit-content;
}

.slider-container {
    width: 99%;
    height: 70%;
    /* border: 1px solid black; */
    margin-left: 0.5%;
    margin-top: 20px;
}

.blur-image {
    width: 55%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    margin-top: 50px;
    /* margin-left: 200px; */
    /* display: flex; */
    /* border: 1px solid black */
}

.blur-image-home {
    width: 55%;
    /* height: fit-content; */
    /* padding-top: 150px; */
    overflow: hidden;
    margin-top: -50px;
    /* margin-left: 200px; */
    /* display: flex; */
    /* border: 1px solid black */
}

.blur-img-img {
    margin-left: 50%;
    /* margin-left: 10%; */
    height: 60%;
    margin-top: 80px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
}

.blur-image img {
    height: 60%;
    margin-top: 0px;
    animation: zoom-in-zoom-out 3s ease infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        opacity: 100;
        transform: scale(1, 1);
    }

    50% {
        opacity: 100;
        transform: scale(1.5, 1.5);
    }

    100% {
        opacity: 100;
        transform: scale(1, 1);
    }
}

/* @keyframes orb {
   
    0% {
        opacity: 100;
        transform: translateX(-100px);
    }

    50% {
        opacity: 100;
        transform: translateY(-100px);
    }
    75% {
        opacity: 100;
        transform: translateX(-100px);
    }
   

    100% {
        opacity: 100;
        transform: translateY(-250);
    }
} */

.main-about-container {
    padding-left: 120px;
    margin-top: 3%;
}

.about-content {
    width: 92%;
    /* border: 1px solid black; */
    margin-top: 2%;
    margin-left: 5%;
}

.about-image {
    /* height: 600px !important; */
    width: 50%;
    /* border: 1px solid black; */
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
}

.about-info {
    width: 40%;
    margin-left: 2%;
    /* border: 1px solid black; */
    margin-top: 3%;
}

.about-info h2 {
    margin-top: 40px;
    font-size: 28px;
    font-weight: 400;
    text-align: justify;
    /* line-height: 1px; */
    color: #757575;
}

.about-btn {
    /* border: 1px solid black; */
    width: 30%;
    height: 40px !important;
    margin-top: 50px;
    /* margin-left: 1%; */
}


.know-btn {
    background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
    width: 80%;
    height: 45px;
    text-align: center;
    font-weight: 400;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    margin-top: 0px;
    /* margin-left: 8px; */
    font-size: 18px;
}

.know-btn :hover {
    color: white;
}


.main-services-container {
    /* padding-left: 120px; */
    margin-top: 3%;
}

.blur-image-service1 {
    width: 40%;
    overflow: hidden;
    position: absolute;
    margin-top: -10%;
    /* border: 1px solid black */
}

.blur-image-service1 img {
    margin-left: -20%;
    margin-top: 60px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
}

.blur-image-service-bottom {
    width: 40vw;
    overflow: hidden;
    text-align: end !important;
    margin-left: 59%;
    /* border: 1px solid black; */
    margin-top: -35%;
    position: absolute;

}

.blur-image-service-bottom img {
    margin-top: 50px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
}

.services-content {
    width: 65%;
    justify-content: center !important;
    text-align: center !important;
    /* border: 1px solid black; */
    position: relative;
    margin-top: 80px;
    margin-left: 13%;
    /* background-color: #0090E0; */
    display: flex;
}

.card {
    width: 99%;
    height: 390px;
    /* margin-left: -10px; */
    background-color: #ffffff;
    position: relative;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
    cursor: pointer;
    text-align: center;
    justify-content: center;
    margin-left: 183px;
    overflow: hidden;
    left: -41.9%;
    top: 0.7%;
}

.card1 {
    width: 98.7%;
    height: 390px;
    /* margin-left: -10px; */
    background-color: #ffffff;
    position: relative;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
    cursor: pointer;
    text-align: center;
    justify-content: center;
    margin-left: 183px;
    overflow: hidden;
    left: -41.8%;
    top: 0.7%;
}

.card-style {
    width: 35%;
    height: 396px;
    background-color: #ffffff;
    position: relative;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 5px 2px #f7f6f6;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    margin-left: 200px;
    overflow: hidden;
}

.card-style3 {
    width: 35%;
    height: 396px;
    background-color: #ffffff;
    position: relative;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 5px 2px #f7f6f6;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    margin-left: 50px;
    overflow: hidden;
}

.card-style1 {
    width: 35%;
    height: 396px;
    background-color: #ffffff;
    position: relative;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0px 0px 5px 2px #f7f6f6;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    margin-left: 50px;
    overflow: hidden;
}


.card-style::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    transform: translate(-50%, -50%) rotate(-280deg);
    width: 160%;
    height: 130%;
    background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
    transition: transform 2s;

}

.card-stylemob::before {
    content: '';
    position: absolute;
    top: 50%;
    cursor: pointer;
    left: 50%;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    transform: translate(-50%, -50%) rotate(-280deg);
    width: 160%;
    height: 130%;
    background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
    transition: transform 2s;

}

.card-style3::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    transform: translate(-50%, -50%) rotate(-280deg);
    width: 160%;
    height: 130%;
    background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
    transition: transform 2s;

}

.card-style1::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    transform: translate(-50%, -50%) rotate(-190deg);
    width: 160%;
    height: 130%;
    background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
    transition: transform 2s;

}

.slick-next,
.slick-prev {
    background: #0000;
    border: none !important;
    color: #0000;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    outline: #0090e0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
}

@keyframes rotateBorder {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.card-style:hover::before {
    cursor: pointer;
    transform: translate(-50%, -50%) rotate(-100deg);
    animation: rotateBorder 5s linear infinite;
}

.card-stylemob:hover::before {
    transform: translate(-50%, -50%) rotate(-100deg);
    cursor: pointer;
    animation: rotateBorder 5s linear infinite;
}

.card-style3:hover::before {
    cursor: pointer;
    transform: translate(-50%, -50%) rotate(-100deg);
    animation: rotateBorder 5s linear infinite;
}

.card-style1:hover::before {
    cursor: pointer;
    transform: translate(-50%, -50%) rotate(-100deg);
    animation: rotateBorder 5s linear infinite;
}

.services-content1 {
    width: 65%;
    justify-content: center !important;
    text-align: center !important;
    /* border: 1px solid black; */
    position: relative;
    margin-top: 40px;
    margin-left: 17%;
    display: flex;
}

/* .service-content-div {
    width: 35%;
    height: 550px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 1px 1px 5px gray;
    overflow: hidden;
    border: 3px solid #0090E0;
    
} */
.service-content-div {
    /* width: 35%; */
    width: 100%;
    height: 400px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 1px 1px 5px gray;
    /* overflow: hidden; */
    position: relative;
    color: rgb(88, 199, 250, 0%);
    z-index: 1;
    border: 3px solid transparent;
    /* margin-left: -10px; */
    /* animation: colorChange 5s linear infinite; */
    /* object-fit: cover !important; */
    /* background-repeat: no-repeat; */
    /* background: rgba(255, 255, 255, 0.1); */
    /* backdrop-filter: blur(10px); */
}



@keyframes colorChange {
    0% {
        border-color: #0090E0;
    }

    25% {
        border-color: #fbf6ff;
    }

    50% {
        border-color: #0090E0;
    }

    75% {
        border-color: #6271ff;
    }

    100% {
        border-color: #0090E0;
    }
}

.service-content-div1 {
    width: 100%;
    height: 400px;
    /* border: 1px solid black; */
    margin-left: 0px;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-shadow: 1px 1px 5px gray;
    /* overflow: hidden; */
    /* background-color: white; */
    position: relative;
    z-index: 1;
    border: 3px solid transparent;
    /* animation: colorChange 5s linear infinite; */
    /* background: rgba(255, 255, 255, 0.1); */
    /* backdrop-filter: blur(10px); */
}



.service-content-divbottom {
    width: 100%;
    height: 400px;
    /* border: 1px solid black; */
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    z-index: 1;
    box-shadow: 1px 1px 5px rgb(240, 240, 240);
    /* overflow: hidden; */
    position: relative;

    border: 3px solid transparent;
    /* animation: colorChange 5s linear infinite; */
    /* background: rgba(255, 255, 255, 0.1); */
    /* backdrop-filter: blur(10px); */
}


.service-content-div1bottom {
    width: 100%;
    height: 400px;
    z-index: 1;
    /* border: 1px solid black; */
    margin-left: 0px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 1px 1px 5px rgb(243, 243, 243);
    /* overflow: hidden; */
    position: relative;

    border: 3px solid transparent;
    /* animation: colorChange 5s linear infinite; */
    /* background: rgba(255, 255, 255, 0.1); */
    /* backdrop-filter: blur(10px); */
}

.main-content {
    width: 80%;
    /* height:200px; */
    padding-top: 30px;
    padding-left: 50px;
    text-align: start;
    /* border: 1px solid black; */
}

.main-chooseus-container {
    /* padding-left: 120px; */
    margin-top: 10%;
}

.counter-wrapper {
    width: 92% !important;
    /* border: 3px solid transparent; */
    margin-left: 0px !important;
    margin-top: 0px !important;
    border-radius: 0px;
    padding: 56px !important;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 0px rgb(255, 255, 255);
    animation: none !important;
    background-image: url("../../assets/images/homepage-images/blureffect.svg");
    /* background-repeat: repeat-x; */
    /* object-fit: cover; */
    /* background: rgba(255, 255, 255, 0.1); */
    backdrop-filter: blur(10px);
}

/* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .counter-wrapper {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
} */


.card-counter {
    width: 99.5%;
    height: 260px;
    /* margin-left: -10px; */
    background-color: #ffffff;
    position: relative;
    border-radius: 30px;
    /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
    cursor: pointer;
    text-align: center;
    justify-content: center;
    overflow: hidden;
    left: 0.3%;
    top: 1%;
}

.card-style-counter {
    width: 60%;
    height: 267px;
    background-color: #ffffff;
    position: relative;
    margin-top: 5%;
    border-radius: 30px;
    box-shadow: 0px 0px 5px 2px #f7f6f6;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    margin-left: 20%;
    overflow: hidden;
}

.card-style-counter::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    transform: translate(-50%, -50%) rotate(-190deg);
    width: 160%;
    height: 100%;
    background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
    transition: transform 3s;

}

.card-style-counter::before {
    cursor: pointer;
    transform: translate(-50%, -50%) rotate(-100deg);
    animation: rotateBorder 5s linear infinite;
}

.counter-div {
    width: 20%;
    /* border: 1px solid black; */
    height: 150px;
    margin-top: 0px;
}

.numbers {
    display: flex;
    justify-content: space-around;
}

.number {
    margin-right: 10px;
    /* margin-left: 10px; */
}

.number .counter {
    font-weight: 700;
    font-size: 75px !important;
    /* margin: 0px 0; */
    justify-content: center !important;
    margin-left: -20px;
}

.number span {
    font-size: 20px;
    display: block;
    font-weight: 500;

}

.spanplus {
    font-size: 64px;
    /* margin-left: -150px; */
    font-weight: bold;
    color: #0090E0;
}

.spanplus1 {
    font-size: 64px;
    margin-left: -250px;
    font-weight: bold;
    color: #0090E0;
}

.spanplus2 {
    font-size: 64px;
    margin-left: -300px;
    font-weight: bold;
    color: #0090E0;
}

.spank {
    font-size: 64px;
    /* margin-left: -230px; */
    font-weight: bold;
    margin-top: 20px;
}

.spank1 {
    font-size: 64px;
    margin-left: -200px;
    font-weight: bold;
    margin-top: 20px;
}

.blur-image-chooseus {
    /* width: 30%; */
    overflow: hidden;
    position: absolute;
    margin-top: 4%;
    /* border: 1px solid black */
}

.blur-image-chooseus img {
    margin-left: -40%;
    margin-top: 0px;
    /* border: 1px solid black; */
    animation: zoom-in-zoom-out 3s ease infinite;
}

.chooseus-row {
    width: 67%;
    /* border: 1px solid black; */
    margin-top: 1%;
    margin-left: 17%;
    display: flex;
}

.chooseus-rowsecond {
    width: 67%;
    /* border: 1px solid black; */
    /* margin-top: 6%; */
    margin-left: 17%;
    display: flex;
}

.chooseus-col {
    width: 50%;
    /* border: 1px solid black; */
}

.chooseus-colsecond {
    width: 50%;
    /* border: 1px solid black; */
}

.chooseus-colrecord {
    width: 50%;
    /* border: 1px solid black; */
}

.chooseus-col1 {
    width: 50%;
    /* border: 1px solid black; */
    /* padding: 50px; */
    margin-top: 50px;
}

.chooseus-colrecord img {
    width: 100%;
    margin-top: 0%;
    margin-bottom: -4%;
    margin-left: -4%;
    margin-right: -5%;
}

.chooseus-col img {
    width: 100%;
    margin-top: -2%;
    margin-bottom: -4%;
    margin-left: -3%;
}

.chooseus-colsecond img {
    width: 100%;
    margin-top: -2%;
    margin-bottom: -4%;
    margin-left: -4%;
    margin-right: -11%;
}

.partner-content {
    width: 60%;
    margin-left: 20%;
    /* border: 1px solid black; */
}

.partner-content p {
    font-size: 24px;
    line-height: 40px;
    /* word-spacing: 1px; */
    text-align: start;
}

.main-jobopening-container {
    /* margin-left: -120px; */
    margin-top: 3%;
}

.job-row {
    width: 100wh;
    height: 500px;
    padding: 70px;
    /* padding-bottom: 50px; */
    /* background-color: #0090E0; */
    background-image: linear-gradient(to right, #0090E0, #6DCBFF);
}

/* .jobopeningdiv{
    height: 650px;
    border: 1px solid #faf9f9;
    background-color: #5fc7ff;
    box-shadow: 1px 1px 1px gray;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
} */
/* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .jobopeningdiv {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
} */
.jobopening-col {
    width: 80% !important;
    height: 450px;
    margin-left: 0% !important;
    border: 1px solid #ffffff;
    background-color: #5fc7ff;
    box-shadow: 1px 1px 1px gray;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    /* border: 3px solid transparent; */
    /* animation: colorChange 5s linear infinite; */
}

.job-opening-row {
    /* justify-content: space-between; */
    margin-top: 25px;
}

.jobopening-col img {
    padding: 60px;
    /* height: 160px; */
}

.jobopening-col h2 {
    color: white;
    font-size: 26px;
    font-weight: 500;
    margin-top: -30px;
    /* font-weight: 900; */
}

.jobopening-col h3 {
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 15px;
}

.submit-btn {
    /* border: 1px solid black; */
    width: 60%;
    justify-content: center !important;
    /* height: 80px !important; */
    /* padding-bottom: 50px; */
}

.submit {
    /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
    width: 90%;
    height: 50px;
    background-color: #0090E0;
    text-align: center !important;
    font-weight: 400;
    background-size: 200% auto;
    color: white;
    /* box-shadow: 1px 1px 5px #aaaaaa; */
    border-radius: 10px;
    display: block;
    margin-top: 40px;
    margin-left: 40%;
    font-size: 20px;
}

.submit:hover {
    color: white !important;
}

.elements {
    animation: moveElements 5s infinite;
}

@keyframes moveElements {

    /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
    0% {
        transform: translate(-2%, -2%);
    }

    25% {
        transform: translateX(-3vw) translateY(-3%);
    }

    50% {
        transform: translateX(-1vw) translateY(-3vh);
    }

    75% {
        transform: translateX(-1%) translateY(-3vh);
    }

    100% {
        transform: translate(-1%, -3%);
    }
}



/* ===========slider=============== */
.main-ourgallery-container {
    /* margin-left: -120px; */
    margin-top: 7%;
}

.wrapper {
    padding: 70px 0;
    margin-top: 2%;
    width: 100wh;

    /* border: 1px solid black; */
}

/* Default slide */
.center-slider .slick-slide {
    /* background-color: #b32532; */
    /* margin-right: 20px; */
    color: #FFF;
    height: 400px !important;
    border-radius: 10px;
    /* margin: 0 15px 0 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transform: scale(0.8);
    transition: all 0.4s ease-in-out;
}

.center-slider .slick-slide,
.center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {
    transform: scale(0.8, 0.8);
    transition: all 0.4s ease-in-out;
}

/* Active center slide (You can change anything here for cenetr slide)*/
.center-slider .slick-center,
.center-slider .slick-slide[aria-hidden="true"]:not([tabindex="-1"])+.slick-cloned[aria-hidden="true"] {
    transform: scale(1.1);
    /* background-color: #000000; */
    border-radius: 10px !important;
}

.center-slider .slick-current.slick-active {
    transform: scale(1.1);
    /* background-color: #000000; */
    border-radius: 10px !important;
}

.slick-next,
.slick-prev {
    z-index: 5;
}

.slick-next {
    right: 0px !important;
}

.slick-prev {
    left: 15px;
}

.slick-prev:before,
.slick-next:before {
    /* color: #000 !important; */
    color: #0090e0 !important;
}

.slick-next:before,
.slick-prev:before {
    /* color: #000; */
    font-size: 26px;
}

/* .custom-arrow {
    position: absolute;
    top: 35%;
    transform: translateY(50%);
    font-size: 24px;
    cursor: pointer;
}

.custom-prev {
    left: 10px;
    padding: 20px;
}

.custom-next {
    right: 10px;
    padding: 20px;
} */

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: black;
    outline: none;
    background: transparent;
}

/* .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 46px !important;
    height: 40px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black !important;
    border: none;
    outline: none;
} */


.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 50px !important;
    height: 60px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black !important;
    border: none;
    outline: none;
    /* background: black; */
}

.slick-prev {
    left: -1px !important;
}

.slick-prev {
    left: -1px !important;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 40px !important;
    line-height: 1;
    opacity: .75;
    /* color: black !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-next,
.slick-next:focus {
    background: #0000;
    color: #0000;
    outline: none;
    position: absolute;
    /* top: 38%; */
    /* left: 66%; */
}

.slick-prev,
.slick-prev:focus {
    background: #0000;
    color: #0000;
    outline: none;
    position: absolute;
    /* top: 38%; */
    left: 106%;
}

/* .slick-prev {
    left: 27.5% !important;
} */

.center-mode-slider {
    .slick-center {
        border-radius: 20px;
        /* Add any other styles you want for the center slide */
    }
}

/* ================= Footer ==================== */

.main-footer-container {
    /* margin-left: -120px; */
    margin-top: 7%;
    /* border: 1px solid black; */
    height: 500px;
    background-image: url("../../assets//images/footerpage-images/bgimg.svg");
    /* background-repeat: no-repeat; */
    object-fit: cover;
    padding: 30px;
}

.footer {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    border: 1px solid #0090E0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.footer span {
    /* border: 1px solid black; */
    color: #e0e0e0;
    font-size: 22px;
    font-weight: 300;
    margin-top: 100px !important;
}

.footer-div1 {
    width: 20%;
    /* border: 1px solid black; */
    text-align: start;
    padding-left: 100px;
    padding-top: 60px;
    margin-left: 50px;
}

.footer-div2 {
    width: 20%;
    /* border: 1px solid black; */
    padding-top: 90px;
    /* margin-left: 170px; */
}

.footer-div3 {
    width: 18%;
    /* border: 1px solid black; */
}

.footer-div4 {
    width: 18%;
    /* border: 1px solid black; */
    padding-top: 110px;
}

.footerlogoimg {
    width: 55%;
}

.address {
    width: 100%;
    color: white;
    font-weight: 300;
    font-size: 20px;
    word-spacing: 5px;
    margin-top: 70px;
}

.footer-div2 h2 {
    color: #ffff;
    font-size: 20px;
    font-weight: 500;
    /* margin-top: -10px; */
}

.footer-div4 h2 {
    color: #ffff;
    font-size: 20px;
    font-weight: 500;
    margin-top: -10px;
}

.footer-div2 h3 {
    text-align: start;
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-left: 95px;
    cursor: pointer;
    margin-top: -7px;
}

.footer-div1 h2 {
    color: #ffff;
    font-size: 20px;
    font-weight: 500;
}

.footer-div4 h3 {
    text-align: start;
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-left: 110px;
    cursor: pointer;
    margin-top: -7px;
    /* margin-top: 50px; */
}

.socialicon {
    height: 50px;
    cursor: pointer
}

.home-page-container-mobile {
    display: none;

}

.main-about-container-mobile {
    display: none;
}

.main-services-container-mobile {
    display: none;
}

.home-page-container-mob {
    display: none;
}

.main-chooseus-container-mobile {
    display: none;
}

.main-jobopening-container-mobile {
    display: none;
}

.main-ourgallery-container-mob {
    display: none;
}

.main-footer-container-mob {
    display: none;
}

.desk-container {
    /* border: 1px solid red */
}

.tab-conatiner {
    display: none;
}



/* * ================= for small to large mobile =============== */
@media (max-width:450px) {
    .home-page-container-mob {
        display: block;
        /* z-index: 1; */
        /* border: 1px solid black; */
    }

    .tab-conatiner {
        display: none;
    }

    .desk-container {
        display: none;
    }

    .main-home-container {
        display: none;
    }

    .home-page-container-mobile {
        display: block;
        /* width: 100vw; */
        /* border: 1px solid black; */
        margin-top: -50px;
        height: 650px;
    }

    .mob-home {
        height: 310px;
        display: flex;
    }

    .home {
        width: 100%;
        display: block
    }
    .homebganimation {
        /* margin-left: 0px !important; */
        margin-top: 10%;
        margin-left: 22% !important;
        /* margin-top: -55%; */
        position: absolute;
        z-index: 1
    }
    .animationgif{
        height: 200px
    }
    .home-text {
        text-align: start;
        margin-top: -50px;
        font-size: 25px !important;
        font-weight: 700;
        line-height: 20.6px;
        padding-left: 25px;
    }

    .home-text p {
        font-size: 16px;
        color: #4b4b4b;
        line-height: 10px;
        font-weight: 400;
    }

    .blur-image {
        width: 100%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        text-align: end;
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image img {
        margin-left: -70%;
        width: 60%;
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-img-img {
        margin-left: 10%;
        height: 60%;
        margin-top: 20px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-slider {
        width: 100%;
        margin-top: 20px;
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image-slider img {
        margin-left: -70%;
        width: 70%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }

    .g-ads-img {
        padding-left: 25px;
        text-align: start;
    }

    .g-ads-img img {
        margin-top: 0%;
        height: 56px;
    }
    

    .slider {
        /* width: 100% !important; */
        width: 95% !important;
        height: 140px;
        padding-top: 10px !important;
        /* padding: 10px; */
        /* border: 1px solid black; */
        margin-left: 0% !important;
        /* margin-top: -70%; */
        border-radius: 0px !important;
        background-color: #ffff;
        box-shadow: 0px 0.2px 30px rgb(233 233 233 / 50%);
    }

    .slider span {
        font-size: 16px;
        font-weight: 600;
        word-spacing: 1px;
    }

   
    /* =================about container start ===================== */

    .main-about-container {
        display: none;
    }

    .main-about-container-mobile {
        display: block;
        width: 100% !important;
        /* border: 1px solid black; */
        margin-top: 50px;
        /* padding: 20px; */
    }

    .about-info {
        width: 90%;
        /* margin-left: 2%; */
        /* border: 1px solid black; */
        margin: 30px;
    }

    .about-info h2 {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        padding-right: 20px;
        text-align: justify;
        /* line-height: 1px; */
        color: #757575;
    }

    .main-chooseus-container {
        display: none;
    }

    .main-jobopening-container {
        display: none;
    }

    .main-ourgallery-container {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: block;
    }

    .main-footer-container {
        display: none;
    }

    .about-btn {
        /* border: 1px solid black; */
        width: 40%;
        height: 20px !important;
        margin-top: 30px;
        margin-left: 20px;
    }

    .know-btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 80%;
        height: 40px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-top: 0px;
        margin-left: 8px;
        font-size: 16px;
    }

    .know-btn :hover {
        color: white;
    }

    .about-image {
        /* height: 600px !important; */
        margin-top: 40px;
        width: 100%;
        /* border: 1px solid black; */
        border-top-right-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
    }
    .about-image img{
        /* height: 600px !important; */
        margin-top: 40px;
        width: 95% !important;
        /* border: 1px solid black; */
        border-top-right-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
        /* padding: 7px; */
    }

    /* =======================Service section start================== */
    .main-services-container {
        display: none;
    }

    .main-services-container-mobile {
        display: block;
        width: 100% !important;
        /* border: 1px solid black; */
        margin-top: 30px;
        padding-top: 20px;
    }

    .elements1 {
        /* border: 1px solid black; */
        /* margin-top: -26%; */
    }

    .blur-image-service-mob {
        width: 100%;
        /* margin-top: 20px; */
        /* overflow: hidden; */
        /* border: 1px solid black */
    }

    .blur-image-service-mob img {
        margin-left: 66%;
        width: 30%;
        margin-top: -17%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }

    .service-content-div {
        width: 100%;
        height: 300px;
        margin-left: 0px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        color: rgb(88, 199, 250, 0%);
        border: 2px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* object-fit: cover !important; */
        /* background-repeat: no-repeat; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .main-content {
        width: 80%;
        /* height:200px; */
        padding-top: 20px;
        padding-left: 40px;
        text-align: start;
        /* border: 1px solid black; */
    }

    .main-content img {
        height: 60px;
    }

    .card {
        width: 99.1%;
        height: 302px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.4%;
        top: 0.8%;
    }

    .card-style {
        width: 80%;
        height: 307px;
        margin-top: 40px;
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 35px;
        overflow: hidden;
    }

    .cardmob {
        width: 99.1%;
        height: 302px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.4%;
        top: 0.8%;
    }

    .card-stylemob {
        width: 80%;
        height: 307px;
        margin-top: 40px;
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 35px;
        overflow: hidden;
    }

    .service-content-div1 {
        margin-top: 0px;
        width: 100%;
        height: 300px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        /* background-color: white; */
        position: relative;
        z-index: 1;
        border: 2px solid transparent;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(1px); */
    }

    .card-style::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 7s linear infinite;
    }

    .card-stylemob::before {
        transform: translate(-50%, -50%) rotate(-100deg);
        cursor: pointer;
        animation: rotateBorder 7s linear infinite;
    }

    .card-style3::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 7s linear infinite;
    }

    .card-style1::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 7s linear infinite;
    }

    .service-content-div2 {
        margin-top: 0px;
        width: 100% !important;
        height: 300px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 1px 1px 5px gray !important;
        /* overflow: hidden; */
        /* background-color: white; */
        position: relative;
        border: 2px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(1px); */
    }

    .blur-image-service2 {
        width: 100%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        text-align: start;
        overflow: hidden;
        /* border: 1px solid black; */
        margin-top: -80%;
    }

    .blur-image-service2 img {
        margin-left: -60px !important;
        /* margin-left: -150% !important; */
        /* height: 5% !important; */
        width: 60%;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    /* ==================why choose us start ===================== */

    .main-chooseus-container-mobile {
        display: block;
        margin-top: 10%;
        /* border: 1px solid black; */
    }

    /* .counter-wrapper {
        width: 70%;
        border: 2px solid transparent;
        margin-left: 5% !important;
        border-radius: 20px;
        display: flex !important;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        background-image: url("../../assets/images/homepage-images/blureffect.svg"); 
    } */

    .counter-wrapper {
        width: 90%;
        border: 2px solid transparent;
        margin-left: 0% !important;
        border-radius: 20px;
        display: flex !important;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        /* animation: colorChange 5s linear infinite; */
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
    }


    .card-counter {
        width: 99%;
        height: 410px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-radius: 30px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        left: 0.5%;
        top: 1%;
    }

    .card-style-counter {
        width: 85% !important;
        height: 418px;
        background-color: #ffffff;
        position: relative;
        margin-top: 5%;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 7% !important;
        overflow: hidden;
    }

    .card-style-counter::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        transform: translate(-50%, -50%) rotate(-190deg);
        width: 160%;
        height: 100%;
        background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
        transition: transform 3s;

    }

    .card-style-counter::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }


    .counter-div {
        width: 90%;
        /* border: 1px solid black; */
        height: 140px;
        margin-top: 20px;
        margin-left: -15% !important;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700 !important;
        font-size: 32px !important;
        /* margin: 0px 0; */
        text-align: center;
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 16px;
        display: block;
        font-weight: 400;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .partner-content-mob {
        width: 60%;
        margin-left: 25%;
        /* border: 1px solid black; */

    }

    .partner-content-mob p {
        font-size: 18px;
        line-height: 28px;
        /* word-spacing: 1px; */
        text-align: start;
        margin-top: -1px;
    }



    .chooseus-col {
        width: 87%;
        margin-left: 8%;

        /* border: 1px solid black; */
    }

    .chooseus-colsecond {
        width: 87%;
        margin-left: 4%;
        /* border: 1px solid black; */
    }

    .chooseus-colrecord {
        width: 82%;
        margin-left: 8%;
        /* border: 1px solid black; */
    }

    .chooseus-col1 {
        width: 50%;
        /* border: 1px solid black; */
        /* padding: 50px; */
        margin-top: 50px;
    }

    .chooseus-colrecord img {
        width: 100%;
        margin-top: 0%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -5%;
    }

    .chooseus-col img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -3%;
    }

    .chooseus-colsecond img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -11%;
    }


    .blur-image-cooseus-mob {
        width: 100%;
        margin-top: -90%;
        /* overflow: hidden; */
        /* border: 1px solid black */
    }

    .blur-image-cooseus-mob img {
        margin-left: -66%;
        width: 70%;
        margin-top: -17%;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-cooseus555-mob {
        width: 100%;
        margin-top: -90%;
    }

    .blur-image-cooseus555-mob img {
        margin-left: 30%;
        width: 70%;
        margin-top: -17%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }



    /* =====================Job opening start ======================= */
    .main-jobopening-container-mobile {
        display: block;
        width: 100vw;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .jobopening-col {
        padding: 0px;
        margin-top: 80px !important;
        width: 70% !important;
        height: 325px !important;
        margin-left: 40px !important;
        text-align: center !important;
        /* margin-left: 4%; */
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 60px;
        border-bottom-left-radius: 60px;
        margin-left: 25px !important;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }
    .center-slider .slick-slide {
        /* background-color: #b32532; */
        /* margin-right: 20px; */
        color: #FFF;
        height: 500px !important;
        border-radius: 10px;
        /* margin: 0 15px 0 0; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transform: scale(0.8);
        transition: all 0.4s ease-in-out;
    }
    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
        /* display: flex !important; */
    }

    .jobopening-col img {
        padding-top: 40px !important;
        height: 60px;
        margin-left: 20px !important;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 18px;
        font-weight: 500;
        margin-top: -30px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 10px;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* padding: 10px !important; */
        /* height:40px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 35px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 10px;
        display: block;
        margin-top: 25px !important;
      
        margin-left: 45%;
        font-size: 12px;
    }

    .submit:hover {
        color: white !important;
    }

    .main-jobopening-container-mobile {
        display: block;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .job-row {
        width: 100vw;
        height: 500px;
        padding: 0px !important;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* ================our gallary start mobile =============== */
    .main-ourgallery-container-mob {
        margin-top: 80px;
    }

    .main-footer-container-mob {
        display: block;
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 700px;
        background-image: url("../../assets//images/footerpage-images/footer-mob.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 700px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer-div1 {
        width: 100%;
        /* border: 1px solid black; */
        text-align: center;
        padding: 0px;
        padding-top: 30px;
        margin-left: 0px;
    }

    .footerlogoimg {
        width: 40%;
    }

    .address {
        width: 80%;
        color: white;
        font-weight: 200;
        font-size: 17px;
        word-spacing: 5px;
        text-align: center;
        margin-left: 10%;
        margin-top: 40px;
    }

    .footer-div2 {
        width: 100%;
        /* border: 1px solid black; */
        padding-top: 50px;
        text-align: center;
        /* margin-left: 170px; */
    }

    .footer-div2 h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 0px;
        margin-top: 7px;
    }

    .footer-div2 h4 {
        color: #fff;
    }

    .footer-div1 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 30px;
        margin-top: 0px;
    }

    .socialicon {
        height: 40px;
        cursor: pointer;
        margin-left: 45px;
    }

    .slide-job {
        /* width: 40%; */
        padding-left: 30px;
        /* border: 1px solid black; */
    }
}


/* ================= For small Tabs ============= */

@media (min-width: 450px) and (max-width: 576px) {
    .home-page-container-mob {
        display: block;
        /* z-index: 1; */
        /* border: 1px solid black; */
    }

    .tab-conatiner {
        display: none;
    }

    .desk-container {
        display: none;
    }

    .main-home-container {
        display: none;
    }

    .home-page-container-mobile {
        display: block;
        /* width: 100vw; */
        /* border: 1px solid black; */
        margin-top: -50px;
        height: 700px;
       
    }

    .mob-home {
        height: 310px;
        display: flex;
    }

    .home {
        width: 100%;
        display: block
    }
    .homebganimation {
        margin-left: 22% !important;
        margin-top: 5%;
        position: absolute;
        z-index: 1
    }
    .animationgif{
        height: 250px
    }
    .home-text {
        text-align: start;
        margin-top: 0px;
        font-size: 25px !important;
        font-weight: 700;
        line-height: 20.6px;
        padding-left: 25px;
    }

    .home-text p {
        font-size: 16px;
        color: #4b4b4b;
        line-height: 10px;
        font-weight: 400;
    }

    .blur-image {
        width: 100%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        text-align: end;
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image img {
        margin-left: -70%;
        width: 60%;
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-img-img {
        margin-left: 10%;
        height: 60%;
        margin-top: 20px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-slider {
        width: 100%;
        margin-top: 20px;
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image-slider img {
        margin-left: -70%;
        width: 70%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }

    .g-ads-img {
        padding-left: 25px;
        text-align: start;
    }

    .g-ads-img img {
        margin-top: 0%;
        height: 56px;
    }

    .slider {
        /* width: 100% !important; */
        width: 96% !important;
        height: 140px;
        padding-top: 10px !important;
        /* padding: 10px; */
        /* border: 1px solid black; */
        margin-left: 0% !important;
        /* margin-top: -70%; */
        border-radius: 0px !important;
        background-color: #ffff;
        box-shadow: 0px 0.2px 30px rgb(233 233 233 / 50%);
    }

    .slider span {
        font-size: 16px;
        font-weight: 600;
        word-spacing: 1px;
    }


    /* =================about container start ===================== */

    .main-about-container {
        display: none;
    }

    .main-about-container-mobile {
        display: block;
        width: 100% !important;
        /* border: 1px solid black; */
        margin-top: 90px;
        /* padding: 20px; */
    }

    .about-info {
        width: 90%;
        /* margin-left: 2%; */
        /* border: 1px solid black; */
        margin: 30px;
    }

    .about-info h2 {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        padding-right: 20px;
        text-align: justify;
        /* line-height: 1px; */
        color: #757575;
    }

    .main-chooseus-container {
        display: none;
    }

    .main-jobopening-container {
        display: none;
    }

    .main-ourgallery-container {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: block;
    }


    .main-footer-container {
        display: none;
    }

    .about-btn {
        /* border: 1px solid black; */
        width: 40%;
        height: 20px !important;
        margin-top: 30px;
        margin-left: 20px;
    }

    .know-btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 80%;
        height: 40px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-top: 0px;
        margin-left: 8px;
        font-size: 16px;
    }

    .know-btn :hover {
        color: white;
    }

    .about-image {
        /* height: 600px !important; */
        margin-top: 40px;
        width: 100%;
        /* border: 1px solid black; */
        border-top-right-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
    }

    /* =======================Service section start================== */
    .main-services-container {
        display: none;
    }

    .main-services-container-mobile {
        display: block;
        width: 100% !important;
        /* border: 1px solid black; */
        margin-top: 30px;
        padding-top: 20px;
    }

    .elements1 {
        /* border: 1px solid black; */
        /* margin-top: -26%; */
    }

    .blur-image-service-mob {
        width: 100%;
        /* margin-top: 20px; */
        /* overflow: hidden; */
        /* border: 1px solid black */
    }

    .blur-image-service-mob img {
        margin-left: 66%;
        width: 30%;
        margin-top: -17%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }

    .service-content-div {
        width: 100%;
        height: 300px;
        margin-left: 0px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        color: rgb(88, 199, 250, 0%);
        border: 2px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* object-fit: cover !important; */
        /* background-repeat: no-repeat; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .main-content {
        width: 80%;
        /* height:200px; */
        padding-top: 20px;
        padding-left: 40px;
        text-align: start;
        /* border: 1px solid black; */
    }

    .main-content img {
        height: 60px;
    }

    .card {
        width: 99%;
        height: 300px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.5%;
        top: 1%;
    }

    .card-style {
        width: 80%;
        height: 307px;
        margin-top: 40px;
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 53px;
        overflow: hidden;
    }

    .cardmob {
        width: 99%;
        height: 300px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.5%;
        top: 1%;
    }

    .card-stylemob {
        width: 80%;
        height: 307px;
        margin-top: 40px;
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 53px;
        overflow: hidden;
    }

    .service-content-div1 {
        margin-top: 0px;
        width: 100%;
        height: 300px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        /* background-color: white; */
        position: relative;
        border: 2px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(1px); */
    }

    .blur-image-service2 {
        width: 100%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        text-align: start;
        overflow: hidden;
        /* border: 1px solid black; */
        margin-top: -80%;
    }

    .blur-image-service2 img {
        margin-left: -60px !important;
        /* margin-left: -150% !important; */
        /* height: 5% !important; */
        width: 60%;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    /* ==================why choose us start ===================== */

    .main-chooseus-container-mobile {
        display: block;
        margin-top: 15%;
        /* border: 1px solid black; */
    }

    /* 
    .counter-wrapper {
        width: 70%;
        border: 2px solid transparent;
        margin-left: 9% !important;
        border-radius: 20px;
        display: flex !important;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
      
    } */

    .counter-wrapper {
        width: 90%;
        border: 2px solid transparent;
        margin-left: 0% !important;
        border-radius: 20px;
        display: flex !important;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        /* animation: colorChange 5s linear infinite; */
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
    }


    .card-counter {
        width: 99%;
        height: 410px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-radius: 30px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        left: 0.5%;
        top: 1%;
    }

    .card-style-counter {
        width: 85% !important;
        height: 418px;
        background-color: #ffffff;
        position: relative;
        margin-top: 5%;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 7% !important;
        overflow: hidden;
    }

    .card-style-counter::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        transform: translate(-50%, -50%) rotate(-190deg);
        width: 160%;
        height: 100%;
        background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
        transition: transform 3s;

    }

    .card-style-counter::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }


    .counter-div {
        width: 90%;
        /* border: 1px solid black; */
        height: 140px;
        margin-top: 20px;
        margin-left: 5%;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700 !important;
        font-size: 30px !important;
        /* margin: 0px 0; */
        text-align: center;
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 16px;
        display: block;
        font-weight: 400;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .partner-content {
        width: 60%;
        margin-left: 25%;
        /* border: 1px solid black; */

    }

    .partner-content p {
        font-size: 18px;
        line-height: 28px;
        /* word-spacing: 1px; */
        text-align: start;
        margin-top: -1px;
    }



    .chooseus-col {
        width: 87%;
        margin-left: 8%;

        /* border: 1px solid black; */
    }

    .chooseus-colsecond {
        width: 87%;
        margin-left: 4%;
        /* border: 1px solid black; */
    }

    .chooseus-colrecord {
        width: 82%;
        margin-left: 8%;
        /* border: 1px solid black; */
    }

    .chooseus-col1 {
        width: 50%;
        /* border: 1px solid black; */
        /* padding: 50px; */
        margin-top: 50px;
    }

    .chooseus-colrecord img {
        width: 100%;
        margin-top: 0%;
        margin-bottom: -4%;
        margin-left: -2%;
        margin-right: -5%;
    }

    .chooseus-col img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: 0%;
    }

    .chooseus-colsecond img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -1%;
        margin-right: -11%;
    }


    .blur-image-cooseus-mob {
        width: 100%;
        margin-top: -90%;
        /* overflow: hidden; */
        /* border: 1px solid black */
    }

    .blur-image-cooseus-mob img {
        margin-left: -66%;
        width: 70%;
        margin-top: -17%;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-cooseus555-mob {
        width: 100%;
        margin-top: -90%;
    }

    .blur-image-cooseus555-mob img {
        margin-left: 30%;
        width: 70%;
        margin-top: -17%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }



    /* =====================Job opening start ======================= */
    .main-jobopening-container-mobile {
        display: block;
        width: 100vw;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .jobopening-col {
        padding: 0px;
        margin-top: 80px !important;
        width: 50% !important;
        height: 328px !important;
        margin-left: 20px !important;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 60px;
        border-bottom-left-radius: 60px;
        margin-left: 25px !important;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .center-slider .slick-slide {
        /* background-color: #b32532; */
        color: #FFF;
        height: 500px !important;
        border-radius: 10px;
        /* margin: 0 15px 0 0; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transform: scale(0.8);
        transition: all 0.4s ease-in-out;
        padding-left: 0px;
    }

    .job-row {
        width: 100vw;
        height: 450px !important;
        /* padding: 50px !important; */
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
        /* display: flex !important; */
    }

    .jobopening-col img {
        padding-top: 60px !important;
        height: 60px;

    }

    .jobopening-col h2 {
        color: white;
        font-size: 18px;
        font-weight: 500;
        margin-top: -30px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 10px;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 35px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 10px;
        display: block;
        margin-top: 35px;
        margin-left: 45%;
        font-size: 12px;
    }

    .submit:hover {
        color: white !important;
    }

    .main-jobopening-container-mobile {
        display: block;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .job-row {
        width: 100vw;
        height: 430px;
        padding: 0px;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* ================our gallary start mobile =============== */
    .main-ourgallery-container-mob {
        margin-top: 80px;
    }

    .main-footer-container-mob {
        display: block;
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 700px;
        background-image: url("../../assets//images/footerpage-images/footer-mob.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 700px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer-div1 {
        width: 100%;
        /* border: 1px solid black; */
        text-align: center;
        padding: 0px;
        padding-top: 30px;
        margin-left: 0px;
    }

    .footerlogoimg {
        width: 40%;
    }

    .address {
        width: 80%;
        color: white;
        font-weight: 200;
        font-size: 17px;
        word-spacing: 5px;
        text-align: center;
        margin-left: 10%;
        margin-top: 40px;
    }

    .footer-div2 {
        width: 100%;
        /* border: 1px solid black; */
        padding-top: 50px;
        text-align: center;
        /* margin-left: 170px; */
    }

    .footer-div2 h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 0px;
        margin-top: 7px;
    }

    .footer-div2 h4 {
        color: #fff;
    }

    .footer-div1 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 30px;
        margin-top: 0px;
    }

    .socialicon {
        height: 40px;
        cursor: pointer;
        margin-left: 45px;
    }

    .slide-job {
        /* width: 40%; */
        padding-left: 30px;
        /* border: 1px solid black; */
    }

    .footer {
        width: 100%;
        height: 700px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer-div1 {
        width: 100%;
        /* border: 1px solid black; */
        text-align: center;
        padding: 0px;
        padding-top: 30px;
        margin-left: 0px;
    }

    .footerlogoimg {
        width: 40%;
    }

    .address {
        width: 80%;
        color: white;
        font-weight: 200;
        font-size: 17px;
        word-spacing: 5px;
        text-align: center;
        margin-left: 10%;
        margin-top: 40px;
    }

    .footer-div2 {
        width: 100%;
        /* border: 1px solid black; */
        padding-top: 50px;
        text-align: center;
        /* margin-left: 170px; */
    }

    .footer-div2 h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 0px;
        margin-top: 7px;
    }

    .footer-div2 h4 {
        color: #fff;
    }

    .footer-div1 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 30px;
        margin-top: 0px;
    }

    .payment-se {
        margin-top: 140px !important;
    }

    .socialicon {
        height: 40px;
        cursor: pointer;
        margin-left: 45px;
    }

    .festivname {
        margin-top: -100px !important;
    }

    .center-slider .slick-slide {
        /* background-color: #b32532; */
        color: #FFF;
        height: 400px;
        border-radius: 10px;
        /* margin: 0 15px 0 0; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transform: scale(0.8);
        transition: all 0.4s ease-in-out;
        padding-left: 0px !important;
    }

    .proven-track {
        margin-top: 190px !important;
    }

}

/* ================= for large tabs ==================== */

@media (min-width: 577px) and (max-width: 768px) {
    .home-page-container-mob {
        display: block;
        /* z-index: 1; */
        /* border: 1px solid black; */
    }

    .tab-conatiner {
        display: none;
    }

    .desk-container {
        display: none;
    }

    .main-home-container {
        display: none;
    }

    .home-page-container-mobile {
        display: block;
        /* width: 100vw; */
        /* border: 1px solid black; */
        margin-top: -50px;
        height: 700px;
    }

    .mob-home {
        height: 310px;
        display: flex;
    }

    .home {
        width: 100%;
        display: block
    }
    .homebganimation {
        margin-left: 25% !important;
        /* margin-top: -55%; */
        
        z-index: 1
    }
    .animationgif{
        height: 250px
    }

    .home-text {
        text-align: start;
        margin-top: 0px;
        font-size: 25px !important;
        font-weight: 700;
        line-height: 20.6px;
        padding-left: 25px;
    }

    .home-text p {
        font-size: 16px;
        color: #4b4b4b;
        line-height: 10px;
        font-weight: 400;
    }

    .blur-image {
        width: 100%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        text-align: end;
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image img {
        margin-left: -70%;
        width: 60%;
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-img-img {
        margin-left: 10%;
        height: 60%;
        margin-top: 20px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-slider {
        width: 100%;
        margin-top: 20px;
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image-slider img {
        margin-left: -70%;
        width: 70%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }

    .g-ads-img {
        padding-left: 25px;
        text-align: start;
    }

    .g-ads-img img {
        margin-top: 0%;
        height: 56px;
    }
    

    .slider {
        /* width: 100% !important; */
        width: 97% !important;
        height: 140px;
        padding-top: 10px !important;
        /* padding: 10px; */
        /* border: 1px solid black; */
        margin-left: 0% !important;
        /* margin-top: -70%; */
        border-radius: 0px !important;
        background-color: #ffff;
        box-shadow: 0px 0.2px 30px rgb(233 233 233 / 50%);
    }

    .slider span {
        font-size: 16px;
        font-weight: 600;
        word-spacing: 1px;
    }

    .Footer-slide {
        height: 2.5rem !important;
        width: 250px;
        display: flex;
        align-items: center;
        padding: 15px;
        perspective: 100px;
    }

    /* =================about container start ===================== */

    .main-about-container {
        display: none;
    }

    .main-about-container-mobile {
        display: block;
        width: 100% !important;
        /* border: 1px solid black; */
        margin-top: 90px;
        /* padding: 20px; */
    }

    .about-info {
        width: 90%;
        /* margin-left: 2%; */
        /* border: 1px solid black; */
        margin: 30px;
    }

    .about-info h2 {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        padding-right: 20px;
        text-align: justify;
        /* line-height: 1px; */
        color: #757575;
    }

    .main-chooseus-container {
        display: none;
    }

    .main-jobopening-container {
        display: none;
    }

    .main-ourgallery-container {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: block;
    }

    .main-footer-container {
        display: none;
    }

    .about-btn {
        /* border: 1px solid black; */
        width: 40%;
        height: 20px !important;
        margin-top: 30px;
        margin-left: 20px;
    }

    .know-btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 80%;
        height: 40px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-top: 0px;
        margin-left: 8px;
        font-size: 16px;
    }

    .know-btn :hover {
        color: white;
    }

    .about-image {
        /* height: 600px !important; */
        margin-top: 40px;
        width: 100%;
        /* border: 1px solid black; */
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    /* =======================Service section start================== */
    .main-services-container {
        display: none;
    }

    .main-services-container-mobile {
        display: block;
        width: 100% !important;
        /* border: 1px solid black; */
        margin-top: 30px;
        padding-top: 20px;
    }

    .elements1 {
        /* border: 1px solid black; */
        /* margin-top: -26%; */
    }

    .blur-image-service-mob {
        width: 100%;
        /* margin-top: 20px; */
        /* overflow: hidden; */
        /* border: 1px solid black */
    }

    .blur-image-service-mob img {
        margin-left: 66%;
        width: 30%;
        margin-top: -17%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }

    .card {
        width: 99%;
        height: 300px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.5%;
        top: 1%;
    }

    .card-style {
        width: 85%;
        height: 307px;
        margin-top: 40px;
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 53px;
        overflow: hidden;
    }

    .cardmob {
        width: 99%;
        height: 300px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.5%;
        top: 1%;
    }

    .card-stylemob {
        width: 85%;
        height: 307px;
        margin-top: 40px;
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 53px;
        overflow: hidden;
    }



    .service-content-div {
        width: 100%;
        height: 300px;
        margin-left: 0px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        box-shadow: 1px 1px 5px rgb(241, 241, 241);
        /* overflow: hidden; */
        position: relative;
        color: rgb(88, 199, 250, 0%);
        border: 2px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* object-fit: cover !important; */
        /* background-repeat: no-repeat; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .card-style::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }

    .card-stylemob::before {
        transform: translate(-50%, -50%) rotate(-100deg);
        cursor: pointer;
        animation: rotateBorder 5s linear infinite;
    }

    .card-style3::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }

    .card-style1::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }

    .main-content {
        width: 80%;
        /* height:200px; */
        padding-top: 20px;
        padding-left: 40px;
        text-align: start;
        /* border: 1px solid black; */
    }

    .main-content img {
        height: 60px;
    }

    .service-content-div1 {
        margin-top: 0px;
        width: 100%;
        height: 300px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        /* background-color: white; */
        position: relative;
        border: 2px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(1px); */
    }

    .service-content-div2 {
        margin-top: 0px !important;
        width: 100% !important;
        height: 300px;
        /* border: 1px solid black !important; */
        margin-left: 0px !important;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        box-shadow: 1px 1px 5px gray !important;
        /* overflow: hidden; */
        /* background-color: white; */
        position: relative;
        border: 2px solid transparent !important;
        z-index: 1;
        /* animation: colorChange 5s linear infinite !important; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(1px); */
    }

    .blur-image-service2 {
        width: 100%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        text-align: start;
        overflow: hidden;
        /* border: 1px solid black; */
        margin-top: -80%;
    }

    .blur-image-service2 img {
        margin-left: -60px !important;
        /* margin-left: -150% !important; */
        /* height: 5% !important; */
        width: 60%;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    /* ==================why choose us start ===================== */

    .main-chooseus-container-mobile {
        display: block;
        margin-top: 15%;
        /* border: 1px solid black; */
    }

    .counter-wrapper {
        width: 90%;
        border: 2px solid transparent;
        margin-left: 0% !important;
        border-radius: 20px;
        display: flex !important;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        /* animation: colorChange 5s linear infinite; */
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
    }


    .card-counter {
        width: 99%;
        height: 410px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-radius: 30px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        left: 0.5%;
        top: 1%;
    }

    .card-style-counter {
        width: 85% !important;
        height: 418px;
        background-color: #ffffff;
        position: relative;
        margin-top: 5%;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 7% !important;
        overflow: hidden;
    }

    .card-style-counter::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        transform: translate(-50%, -50%) rotate(-190deg);
        width: 160%;
        height: 100%;
        background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
        transition: transform 3s;

    }

    .card-style-counter::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */





    .payment-se {
        margin-top: 230px !important;
    }

    .counter-div {
        width: 90%;
        /* border: 1px solid black; */
        height: 140px;
        margin-top: 20px;
        margin-left: 5%;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .proven-track {
        margin-top: 270px !important;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 50px !important;
        /* margin: 0px 0; */
        text-align: center;
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 16px;
        display: block;
        font-weight: 400;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .partner-content {
        width: 60%;
        margin-left: 25%;
        /* border: 1px solid black; */

    }

    .partner-content p {
        font-size: 18px;
        line-height: 28px;
        /* word-spacing: 1px; */
        text-align: start;
        margin-top: -1px;
    }



    .chooseus-col {
        width: 87%;
        margin-left: 8%;

        /* border: 1px solid black; */
    }

    .chooseus-colsecond {
        width: 87%;
        margin-left: 4%;
        /* border: 1px solid black; */
    }

    .chooseus-colrecord {
        width: 82%;
        margin-left: 8%;
        /* border: 1px solid black; */
    }

    .chooseus-col1 {
        width: 50%;
        /* border: 1px solid black; */
        /* padding: 50px; */
        margin-top: 50px;
    }

    .chooseus-colrecord img {
        width: 100%;
        margin-top: 0%;
        margin-bottom: -4%;
        margin-left: -2%;
        margin-right: -5%;
    }

    .chooseus-col img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: 0%;
    }

    .chooseus-colsecond img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -1%;
        margin-right: -11%;
    }


    .blur-image-cooseus-mob {
        width: 100%;
        margin-top: -90%;
        /* overflow: hidden; */
        /* border: 1px solid black */
    }

    .blur-image-cooseus-mob img {
        margin-left: -66%;
        width: 70%;
        margin-top: -17%;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-cooseus555-mob {
        width: 100%;
        margin-top: -90%;
    }

    .blur-image-cooseus555-mob img {
        margin-left: 30%;
        width: 70%;
        margin-top: -17%;
        /* border: 1px solid black; */
        /* animation: zoom-in-zoom-out 3s ease infinite; */
    }



    /* =====================Job opening start ======================= */
    .main-jobopening-container-mobile {
        display: block;
        width: 100vw;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    .jobopening-col {
        padding: 0px;
        margin-top: 100px;
        width: 65% !important;
        height: 280px;
        margin-left: 111px !important;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 60px;
        border-bottom-left-radius: 60px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
        /* display: flex !important; */
    }

    .jobopening-col img {
        padding-top: 20px;
        height: 60px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 18px;
        font-weight: 500;
        margin-top: -30px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 10px;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 35px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 10px;
        display: block;
        margin-top: 35px;
        margin-left: 45%;
        font-size: 12px;
    }

    .submit:hover {
        color: white !important;
    }

    .main-jobopening-container-mobile {
        display: block;
        /* border: 1px solid black; */
        margin-top: 25%;
    }

    /* .job-row {
        width: 100vw;
        height: 500px;
        padding: 0px;
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    } */

    /* ================our gallary start mobile =============== */
    .main-ourgallery-container-mob {
        margin-top: 80px;
    }

    .festivname {
        margin-top: 0px !important;
    }

    .main-footer-container-mob {
        display: block;
        margin-top: 10%;
        /* border: 1px solid black; */
        height: 700px;
        background-image: url("../../assets//images/footerpage-images/footer-mob.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 700px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer-div1 {
        width: 100%;
        /* border: 1px solid black; */
        text-align: center;
        padding: 0px;
        padding-top: 30px;
        margin-left: 0px;
    }

    .footerlogoimg {
        width: 40%;
    }

    .address {
        width: 80%;
        color: white;
        font-weight: 200;
        font-size: 17px;
        word-spacing: 5px;
        text-align: center;
        margin-left: 10%;
        margin-top: 40px;
    }

    .footer-div2 {
        width: 100%;
        /* border: 1px solid black; */
        padding-top: 50px;
        text-align: center;
        /* margin-left: 170px; */
    }

    .footer-div2 h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 0px;
        margin-top: 7px;
    }

    .footer-div2 h4 {
        color: #fff;
    }

    .footer-div1 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 30px;
        margin-top: 0px;
    }

    .socialicon {
        height: 40px;
        cursor: pointer;
        margin-left: 45px;
    }
}

/* ================== for small Laptops =============== */

@media (min-width: 769px) and (max-width: 991px) {
    /* .container{
    width: 100% !important;
 }*/

    .home-page-container-mobile {
        display: none !important;

    }

    .main-about-container-mobile {
        display: none;
    }

    .main-services-container-mobile {
        display: none;
    }

    .home-page-container-mob {
        display: none;
    }

    .main-chooseus-container-mobile {
        display: none;
    }

    .main-jobopening-container-mobile {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: none;
    }

    .main-footer-container-mob {
        display: none;
    }

    .tab-conatiner {
        display: block;
        width: 100%;
        /* border: 1px solid rgb(0, 255, 98); */
    }

    .desk-container {
        display: none;
    }

    .home-page-container-mob {
        display: none;
    }

    .home-text {
        text-align: start;
        margin-top: 0px !important;
        font-size: 26px !important;
        font-weight: 700;
        line-height: 40.6px;
    }

    .home-text p {
        font-size: 20px;
        color: #5D5D5D;
        line-height: 25px;
        font-weight: 400;
    }

    .g-ads-img {
        text-align: start;
        /* margin-top: 20px;  */
    }

    .g-ads-img img {
        margin-top: 3%;
        height: 80px;
    }
    .homebganimation {
        margin-left: 60% !important;
        /* margin-left: -330px !important; */
        margin-top: 6%;
        position: absolute;
        z-index: 1
    }
    .animationgif{
        height: 200px
    }

    .slider {
        width: 85%;
        height: 160px;
        padding-top: 20px !important;
        padding: 10px;
        /* border: 1px solid black; */
        margin-left: 4%;
        /* margin-top: -60%; */
        border-radius: 20px;
        background-color: #ffff;
        box-shadow: 0px 0.2px 30px rgb(233 233 233 / 50%);
        margin-right: -1%;
        z-index: 0;
    }

    .slider span {
        font-size: 18px;
        font-weight: 600;
        word-spacing: 3px;
        margin-top: 10px !important;
    }

    .home {
        width: 100%;
        display: flex;
        height: 450px;
        /* border: 1px solid black; */
    }

    .home-content {
        width: 52%;
        /* border: 1px solid black; */
        height: fit-content;
    }

    .main-home-container {
        padding-left: 60px;
    }

    .slider-container {
        width: 99%;
        height: 70%;
        /* border: 1px solid black; */
        margin-left: 0.5%;
        margin-top: 20px;
    }

    .blur-image {
        width: 48%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image img {
        margin-left: 5%;
        height: 40%;
        margin-top: 20px;
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-img-img {
        margin-left: 10%;
        height: 60%;
        margin-top: 20px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            opacity: 100;
            transform: scale(1, 1);
        }

        50% {
            opacity: 100;
            transform: scale(1.5, 1.5);
        }

        100% {
            opacity: 100;
            transform: scale(1, 1);
        }
    }

    /* @keyframes orb {
   
    0% {
        opacity: 100;
        transform: translateX(-100px);
    }

    50% {
        opacity: 100;
        transform: translateY(-100px);
    }
    75% {
        opacity: 100;
        transform: translateX(-100px);
    }
   

    100% {
        opacity: 100;
        transform: translateY(-250);
    }
} */

    .main-about-container {
        padding-left: 40px;
        margin-top: 3%;
    }

    .about-content {
        width: 95%;
        /* border: 1px solid black; */
        margin-top: 3%;
        margin-left: 0%;
    }

    .about-image {
        /* height: 600px !important; */
        width: 80%;
        /* border: 1px solid black; */
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    .about-info {
        width: 40%;
        margin-left: 2%;
        /* border: 1px solid black; */
        margin-top: 3%;
    }

    .about-info h2 {
        margin-top: 0px;
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        /* line-height: 1px; */
        color: #757575;
    }

    .about-btn {
        /* border: 1px solid black; */
        width: 50%;
        height: 30px !important;
        margin-top: 10px;
        /* margin-left: 1%; */
    }


    .know-btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 90%;
        height: 40px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-top: 0px;
        /* margin-left: 8px; */
        font-size: 14px;
    }

    .know-btn :hover {
        color: white;
    }


    .main-services-container {
        /* padding-left: 120px; */
        margin-top: 1%;
    }

    .blur-image-service1 {
        width: 50%;
        overflow: hidden;
        position: absolute;
        margin-top: -5%;
        /* border: 1px solid black */
    }

    .blur-image-service1 img {
        margin-left: -50%;
        margin-top: 60px;
        height: 600px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-service-bottom {
        width: 60%;
        overflow: hidden;
        text-align: end !important;
        margin-left: 39%;
        /* border: 1px solid black; */
        margin-top: -50%;
        position: absolute;

    }

    .blur-image-service-bottom img {
        margin-top: 50px;
        height: 600px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .services-content {
        width: 90%;
        justify-content: center !important;
        text-align: center !important;
        /* border: 1px solid black; */
        position: relative;
        margin-top: 60px;
        margin-left: -5.5%;
        /* background-color: #0090E0; */
        display: flex;
    }

    .services-content1 {
        width: 90%;
        justify-content: center !important;
        text-align: center !important;
        /* border: 1px solid black; */
        position: relative;
        margin-top: 40px;
        margin-left: 2%;
        display: flex;
    }

    /* .service-content-div {
    width: 35%;
    height: 550px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 1px 1px 5px gray;
    overflow: hidden;
    border: 3px solid #0090E0;
    
} */
    .service-content-div {
        width: 100%;
        height: 350px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        color: rgb(88, 199, 250, 0%);
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* object-fit: cover !important; */
        /* background-repeat: no-repeat; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .card1 {
        width: 98%;
        height: 353px !important;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 1%;
        top: 1%;
    }

    .card {
        width: 98%;
        height: 353px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 1%;
        top: 1%;
    }

    .card-style {
        width: 35%;
        height: 360px;
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 200px;
        overflow: hidden;
    }

    .card-style3 {
        width: 35%;
        height: 360px;
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 50px;
        overflow: hidden;
    }

    .card-style1 {
        width: 35%;
        height: 360px;
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 50px;
        overflow: hidden;
    }



    @keyframes colorChange {
        0% {
            border-color: #0090E0;
        }

        25% {
            border-color: #fbf6ff;
        }

        50% {
            border-color: #0090E0;
        }

        75% {
            border-color: #6271ff;
        }

        100% {
            border-color: #0090E0;
        }
    }

    .service-content-div1 {
        width: 100%;
        height: 350px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        /* background-color: white; */
        z-index: 1;
        position: relative;
        border: 3px solid transparent;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }



    .service-content-divbottom {
        width: 100%;
        height: 350px;
        /* border: 1px solid black; */
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px); */
    }


    .service-content-div1bottom {
        width: 100%;
        height: 350px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px); */
    }

    .main-content {
        width: 80%;
        /* height:200px; */
        padding-top: 30px;
        padding-left: 30px;
        text-align: start;
        /* border: 1px solid black; */
    }

    .main-chooseus-container {
        /* padding-left: 120px; */
        margin-top: 20%;
    }

    /* .counter-wrapper {
        width: 60%;
        border: 3px solid transparent;
        margin-left: 15%;
        border-radius: 25px;
        display: flex;
        padding: 30px;
        margin-top: 5%;
        justify-content: space-between;
        box-shadow: 2px 2px 4px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
      
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    } */
    .counter-wrapper {
        width: 95% !important;
        border: 3px solid transparent;
        margin-left: 0px;
        border-radius: 30px;
        display: flex;
        padding: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgb(252, 252, 252);
        /* animation: colorChange 5s linear infinite; */
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */


    .card-counter {
        width: 99.3%;
        height: 256px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-radius: 30px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        left: 0.4%;
        top: 1.3%;
    }

    .card-style-counter {
        width: 90% !important;
        height: 263px;
        background-color: #ffffff;
        position: relative;
        margin-top: 5%;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 5% !important;
        overflow: hidden;
    }

    .card-style-counter::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        transform: translate(-50%, -50%) rotate(-190deg);
        width: 160%;
        height: 100%;
        background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
        transition: transform 3s;

    }

    .card-style-counter::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }


    .counter-div {
        width: 25%;
        /* border: 1px solid black; */
        height: 100px;
        margin-top: 0px;
        padding: 5px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        /* margin-right: 10px; */
        margin-left: 10px;
    }

    .number .counter {
        font-weight: 700 !important;
        font-size: 50px !important;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 14px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-chooseus {
        width: 60%;
        overflow: hidden;
        position: absolute;
        margin-top: 4%;
        /* border: 1px solid black */
    }

    .blur-image-chooseus img {
        margin-left: -40%;
        height: 500px;
        margin-top: 50px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .chooseus-row {
        width: 85%;
        /* border: 1px solid black; */
        margin-top: 1%;
        margin-left: 9%;
        display: flex;
    }

    .chooseus-rowsecond {
        width: 85%;
        /* border: 1px solid black; */
        /* margin-top: 6%; */
        margin-left: 9%;
        display: flex;
    }

    .chooseus-col {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-colsecond {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-colrecord {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-col1 {
        width: 50%;
        /* border: 1px solid black; */
        /* padding: 50px; */
        margin-top: 5px;
    }

    .chooseus-colrecord img {
        width: 100%;
        margin-top: 0%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -5%;
    }

    .chooseus-col img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -3%;
    }

    .chooseus-colsecond img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -11%;
    }

    .partner-content {
        width: 60%;
        margin-left: 25%;
        margin-top: -21px;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 16px;
        line-height: 28px;
        /* word-spacing: 1px; */
        text-align: start;
    }

    .main-jobopening-container {
        /* margin-left: -120px; */
        margin-top: 3%;
    }

    .job-row {
        width: 100wh;
        height: 400px;
        padding: 20px;
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
    height: 650px;
    border: 1px solid #faf9f9;
    background-color: #5fc7ff;
    box-shadow: 1px 1px 1px gray;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
} */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .jobopeningdiv {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
} */
    .jobopening-col {
        width: 32%;
        height: 320px;
        margin-left: 5%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .jobopening-col1 {
        width: 38%;
        height: 280px;
        margin-left: 2%;
        margin-top: 15px;
        margin-bottom: 25px;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 15px;
    }

    .job-opening-row1 {
        margin-top: 15px;
        margin-left: 15%;
    }

    .jobopening-col img {
        padding: 20px;
        height: 70px;
        /* height: 160px; */
    }

    .jobopening-col1 img {
        padding: 20px;
        height: 70px;
        /* height: 160px; */
    }

    .jobopening-col h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
        /* font-weight: 900; */
    }

    .jobopening-col1 h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 12px;
    }

    .jobopening-col1 h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 12px;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 50%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 90%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 8px;
        display: block;
        margin-top: 25px;
        margin-left: 55%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }



    /* ===========slider=============== */
    .main-ourgallery-container {
        /* margin-left: -120px; */
        margin-top: 7%;
    }

    .wrapper {
        padding: 70px 0;
        margin-top: 2%;
        width: 100wh;
        /* border: 1px solid black; */
    }

    /* Default slide */
    .center-slider .slick-slide {
        /* background-color: #b32532; */
        color: #FFF;
        height: 400px;
        border-radius: 10px;
        /* margin: 0 15px 0 0; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transform: scale(0.8);
        transition: all 0.4s ease-in-out;
    }

    .center-slider .slick-slide,
    .center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {
        transform: scale(0.8, 0.8);
        transition: all 0.4s ease-in-out;
    }

    /* Active center slide (You can change anything here for cenetr slide)*/
    .center-slider .slick-center,
    .center-slider .slick-slide[aria-hidden="true"]:not([tabindex="-1"])+.slick-cloned[aria-hidden="true"] {
        transform: scale(1.1);
        /* background-color: #000000; */
        border-radius: 10px !important;
    }

    .center-slider .slick-current.slick-active {
        transform: scale(1.1);
        /* background-color: #000000; */
        border-radius: 10px !important;
    }

    .slick-next,
    .slick-prev {
        z-index: 5;
    }

    .slick-next {
        right: 0px !important;
    }

    /* .slick-prev {
        left: 15px;
    } */

    .slick-next:before,
    .slick-prev:before {
        color: #000;
        font-size: 26px;
    }

    /* .custom-arrow {
    position: absolute;
    top: 35%;
    transform: translateY(50%);
    font-size: 24px;
    cursor: pointer;
}

.custom-prev {
    left: 10px;
    padding: 20px;
}

.custom-next {
    right: 10px;
    padding: 20px;
} */

    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        color: black;
        outline: none;
        background: transparent;
    }

    /* .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 46px !important;
    height: 40px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black !important;
    border: none;
    outline: none;
} */
    .slick-prev:before,
    .slick-next:before {
        /* color: #000 !important; */
        color: #0090e0 !important;
    }

    .slick-prev,
    .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 25% !important;
        display: block;
        width: 50px !important;
        height: 60px !important;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: black !important;
        border: none;
        outline: none;
        /* background: black; */
    }

    /* .slick-prev {
        left: -1px !important;
    } */

    .festivetext {
        margin-top: -20%;
    }

    /* .slick-prev {
        left: -1px !important;
    } */

    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 40px !important;
        line-height: 1;
        opacity: .75;
        /* color: black !important; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /* .slick-prev {
        left: 27% !important;
    } */
    .center-mode-slider {
        .slick-center {
            border-radius: 20px;
            /* Add any other styles you want for the center slide */
        }
    }

    .main-footer-container {
        /* margin-left: -120px; */
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 500px;
        background-image: url("../../assets//images/footerpage-images/bgimg.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 500px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer span {
        /* border: 1px solid black; */
        color: #e0e0e0;
        font-size: 22px;
        font-weight: 300;
        margin-top: 100px !important;
    }

    .footer-div1 {
        width: 25%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 0px;
        padding-top: 40px;
        margin-left: 30px;
    }

    .footer-div5 {
        width: 25%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 0px;
        padding-top: 40px;
        margin-left: 0px;
    }

    .footer-div5 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: 50px !important;
        text-align: center;
    }

    .footer-div2 {
        width: 19%;
        padding: 10px;
        /* border: 1px solid black; */
        padding-top: 70px;
        /* margin-left: 170px; */
    }

    .socialmedia {
        width: 60%;
        /* border: 1px solid black; */
        margin-top: 40px;
        margin-left: 25px;

    }

    .footer-div4 {
        width: 19%;
        /* border: 1px solid black; */
        padding-top: 110px;
    }

    .footerlogoimg {
        width: 90%;
    }

    .address {
        width: 100%;
        color: white;
        font-weight: 300;
        font-size: 17px;
        word-spacing: 5px;
        margin-top: 50px;
    }

    .address span {
        font-size: 17px;
        margin-top: 40px;
    }

    .footer-div2 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        /* margin-top: -10px; */
    }

    .footer-div4 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: -22px;
    }

    .footer-div2 h3 {
        text-align: start;
        font-size: 16px;
        font-weight: 400;
        color: white;
        margin-left: 20px;
        cursor: pointer;
        margin-top: -7px;
    }

    .footer-div1 h2 {
        color: #ffff;
        font-size: 18px;
        font-weight: 500;
    }

    .footer-div4 h3 {
        text-align: start;
        font-size: 18px;
        font-weight: 400;
        color: white;
        margin-left: 32px;
        cursor: pointer;
        margin-top: -7px;
        /* margin-top: 50px; */
    }

    .socialicon {
        height: 30px;
        cursor: pointer
    }

    .home-page-container-mobile {
        display: none;

    }

    .main-about-container-mobile {
        display: none;
    }

    .main-services-container-mobile {
        display: none;
    }

    .home-page-container-mob {
        display: none;
    }

    .main-chooseus-container-mobile {
        display: none;
    }

    .main-jobopening-container-mobile {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: none;
    }

    .main-footer-container-mob {
        display: none;
    }


}



/* ================for small to large laptops========== */

@media (min-width: 992px) and (max-width: 1200px) {
    .home-page-container-mobile {
        display: none !important;

    }

    .main-about-container-mobile {
        display: none;
    }

    .main-services-container-mobile {
        display: none;
    }

    .home-page-container-mob {
        display: none;
    }

    .main-chooseus-container-mobile {
        display: none;
    }

    .main-jobopening-container-mobile {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: none;
    }

    .main-footer-container-mob {
        display: none;
    }

    .tab-conatiner {
        display: block;
        width: 100%;
        /* border: 1px solid rgb(0, 255, 98); */
    }

    .desk-container {
        display: none;
    }

    .home-page-container-mob {
        display: none;
    }

    .home-text {
        text-align: start;
        margin-top: 0px !important;
        font-size: 40px !important;
        font-weight: 700;
        line-height: 40.6px;
    }

    .home-text p {
        font-size: 20px;
        color: #5D5D5D;
        line-height: 15px;
        font-weight: 400;
    }

    .g-ads-img {
        text-align: start;
        /* margin-top: 20px;  */
    }

    .g-ads-img img {
        margin-top: 3%;
        height: 80px;
    }
    .homebganimation {
        /* margin-left: -370px !important; */
        margin-left: 65% !important;
        margin-top: 4%;
        position: absolute;
        z-index: 1
    }
    .animationgif{
        height: 250px
    }

    .slider {
        width: 85%;
        height: 160px;
        padding-top: 20px !important;
        padding: 10px;
        /* border: 1px solid black; */
        margin-left: 4%;
        /* margin-top: -40%; */
        border-radius: 20px;
        background-color: #ffff;
        box-shadow: 0px 0.2px 30px rgb(233 233 233 / 50%);
        margin-right: -1%;
        z-index: 0;
    }

    .slider span {
        font-size: 18px;
        font-weight: 600;
        word-spacing: 3px;
        margin-top: 10px !important;
    }

    .home {
        width: 100%;
        display: flex;
        height: 500px;
        /* border: 1px solid black; */
    }

    .home-content {
        width: 57%;
        /* border: 1px solid black; */
        height: fit-content;
    }

    .main-home-container {
        padding-left: 60px;
    }

    .slider-container {
        width: 99%;
        height: 70%;
        /* border: 1px solid black; */
        margin-left: 0.5%;
        margin-top: 20px;
    }

    .blur-image {
        width: 42%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image img {
        margin-left: 5%;
        height: 40%;
        margin-top: 20px;
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-img-img {
        margin-left: 8%;
        height: 60%;
        margin-top: 90px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            opacity: 100;
            transform: scale(1, 1);
        }

        50% {
            opacity: 100;
            transform: scale(1.5, 1.5);
        }

        100% {
            opacity: 100;
            transform: scale(1, 1);
        }
    }

    /* @keyframes orb {
   
    0% {
        opacity: 100;
        transform: translateX(-100px);
    }

    50% {
        opacity: 100;
        transform: translateY(-100px);
    }
    75% {
        opacity: 100;
        transform: translateX(-100px);
    }
   

    100% {
        opacity: 100;
        transform: translateY(-250);
    }
} */

    .main-about-container {
        padding-left: 40px;
        margin-top: 3%;
    }

    .about-content {
        width: 95%;
        /* border: 1px solid black; */
        margin-top: 3%;
        margin-left: 0%;
    }

    .about-image {
        /* height: 600px !important; */
        width: 80%;
        /* border: 1px solid black; */
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    .about-info {
        width: 40%;
        margin-left: 2%;
        /* border: 1px solid black; */
        margin-top: 3%;
    }

    .about-info h2 {
        margin-top: 0px;
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        /* line-height: 1px; */
        color: #757575;
    }

    .about-btn {
        /* border: 1px solid black; */
        width: 50%;
        height: 30px !important;
        margin-top: 10px;
        /* margin-left: 1%; */
    }


    .know-btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 90%;
        height: 40px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-top: 0px;
        /* margin-left: 8px; */
        font-size: 14px;
    }

    .know-btn :hover {
        color: white;
    }


    .main-services-container {
        /* padding-left: 120px; */
        margin-top: 1%;
    }

    .blur-image-service1 {
        width: 50%;
        overflow: hidden;
        position: absolute;
        margin-top: -5%;
        /* border: 1px solid black */
    }

    .blur-image-service1 img {
        margin-left: -50%;
        margin-top: 60px;
        height: 600px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-service-bottom {
        width: 60%;
        overflow: hidden;
        text-align: end !important;
        margin-left: 39%;
        /* border: 1px solid black; */
        margin-top: -50%;
        position: absolute;

    }

    .blur-image-service-bottom img {
        margin-top: 50px;
        height: 600px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .services-content {
        width: 90%;
        justify-content: center !important;
        text-align: center !important;
        /* border: 1px solid black; */
        position: relative;
        margin-top: 60px;
        margin-left: -4%;
        /* background-color: #0090E0; */
        display: flex;
    }

    .services-content1 {
        width: 90%;
        justify-content: center !important;
        text-align: center !important;
        /* border: 1px solid black; */
        position: relative;
        margin-top: 40px;
        margin-left: 2.2%;
        display: flex;
    }

    /* .service-content-div {
    width: 35%;
    height: 550px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 1px 1px 5px gray;
    overflow: hidden;
    border: 3px solid #0090E0;
    
} */
    .service-content-div {
        width: 100%;
        height: 350px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px rgb(245, 245, 245);
        /* overflow: hidden; */
        position: relative;
        color: rgb(88, 199, 250, 0%);
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* object-fit: cover !important; */
        /* background-repeat: no-repeat; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .card1 {
        width: 98.4%;
        height: 354px !important;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.8%;
        top: 0.9%;
    }

    .card {
        width: 98.7%;
        height: 354px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.8%;
        top: 1%;
    }

    .card-style {
        width: 35%;
        height: 360px;
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 200px;
        overflow: hidden;
    }

    .card-style3 {
        width: 35%;
        height: 360px;
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 50px;
        overflow: hidden;
    }

    .card-style1 {
        width: 35%;
        height: 360px;
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 50px;
        overflow: hidden;
    }


    @keyframes colorChange {
        0% {
            border-color: #0090E0;
        }

        25% {
            border-color: #fbf6ff;
        }

        50% {
            border-color: #0090E0;
        }

        75% {
            border-color: #6271ff;
        }

        100% {
            border-color: #0090E0;
        }
    }

    .service-content-div1 {
        width: 100%;
        height: 350px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 1px 1px 5px rgb(238, 238, 238);
        /* overflow: hidden; */
        /* background-color: white; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }



    .service-content-divbottom {
        width: 100%;
        height: 350px;
        /* border: 1px solid black; */
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 1px 1px 5px rgb(241, 241, 241);
        /* overflow: hidden; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px); */
    }


    .service-content-div1bottom {
        width: 100%;
        height: 350px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px rgb(240, 240, 240);
        /* overflow: hidden; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px); */
    }

    .main-content {
        width: 80%;
        /* height:200px; */
        padding-top: 30px;
        padding-left: 30px;
        text-align: start;
        /* border: 1px solid black; */
    }

    .main-chooseus-container {
        /* padding-left: 120px; */
        margin-top: 15%;
    }

    /* .counter-wrapper {
        width: 67%;
        border: 3px solid transparent;
        margin-left: 15%;
        border-radius: 25px;
        display: flex;
        padding: 30px;
        margin-top: 5%;
        justify-content: space-between;
        box-shadow: 2px 2px 4px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    } */


    .counter-wrapper {
        width: 93% !important;
        border: 3px solid transparent;
        margin-left: 0%;
        border-radius: 30px;
        display: flex;
        padding: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgb(252, 252, 252);
        /* animation: colorChange 5s linear infinite; */
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */


    .card-counter {
        width: 99.3%;
        height: 256px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-radius: 30px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        left: 0.4%;
        top: 1.3%;
    }

    .card-style-counter {
        width: 85% !important;
        height: 263px;
        background-color: #ffffff;
        position: relative;
        margin-top: 5%;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 10% !important;
        overflow: hidden;
    }

    .card-style-counter::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        transform: translate(-50%, -50%) rotate(-190deg);
        width: 160%;
        height: 100%;
        background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
        transition: transform 3s;

    }

    .card-style-counter::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }



    .counter-div {
        width: 25%;
        /* border: 1px solid black; */
        height: 100px;
        margin-top: 0px;
        padding: 5px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        /* margin-right: 10px; */
        margin-left: 10px;
    }

    .number .counter {
        font-weight: 700;
        font-size: 45px !important;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 14px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-chooseus {
        width: 60%;
        overflow: hidden;
        position: absolute;
        margin-top: 4%;
        /* border: 1px solid black */
    }

    .blur-image-chooseus img {
        margin-left: -40%;
        height: 500px;
        margin-top: 50px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .chooseus-row {
        width: 85%;
        /* border: 1px solid black; */
        margin-top: 1%;
        margin-left: 9%;
        display: flex;
    }

    .chooseus-rowsecond {
        width: 85%;
        /* border: 1px solid black; */
        /* margin-top: 6%; */
        margin-left: 9%;
        display: flex;
    }

    .chooseus-col {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-colsecond {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-colrecord {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-col1 {
        width: 50%;
        /* border: 1px solid black; */
        /* padding: 50px; */
        margin-top: 5px;
    }

    .chooseus-colrecord img {
        width: 100%;
        margin-top: 0%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -5%;
    }

    .chooseus-col img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -3%;
    }

    .chooseus-colsecond img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -11%;
    }

    .partner-content {
        width: 60%;
        margin-left: 25%;
        margin-top: -21px;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 16px;
        line-height: 28px;
        /* word-spacing: 1px; */
        text-align: start;
    }

    /* .main-jobopening-container {
        margin-top: 3%;
    } */

    .job-row {
        width: 100wh;
        height: 400px !important;
        padding: 20px;
       
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
    height: 650px;
    border: 1px solid #faf9f9;
    background-color: #5fc7ff;
    box-shadow: 1px 1px 1px gray;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
} */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .jobopeningdiv {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
} */
    .jobopening-col {
        width: 32%;
        height: 320px;
        margin-left: 5%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
       
    }

    /* .jobopening-col {
        padding: 0px;
        margin-top: 80px !important;
        width: 50% !important;
        height: 328px !important;
        margin-left: 20px !important;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 60px;
        border-bottom-left-radius: 60px;
        margin-left: 25px !important;
      
    } */

    /* .jobopening-col1 {
        width: 36%;
        height: 280px;
        margin-left: 2%;
        margin-top: 15px;
        margin-bottom: 25px;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
       
    }

    .job-opening-row {
        margin-top: 15px;
    }

    .job-opening-row1 {
        margin-top: 15px;
        margin-left: 10%;
    }

    .jobopening-col img {
        padding: 20px;
        height: 70px;
    }

    .jobopening-col1 img {
        padding: 20px;
        height: 70px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -20px !important;
    }

    .jobopening-col1 h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -15px !important;
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 12px;
    }

    .jobopening-col1 h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 12px;
    }

    .submit-btn {
        width: 50%;
        justify-content: center !important;
        
    }

    .submit {
        width: 90%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        border-radius: 8px;
        display: block;
        margin-top: 20px;
        margin-left: 55%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    } */

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }



    /* ===========slider=============== */
    .main-ourgallery-container {
        /* margin-left: -120px; */
        margin-top: 7%;
    }

    .wrapper {
        padding: 70px 0;
        margin-top: 2%;
        width: 100wh;
        /* border: 1px solid black; */
    }



    /* Default slide */
    .center-slider .slick-slide {
        /* background-color: #b32532; */
        color: #FFF;
        height: 400px;
        border-radius: 10px;
        /* margin: 0 15px 0 0; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transform: scale(0.8);
        transition: all 0.4s ease-in-out;
    }

    .center-slider .slick-slide,
    .center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {
        transform: scale(0.8, 0.8);
        transition: all 0.4s ease-in-out;
    }

    /* Active center slide (You can change anything here for cenetr slide)*/
    .center-slider .slick-center,
    .center-slider .slick-slide[aria-hidden="true"]:not([tabindex="-1"])+.slick-cloned[aria-hidden="true"] {
        transform: scale(1.1);
        /* background-color: #000000; */
        border-radius: 10px !important;
    }

    .center-slider .slick-current.slick-active {
        transform: scale(1.1);
        /* background-color: #000000; */
        border-radius: 10px !important;
    }

    .slick-next,
    .slick-prev {
        z-index: 5;
    }

    .slick-next {
        right: 0px !important;
    }

    /* 
    .slick-prev {
        left: 15px;
    } */
    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 40px !important;
        line-height: 1;
        opacity: .75;
        color: #0090e0 !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .slick-next:before,
    .slick-prev:before {
        color: #000;
        font-size: 26px;
    }

    /* .custom-arrow {
    position: absolute;
    top: 35%;
    transform: translateY(50%);
    font-size: 24px;
    cursor: pointer;
}

.custom-prev {
    left: 10px;
    padding: 20px;
}

.custom-next {
    right: 10px;
    padding: 20px;
} */

    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        color: black;
        outline: none;
        background: transparent;
    }

    /* .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 46px !important;
    height: 40px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black !important;
    border: none;
    outline: none;
} */
    .slick-prev:before,
    .slick-next:before {
        /* color: #000 !important; */
        color: #0090e0 !important;
    }

    /* .slick-prev {
        left: 27% !important;
    } */
    .slick-prev,
    .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 30% !important;
        display: block;
        width: 35px !important;
        height: 30px !important;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: black !important;
        border: none;
        outline: none;
        /* background: black; */
    }

    /* .slick-prev {
        left: -1px !important;
    } */

    .festivetext {
        margin-top: -14%;
    }

    /* .slick-prev {
        left: -1px !important;
    } */

    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 40px !important;
        line-height: 1;
        opacity: .75;
        /* color: black !important; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .center-mode-slider {
        .slick-center {
            border-radius: 20px;
            /* Add any other styles you want for the center slide */
        }
    }

    .main-footer-container {
        /* margin-left: -120px; */
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 500px;
        background-image: url("../../assets//images/footerpage-images/bgimg.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 20px;
    }

    .footer {
        width: 100%;
        height: 500px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer span {
        /* border: 1px solid black; */
        color: #e0e0e0;
        font-size: 22px;
        font-weight: 300;
        margin-top: 100px !important;
    }

    .footer-div1 {
        width: 25%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 0px;
        padding-top: 40px;
        margin-left: 30px;
    }

    .footer-div5 {
        width: 25%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 0px;
        padding-top: 40px;
        margin-left: 0px;
    }

    .footer-div5 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: 50px !important;
        text-align: center;
    }

    .footer-div2 {
        width: 19%;
        padding: 10px;
        /* border: 1px solid black; */
        padding-top: 70px;
        /* margin-left: 170px; */
    }

    .socialmedia {
        width: 60%;
        /* border: 1px solid black; */
        margin-top: 40px;
        margin-left: 25px;

    }

    .footer-div4 {
        width: 19%;
        /* border: 1px solid black; */
        padding-top: 110px;
    }

    .footerlogoimg {
        width: 90%;
    }

    .address {
        width: 100%;
        color: white;
        font-weight: 300;
        font-size: 17px;
        word-spacing: 5px;
        margin-top: 50px;
    }

    .address span {
        font-size: 17px;
        margin-top: 40px;
    }

    .footer-div2 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        /* margin-top: -10px; */
    }

    .footer-div4 h2 {
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: -22px;
    }

    .footer-div2 h3 {
        text-align: start;
        font-size: 16px;
        font-weight: 400;
        color: white;
        margin-left: 20px;
        cursor: pointer;
        margin-top: -7px;
    }

    .footer-div1 h2 {
        color: #ffff;
        font-size: 18px;
        font-weight: 500;
    }

    .footer-div4 h3 {
        text-align: start;
        font-size: 18px;
        font-weight: 400;
        color: white;
        margin-left: 32px;
        cursor: pointer;
        margin-top: -7px;
        /* margin-top: 50px; */
    }

    .socialicon {
        height: 30px;
        cursor: pointer
    }

    .home-page-container-mobile {
        display: none;

    }

    .main-about-container-mobile {
        display: none;
    }

    .main-services-container-mobile {
        display: none;
    }

    .home-page-container-mob {
        display: none;
    }

    .main-chooseus-container-mobile {
        display: none;
    }

    .main-jobopening-container-mobile {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: none;
    }

    .main-footer-container-mob {
        display: none;
    }


}


@media (min-width: 1201px) and (max-width: 1500px) {
    .home-page-container {
        /* width: 100%; */
        /* border: 1px solid black; */
        /* padding-top: 150px; */
    }

    .main-home-container {
        padding-left: 90px;
    }

    .home-text {
        text-align: start;
        margin-top: 70px;
        font-size: 44px !important;
        font-weight: 700;
        line-height: 40.6px;
    }

    .home-text p {
        font-size: 25px;
        color: #5D5D5D;
        line-height: 15px;
        font-weight: 400;
    }

    .g-ads-img {
        text-align: start;
        /* margin-top: 20px;  */
    }

    .g-ads-img img {
        margin-top: 3%;
        height: 80px;
    }
    .homebganimation {
        margin-left: 7%;
        /* margin-left: -200px; */
        margin-top: 6%;
        /* margin-top: 20%; */
        position: absolute;
        z-index: 1
    }
    .animationgif{
        height: 290px
    }
    .slider {
        width: 85%;
        height: 200px;
        padding-top: 20px !important;
        padding: 10px;
        /* border: 1px solid black; */
        margin-left: 4%;
        /* margin-top: -30%; */
        border-radius: 20px;
        background-color: #ffff;
        box-shadow: 0px 0.2px 30px rgb(233 233 233 / 50%);
        margin-right: -1%;
        z-index: 0;
    }

    .slider span {
        font-size: 22px;
        font-weight: 600;
        word-spacing: 4px;
        margin-top: 10px !important;
    }

    .home {
        width: 100%;
        display: flex;
        height: 530px;
        /* border: 1px solid black; */
    }

    .home-content {
        width: 55%;
        /* border: 1px solid black; */
        height: fit-content;
    }

    .slider-container {
        width: 99%;
        height: 70%;
        /* border: 1px solid black; */
        margin-left: 0.5%;
        margin-top: 20px;
    }

    .blur-image {
        width: 55%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image img {
        margin-left: 10%;
        height: 60%;
        margin-top: 50px;
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-img-img {
        /* margin-left: 10%; */
        margin-left: 40%;
        height: 60%;
        margin-top: 80px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            opacity: 100;
            transform: scale(1, 1);
        }

        50% {
            opacity: 100;
            transform: scale(1.5, 1.5);
        }

        100% {
            opacity: 100;
            transform: scale(1, 1);
        }
    }

    /* @keyframes orb {
       
        0% {
            opacity: 100;
            transform: translateX(-100px);
        }
    
        50% {
            opacity: 100;
            transform: translateY(-100px);
        }
        75% {
            opacity: 100;
            transform: translateX(-100px);
        }
       
    
        100% {
            opacity: 100;
            transform: translateY(-250);
        }
    } */

    .main-about-container {
        padding-left: 120px;
        margin-top: 3%;
    }

    .about-content {
        width: 92%;
        /* border: 1px solid black; */
        margin-top: 2%;
        margin-left: 5%;
    }

    .about-image {
        /* height: 600px !important; */
        width: 50%;
        /* border: 1px solid black; */
        border-top-right-radius: 75px;
        border-bottom-left-radius: 75px ;
    }
    .about-image img{
        /* height: 600px !important; */
        width: 50%;
        /* border: 1px solid black; */
        border-top-right-radius: 75px !important;
        border-bottom-left-radius: 75px !important;
    }

    .about-info {
        width: 40%;
        margin-left: 2%;
        /* border: 1px solid black; */
        margin-top: 3%;
    }

    .about-info h2 {
        margin-top: 5px;
        font-size: 18px;
        font-weight: 400;
        text-align: justify;
        /* line-height: 1px; */
        color: #757575;
    }

    .about-btn {
        /* border: 1px solid black; */
        width: 30%;
        height: 40px !important;
        margin-top: 20px;
        /* margin-left: 1%; */
    }


    .know-btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 100%;
        height: 45px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-top: 0px;
        /* margin-left: 8px; */
        font-size: 17px;
    }

    .know-btn :hover {
        color: white;
    }


    .main-services-container {
        /* padding-left: 120px; */
        margin-top: 3%;
    }

    .blur-image-service1 {
        width: 60%;
        overflow: hidden;
        position: absolute;
        margin-top: -10%;
        /* border: 1px solid black */
    }

    .blur-image-service1 img {
        margin-left: -30%;
        margin-top: 70px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-service-bottom {
        width: 40vw;
        overflow: hidden;
        text-align: end !important;
        margin-left: 59%;
        /* border: 1px solid black; */
        margin-top: -35%;
        position: absolute;

    }

    .blur-image-service-bottom img {
        margin-top: 50px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .services-content {
        width: 85%;
        justify-content: center !important;
        text-align: center !important;
        /* border: 1px solid black; */
        position: relative;
        margin-top: 80px;
        margin-left: 0%;
        /* background-color: #0090E0; */
        display: flex;
    }

    .card1 {
        width: 98.6%;
        height: 391px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.7%;
        top: 0.6%;
    }

    .card {
        width: 98.8%;
        height: 390px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.6%;
        top: 0.8%;
    }

    .services-content1 {
        width: 85%;
        justify-content: center !important;
        text-align: center !important;
        /* border: 1px solid black; */
        position: relative;
        margin-top: 40px;
        margin-left: 5%;
        display: flex;
    }

    /* .service-content-div {
        width: 35%;
        height: 550px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        overflow: hidden;
        border: 3px solid #0090E0;
        
    } */
    .service-content-div {
        width: 100%;
        height: 400px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        color: rgb(88, 199, 250, 0%);
        z-index: 1;
        border: 3px solid transparent;
        /* animation: colorChange 5s linear infinite; */
        /* object-fit: cover !important; */
        /* background-repeat: no-repeat; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 40px !important;
        line-height: 1;
        opacity: .75;
        color: #0090e0 !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }


    @keyframes colorChange {
        0% {
            border-color: #0090E0;
        }

        25% {
            border-color: #fbf6ff;
        }

        50% {
            border-color: #0090E0;
        }

        75% {
            border-color: #6271ff;
        }

        100% {
            border-color: #0090E0;
        }
    }

    .service-content-div1 {
        width: 100%;
        height: 400px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        /* background-color: white; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }



    .service-content-divbottom {
        width: 100%;
        height: 400px;
        /* border: 1px solid black; */
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px); */
    }


    .service-content-div1bottom {
        width: 100%;
        height: 400px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px); */
    }

    .main-content {
        width: 80%;
        /* height:200px; */
        padding-top: 30px;
        padding-left: 50px;
        text-align: start;
        /* border: 1px solid black; */
    }

    .main-chooseus-container {
        /* padding-left: 120px; */
        margin-top: 10%;
    }

    /* .counter-wrapper {
        width: 60%;
        border: 3px solid transparent;
        margin-left: 17%;
        border-radius: 30px;
        display: flex;
        padding: 50px;
        margin-top: 5%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
      
        backdrop-filter: blur(10px);
    } */

    .counter-wrapper {
        width: 93% !important;
        border: 3px solid transparent;
        margin-left: 0%;
        border-radius: 30px;
        display: flex;
        padding: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgb(252, 252, 252);
        /* animation: colorChange 5s linear infinite; */
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */


    .card-counter {
        width: 99.3%;
        height: 256px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-radius: 30px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        left: 0.3%;
        top: 1.3%;
    }

    .card-style-counter {
        width: 85% !important;
        height: 263px;
        background-color: #ffffff;
        position: relative;
        margin-top: 5%;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 10% !important;
        overflow: hidden;
    }

    .card-style-counter::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        transform: translate(-50%, -50%) rotate(-190deg);
        width: 160%;
        height: 100%;
        background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
        transition: transform 3s;

    }

    .card-style-counter::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 75px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 20px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-chooseus {
        /* width: 30%; */
        overflow: hidden;
        position: absolute;
        margin-top: 4%;
        /* border: 1px solid black */
    }

    .blur-image-chooseus img {
        margin-left: -40%;
        margin-top: 0px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .chooseus-row {
        width: 67%;
        /* border: 1px solid black; */
        margin-top: 1%;
        margin-left: 17%;
        display: flex;
    }

    .chooseus-rowsecond {
        width: 67%;
        /* border: 1px solid black; */
        /* margin-top: 6%; */
        margin-left: 17%;
        display: flex;
    }

    .chooseus-col {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-colsecond {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-colrecord {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-col1 {
        width: 50%;
        /* border: 1px solid black; */
        /* padding: 50px; */
        margin-top: 0px !important;
    }

    .chooseus-colrecord img {
        width: 100%;
        margin-top: 0%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -5%;
    }

    .chooseus-col img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -3%;
    }

    .namenumber {
        font-size: 32px !important;
        color: skyblue;
        font-weight: bold;
        margin-top: 10px
    }

    .nameheading {
        margin-left: 20px !important;
        font-size: 30px !important;
        margin-top: 10px
    }

    .chooseus-colsecond img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -11%;
    }

    .partner-content {
        width: 75% !important;
        margin-left: 20%;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 20px !important;
        line-height: 32px;
        word-spacing: 0px !important;
        margin-top: 10px;
        text-align: start;
    }

    .main-jobopening-container {
        /* margin-left: -120px; */
        margin-top: 3%;
    }

    .job-row {
        width: 100wh;
        height: 500px;
        padding: 10px;
        padding-top: 20px;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
        height: 650px;
        border: 1px solid #faf9f9;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    } */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .jobopeningdiv {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */
    .jobopening-col {
        width: 20%;
        height: 360px;
        margin-left: 2%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        margin-left: 50px !important;
        margin-top: 20px;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 25px;
    }

    .jobopening-col img {
        padding: 40px;
        height: 80px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 18px !important;
        font-weight: 500;
        margin-top: -15px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 12px;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* padding-bottom: 50px; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 8px;
        display: block;
        margin-top: 15px !important;
        margin-left: 45%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }



    /* ===========slider=============== */
    .main-ourgallery-container {
        /* margin-left: -120px; */
        margin-top: 7%;
    }

    .wrapper {
        padding: 70px 0;
        margin-top: 2%;
        width: 100wh;
        /* border: 1px solid black; */
    }

    /* Default slide */
    .center-slider .slick-slide {
        /* background-color: #b32532; */
        color: #FFF;
        height: 400px;
        border-radius: 10px;
        /* margin: 0 15px 0 0; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transform: scale(0.8);
        transition: all 0.4s ease-in-out;
    }

    .center-slider .slick-slide,
    .center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {
        transform: scale(0.8, 0.8);
        transition: all 0.4s ease-in-out;
    }

    /* Active center slide (You can change anything here for cenetr slide)*/
    .center-slider .slick-center,
    .center-slider .slick-slide[aria-hidden="true"]:not([tabindex="-1"])+.slick-cloned[aria-hidden="true"] {
        transform: scale(1.1);
        /* background-color: #000000; */
        border-radius: 10px !important;
    }

    .center-slider .slick-current.slick-active {
        transform: scale(1.1);
        /* background-color: #000000; */
        border-radius: 10px !important;
    }

    .slick-next,
    .slick-prev {
        z-index: 5;
    }

    .slick-next {
        right: 0px !important;
    }

    /* .slick-prev {
        left: 15px;
    } */

    .slick-next:before,
    .slick-prev:before {
        color: #000;
        font-size: 26px;
    }

    /* .custom-arrow {
        position: absolute;
        top: 35%;
        transform: translateY(50%);
        font-size: 24px;
        cursor: pointer;
    }
    
    .custom-prev {
        left: 10px;
        padding: 20px;
    }
    
    .custom-next {
        right: 10px;
        padding: 20px;
    } */

    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        color: black;
        outline: none;
        background: transparent;
    }

    /* .slick-prev, .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        width: 46px !important;
        height: 40px !important;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: black !important;
        border: none;
        outline: none;
    } */
    .slick-prev:before,
    .slick-next:before {
        /* color: #000 !important; */
        color: #0090e0 !important;
    }

    .slick-prev,
    .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 35% !important;
        display: block;
        width: 45px !important;
        height: 50px !important;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: black !important;
        border: none;
        outline: none;
        /* background: black; */
    }

    /* .slick-prev {
        left: -1px !important;
    } */

    /* .slick-prev {
        left: -1px !important;
    } */

    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 35px !important;
        line-height: 1;
        opacity: .75;
        /* color: black !important; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .center-mode-slider {
        .slick-center {
            border-radius: 20px;
            /* Add any other styles you want for the center slide */
        }
    }

    .main-footer-container {
        /* margin-left: -120px; */
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 500px;
        background-image: url("../../assets//images/footerpage-images/bgimg.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 30px;
    }

    /* .slick-prev {
        left: 27% !important;
    } */

    .footer {
        width: 100%;
        height: 500px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer span {
        /* border: 1px solid black; */
        color: #e0e0e0;
        font-size: 22px;
        font-weight: 300;
        margin-top: 100px !important;
    }

    .footer-div1 {
        width: 20%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 60px;
        padding-top: 60px;
        margin-left: 20px;
    }

    .footer-div2 {
        width: 20%;
        /* border: 1px solid black; */
        padding-top: 80px;
        /* margin-left: 170px; */
    }

    .footer-div3 {
        width: 18%;
        /* border: 1px solid black; */
    }

    .footer-div4 {
        width: 18%;
        /* border: 1px solid black; */
        padding-top: 110px;
    }

    .footerlogoimg {
        width: 55%;
    }

    .address {
        width: 100%;
        color: white;
        font-weight: 300;
        font-size: 20px;
        word-spacing: 5px;
        margin-top: 70px;
    }

    .footer-div2 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
        /* margin-top: -10px; */
    }

    .footer-div4 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
    }

    .footer-div2 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 55px;
        cursor: pointer;
        margin-top: -7px;
    }

    .footer-div1 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
    }

    .footer-div4 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 60px;
        cursor: pointer;
        margin-top: -7px;
        /* margin-top: 50px; */
    }

    .festive {
        margin-top: -100px;
    }

    .copyright {
        margin-top: 30px !important;
    }

    .socialicon {
        margin-top: 20px;
        height: 40px;
        cursor: pointer
    }

    .home-page-container-mobile {
        display: none;

    }

    .main-about-container-mobile {
        display: none;
    }

    .main-services-container-mobile {
        display: none;
    }

    .home-page-container-mob {
        display: none;
    }

    .main-chooseus-container-mobile {
        display: none;
    }

    .main-jobopening-container-mobile {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: none;
    }

    .main-footer-container-mob {
        display: none;
    }

    .desk-container {
        /* border: 1px solid red */
    }

    .tab-conatiner {
        display: none;
    }


}

@media (min-width: 1501px) and (max-width: 1700px) {
    .home-page-container {
        /* width: 100%; */
        /* border: 1px solid black; */
        /* padding-top: 150px; */
    }

    .main-home-container {
        padding-left: 90px;
    }

    .home-text {
        text-align: start;
        margin-top: 70px;
        font-size: 44px !important;
        font-weight: 700;
        line-height: 40.6px;
    }

    .home-text p {
        font-size: 25px;
        color: #5D5D5D;
        line-height: 15px;
        font-weight: 400;
    }

    .g-ads-img {
        text-align: start;
        /* margin-top: 20px;  */
    }

    .g-ads-img img {
        margin-top: 3%;
        height: 80px;
    }
    .homebganimation {
        /* margin-left: -250px; */
        margin-left: 7%;
        margin-top: 7%;
        position: absolute;
        z-index: 1
    }
    .animationgif{
        height: 300px
    }

    .slider {
        width: 85%;
        height: 200px;
        padding-top: 20px !important;
        padding: 10px;
        /* border: 1px solid black; */
        margin-left: 4%;
        /* margin-top: -25%; */
        border-radius: 20px;
        background-color: #ffff;
        box-shadow: 0px 0.2px 30px rgb(233 233 233 / 50%);
        margin-right: -1%;
        z-index: 0;
    }

    .slider span {
        font-size: 22px;
        font-weight: 600;
        word-spacing: 4px;
        margin-top: 10px !important;
    }

    .home {
        width: 100%;
        display: flex;
        height: 550px;
        /* border: 1px solid black; */
    }

    .home-content {
        width: 55%;
        /* border: 1px solid black; */
        height: fit-content;
    }

    .slider-container {
        width: 99%;
        height: 70%;
        /* border: 1px solid black; */
        margin-left: 0.5%;
        margin-top: 20px;
    }

    .blur-image {
        width: 55%;
        /* height: fit-content; */
        /* padding-top: 150px; */
        overflow: hidden;
        /* border: 1px solid black */
    }

    .blur-image img {
        margin-left: 10%;
        height: 60%;
        margin-top: 50px;
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-img-img {
          /* margin-left: 10%; */
          margin-left: 40%;
          height: 60%;
          margin-top: 100px;
          /* border: 1px solid black; */
          animation: zoom-in-zoom-out 3s ease infinite;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            opacity: 100;
            transform: scale(1, 1);
        }

        50% {
            opacity: 100;
            transform: scale(1.5, 1.5);
        }

        100% {
            opacity: 100;
            transform: scale(1, 1);
        }
    }

    /* @keyframes orb {
       
        0% {
            opacity: 100;
            transform: translateX(-100px);
        }
    
        50% {
            opacity: 100;
            transform: translateY(-100px);
        }
        75% {
            opacity: 100;
            transform: translateX(-100px);
        }
       
    
        100% {
            opacity: 100;
            transform: translateY(-250);
        }
    } */

    .main-about-container {
        padding-left: 120px;
        margin-top: 3%;
    }

    .about-content {
        width: 92%;
        /* border: 1px solid black; */
        margin-top: 2%;
        margin-left: 5%;
    }

    .about-image {
        /* height: 600px !important; */
        width: 50%;
        /* border: 1px solid black; */
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    .about-info {
        width: 40%;
        margin-left: 2%;
        /* border: 1px solid black; */
        margin-top: 3%;
    }

    .about-info h2 {
        margin-top: 5px;
        font-size: 18px;
        font-weight: 400;
        text-align: justify;
        /* line-height: 1px; */
        color: #757575;
    }

    .about-btn {
        /* border: 1px solid black; */
        width: 30%;
        height: 40px !important;
        margin-top: 20px;
        /* margin-left: 1%; */
    }


    .know-btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 100%;
        height: 45px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-top: 0px;
        /* margin-left: 8px; */
        font-size: 17px;
    }

    .know-btn :hover {
        color: white;
    }


    .main-services-container {
        /* padding-left: 120px; */
        margin-top: 3%;
    }

    .blur-image-service1 {
        width: 60%;
        overflow: hidden;
        position: absolute;
        margin-top: -10%;
        /* border: 1px solid black */
    }

    .blur-image-service1 img {
        margin-left: -30%;
        margin-top: 70px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .blur-image-service-bottom {
        width: 40vw;
        overflow: hidden;
        text-align: end !important;
        margin-left: 59%;
        /* border: 1px solid black; */
        margin-top: -35%;
        position: absolute;

    }

    .blur-image-service-bottom img {
        margin-top: 50px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .services-content {
        width: 85%;
        justify-content: center !important;
        text-align: center !important;
        /* border: 1px solid black; */
        position: relative;
        margin-top: 80px;
        margin-left: 3.5%;
        /* background-color: #0090E0; */
        display: flex;
    }

    .services-content1 {
        width: 85%;
        justify-content: center !important;
        text-align: center !important;
        /* border: 1px solid black; */
        position: relative;
        margin-top: 40px;
        margin-left: 8%;
        display: flex;
    }

    /* .service-content-div {
        width: 35%;
        height: 550px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        overflow: hidden;
        border: 3px solid #0090E0;
        
    } */
    .service-content-div {
        width: 100%;
        height: 400px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        color: rgb(88, 199, 250, 0%);
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* object-fit: cover !important; */
        /* background-repeat: no-repeat; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    /* .slick-prev {
        left: 27% !important;
    } */

    @keyframes colorChange {
        0% {
            border-color: #0090E0;
        }

        25% {
            border-color: #fbf6ff;
        }

        50% {
            border-color: #0090E0;
        }

        75% {
            border-color: #6271ff;
        }

        100% {
            border-color: #0090E0;
        }
    }

    .service-content-div1 {
        width: 100%;
        height: 400px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        /* background-color: white; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .card-style1 {
        width: 35%;
        height: 400px;
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 50px;
        overflow: hidden;
    }

    .card1 {
        width: 98.9%;
        height: 394px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.6%;
        top: 0.8%;
    }

    .card {
        width: 98.9%;
        height: 391px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 0px;
        overflow: hidden;
        left: 0.5%;
        top: 0.5%;
    }

    .service-content-divbottom {
        width: 100%;
        height: 400px;
        /* border: 1px solid black; */
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px); */
    }


    .service-content-div1bottom {
        width: 100%;
        height: 400px;
        /* border: 1px solid black; */
        margin-left: 0px;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        box-shadow: 1px 1px 5px gray;
        /* overflow: hidden; */
        position: relative;
        border: 3px solid transparent;
        z-index: 1;
        /* animation: colorChange 5s linear infinite; */
        /* background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px); */
    }

    .main-content {
        width: 80%;
        /* height:200px; */
        padding-top: 30px;
        padding-left: 50px;
        text-align: start;
        /* border: 1px solid black; */
    }

    .main-chooseus-container {
        /* padding-left: 120px; */
        margin-top: 10%;
    }

    .counter-wrapper {
        width: 93% !important;
        border: 3px solid transparent;
        margin-left: 0%;
        border-radius: 30px;
        display: flex;
        padding: 0px;
        margin-top: 0%;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgb(252, 252, 252);
        /* animation: colorChange 5s linear infinite; */
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */


    .card-counter {
        width: 99.3%;
        height: 256px;
        /* margin-left: -10px; */
        background-color: #ffffff;
        position: relative;
        border-radius: 30px;
        /* box-shadow: 0px 0px 5px 2px #a0a0a0; */
        cursor: pointer;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        left: 0.3%;
        top: 1.3%;
    }

    .card-style-counter {
        width: 70% !important;
        height: 263px;
        background-color: #ffffff;
        position: relative;
        margin-top: 5%;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 2px #f7f6f6;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-left: 15% !important;
        overflow: hidden;
    }

    .card-style-counter::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        transform: translate(-50%, -50%) rotate(-190deg);
        width: 160%;
        height: 100%;
        background-image: conic-gradient(#0090e0 0deg, #88d5ff 90deg, transparent 130deg, transparent 180deg, #0090e0 180deg, #6eccff 270deg, transparent 310deg, transparent 360deg);
        transition: transform 3s;

    }

    .card-style-counter::before {
        cursor: pointer;
        transform: translate(-50%, -50%) rotate(-100deg);
        animation: rotateBorder 5s linear infinite;
    }

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 75px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 20px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-chooseus {
        /* width: 30%; */
        overflow: hidden;
        position: absolute;
        margin-top: 4%;
        /* border: 1px solid black */
    }

    .blur-image-chooseus img {
        margin-left: -40%;
        margin-top: 0px;
        /* border: 1px solid black; */
        animation: zoom-in-zoom-out 3s ease infinite;
    }

    .chooseus-row {
        width: 67%;
        /* border: 1px solid black; */
        margin-top: 1%;
        margin-left: 17%;
        display: flex;
    }

    .chooseus-rowsecond {
        width: 67%;
        /* border: 1px solid black; */
        /* margin-top: 6%; */
        margin-left: 17%;
        display: flex;
    }

    .chooseus-col {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-colsecond {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-colrecord {
        width: 50%;
        /* border: 1px solid black; */
    }

    .chooseus-col1 {
        width: 50%;
        /* border: 1px solid black; */
        /* padding: 50px; */
        margin-top: 0px !important;
    }

    .chooseus-colrecord img {
        width: 100%;
        margin-top: 0%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -5%;
    }

    .chooseus-col img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -3%;
    }

    .namenumber {
        font-size: 32px !important;
        color: skyblue;
        font-weight: bold;
        margin-top: 10px
    }

    .nameheading {
        margin-left: 20px !important;
        font-size: 30px !important;
        margin-top: 10px
    }

    .chooseus-colsecond img {
        width: 100%;
        margin-top: -2%;
        margin-bottom: -4%;
        margin-left: -4%;
        margin-right: -11%;
    }

    .partner-content {
        width: 75%;
        margin-left: 20%;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 20px;
        line-height: 32px;
        /* word-spacing: 1px; */
        margin-top: 10px;
        text-align: start;
    }

    .main-jobopening-container {
        /* margin-left: -120px; */
        margin-top: 3%;
    }

    .job-row {
        width: 100wh;
        height: 500px;
        padding: 10px;
        padding-top: 20px;
        /* padding-bottom: 50px; */
        /* background-color: #0090E0; */
        background-image: linear-gradient(to right, #0090E0, #6DCBFF);
    }

    /* .jobopeningdiv{
        height: 650px;
        border: 1px solid #faf9f9;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    } */
    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .jobopeningdiv {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */
    .jobopening-col {
        width: 20%;
        height: 380px !important;
        margin-left: 0%;
        border: 1px solid #ffffff;
        background-color: #5fc7ff;
        box-shadow: 1px 1px 1px gray;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        margin-left: 0px !important;
        margin-top: 20px;
        padding-bottom: 10px !important;
        /* border: 3px solid transparent; */
        /* animation: colorChange 5s linear infinite; */
    }

    .job-opening-row {
        /* justify-content: space-between; */
        margin-top: 25px;
    }

    .jobopening-col img {
        padding: 40px;
        height: 80px;
    }

    .jobopening-col h2 {
        color: white;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
        /* font-weight: 900; */
    }

    .jobopening-col h3 {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 12px;
    }

    .submit-btn {
        /* border: 1px solid black; */
        width: 60%;
        justify-content: center !important;
        /* height: 80px !important; */
        /* margin-bottom: 50px !important; */
    }

    .submit {
        /* background-image: linear-gradient(to right, #0090E0 20%, #6DCBFF 51%, #1FA2FF 100%); */
        width: 80%;
        height: 40px;
        background-color: #0090E0;
        text-align: center !important;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        /* box-shadow: 1px 1px 5px #aaaaaa; */
        border-radius: 8px;
        display: block;
        margin-top: 30px;
        margin-left: 45%;
        font-size: 16px;
    }

    .submit:hover {
        color: white !important;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }



    /* ===========slider=============== */
    .main-ourgallery-container {
        /* margin-left: -120px; */
        margin-top: 7%;
    }

    .wrapper {
        padding: 70px 0;
        margin-top: 2%;
        width: 100wh;
        /* border: 1px solid black; */
    }

    /* Default slide */
    .center-slider .slick-slide {
        /* background-color: #b32532; */
        color: #FFF;
        height: 400px;
        border-radius: 10px;
        /* margin: 0 15px 0 0; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transform: scale(0.8);
        transition: all 0.4s ease-in-out;
    }

    .center-slider .slick-slide,
    .center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {
        transform: scale(0.8, 0.8);
        transition: all 0.4s ease-in-out;
    }

    /* Active center slide (You can change anything here for cenetr slide)*/
    .center-slider .slick-center,
    .center-slider .slick-slide[aria-hidden="true"]:not([tabindex="-1"])+.slick-cloned[aria-hidden="true"] {
        transform: scale(1.1);
        /* background-color: #000000; */
        border-radius: 10px !important;
    }

    .center-slider .slick-current.slick-active {
        transform: scale(1.1);
        /* background-color: #000000; */
        border-radius: 10px !important;
    }

    .slick-next,
    .slick-prev {
        z-index: 5;
    }

    /* 
    .slick-next {
        right: 0px !important;
    } */

    /* .slick-prev {
        left: 15px;
    } */

    .slick-next:before,
    .slick-prev:before {
        color: #000;
        font-size: 26px;
    }

    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 40px !important;
        line-height: 1;
        opacity: .75;
        color: #0090e0 !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /* .custom-arrow {
        position: absolute;
        top: 35%;
        transform: translateY(50%);
        font-size: 24px;
        cursor: pointer;
    }
    
    .custom-prev {
        left: 10px;
        padding: 20px;
    }
    
    .custom-next {
        right: 10px;
        padding: 20px;
    } */

    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        color: black;
        outline: none;
        background: transparent;
    }

    /* .slick-prev, .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        width: 46px !important;
        height: 40px !important;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: black !important;
        border: none;
        outline: none;
    } */
    .slick-prev:before,
    .slick-next:before {
        /* color: #000 !important; */
        color: #0090e0 !important;
    }

    .slick-prev,
    .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 180px !important;
        display: block;
        width: 50px !important;
        height: 60px !important;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: black !important;
        border: none;
        outline: none;
        /* background: black; */
    }

    /* .slick-prev {
        left: -1px !important;
    } */



    .slick-prev:before,
    .slick-next:before {
        font-family: 'slick';
        font-size: 40px !important;
        line-height: 1;
        opacity: .75;
        /* color: black !important; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .center-mode-slider {
        .slick-center {
            border-radius: 20px;
            /* Add any other styles you want for the center slide */
        }
    }

    .main-footer-container {
        /* margin-left: -120px; */
        margin-top: 7%;
        /* border: 1px solid black; */
        height: 500px;
        background-image: url("../../assets//images/footerpage-images/bgimg.svg");
        /* background-repeat: no-repeat; */
        object-fit: cover;
        padding: 30px;
    }

    .footer {
        width: 100%;
        height: 500px;
        border-radius: 10px;
        border: 1px solid #0090E0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
    }

    .footer span {
        /* border: 1px solid black; */
        color: #e0e0e0;
        font-size: 22px;
        font-weight: 300;
        margin-top: 100px !important;
    }

    .footer-div1 {
        width: 20%;
        /* border: 1px solid black; */
        text-align: start;
        padding-left: 60px;
        padding-top: 60px;
        margin-left: 20px;
    }

    .footer-div2 {
        width: 20%;
        /* border: 1px solid black; */
        padding-top: 80px;
        /* margin-left: 170px; */
    }

    .footer-div3 {
        width: 18%;
        /* border: 1px solid black; */
    }

    .footer-div4 {
        width: 18%;
        /* border: 1px solid black; */
        padding-top: 110px;
    }

    .footerlogoimg {
        width: 55%;
    }

    .address {
        width: 100%;
        color: white;
        font-weight: 300;
        font-size: 20px;
        word-spacing: 5px;
        margin-top: 70px;
    }

    .footer-div2 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
        /* margin-top: -10px; */
    }

    .footer-div4 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
        margin-top: -10px;
    }

    .footer-div2 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 55px;
        cursor: pointer;
        margin-top: -7px;
    }

    .footer-div1 h2 {
        color: #ffff;
        font-size: 20px;
        font-weight: 500;
    }

    .footer-div4 h3 {
        text-align: start;
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-left: 60px;
        cursor: pointer;
        margin-top: -7px;
        /* margin-top: 50px; */
    }

    .festive {
        margin-top: -70px;
    }

    .copyright {
        margin-top: 30px !important;
    }

    .socialicon {
        margin-top: 20px;
        height: 40px;
        cursor: pointer
    }

    .home-page-container-mobile {
        display: none;

    }

    .main-about-container-mobile {
        display: none;
    }

    .main-services-container-mobile {
        display: none;
    }

    .home-page-container-mob {
        display: none;
    }

    .main-chooseus-container-mobile {
        display: none;
    }

    .main-jobopening-container-mobile {
        display: none;
    }

    .main-ourgallery-container-mob {
        display: none;
    }

    .main-footer-container-mob {
        display: none;
    }

    .desk-container {
        /* border: 1px solid red */
    }

    .tab-conatiner {
        display: none;
    }


}