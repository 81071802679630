.navbar-page-container {
    width: 100%;
    position: fixed;
    /* overflow-x: hidden !important;  responsible for not loading the page  */
    /* z-index: 999 !important; */
    /* overflow-inline: hidden; */
    /* border: 1px solid black; */

}

.navbar-main {
    height: 70px;
    max-width: 100%;
    padding: 15px;
    /* border: 1px solid black; */
    display: flex;
    /* background: rgba(255, 255, 255, 0.8); */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    z-index: 1200 !important;
    /* backdrop-filter: saturate(180%) blur(10px); */
}

/* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .navbar-main {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
} */

.nav-logo {
    text-align: start;
    /* padding: 20px; */
    padding-left: 110px;
    z-index: 1200 !important;
}

.nav-logo img {
    height: 50px;
    padding: 10px;
}


.nav-heading {
    width: 58%;
    /* border: 1px solid black; */
    margin-left: 15%;
    display: flex;
}

.nav-content {
    /* border: 1px solid black; */
    width: 45%;
    height: 40px;
    margin: 18px;
    margin-left: 4%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.nav-content span {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}

.career-btn {
    /* border: 1px solid black; */
    width: 13%;
    height: 40px;
    margin-top: 15px;
    margin-left: 33%;
}


.btn {
    background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
    width: 90%;
    height: 40px;
    text-align: center;
    font-weight: 400;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
    font-size: 18px;
}

.btn:hover {
    /* background-position: right center; change the direction of the change here */
    color: #fff !important;
    text-decoration: none;
}

.menu-btn {
    display: none;
}

.menu-item-section {
    display: none;
}
.spannavtext{
    text-decoration:none !important;
    text-decoration-line: none !important;
}
#activedot {
    /* display: none; */
    height: 10px;
    width: 10px;
    margin-left: 40%;
    border-radius: 50px;
    margin-top: 8px;
    background-color: #0090E0;
    box-shadow: 2px 5px 10px #0090E0
}

/* ================Responsive==================== */



@media (min-width: 450px) and (max-width: 576px) {}

@media (min-width: 577px) and (max-width: 768px) {}


/* ===============For small to large mobile ======== */

@media (max-width:766px) {
    .navbar-page-container {
        width: 100vw !important;
        position: fixed !important;
        /* overflow-y: hidden; */
        /* z-index: 1000 !important; */

    }

    .navbar-main {
        height: 60px;
        max-width: 100%;
        padding: 0px;
        /* border: 1px solid black; */
        display: flex;
        background: rgba(255, 255, 255, 0.8);
        /* backdrop-filter: saturate(180%) blur(10px); */
        justify-content: space-between;
    }

    .nav-heading {
        display: none;
    }

    .nav-logo {
        padding: 10px;
        /* border: 1px solid black; */
        /* padding-left: 110px; */
    }

    .nav-logo img {
        height: 30px;
        padding: 5px;
    }

    .nav-content span {
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
    }

    .menu-btn {
        display: flex;
        height: 40px;
        width: 40px;
        /* border: 1px solid black; */
        margin: 10px;

    }

    .menu-item-section {
        display: block;
        position: fixed !important;
        background-color: rgb(0, 0, 0);
        height: 100vh;
        width: 85vw;
        margin-top: -15%;
        /* overflow: hidden !important; */
        z-index: 999 !important;
        /* padding: 10px; */
    }

    .menu-item-section-innerdiv {
        position: fixed !important;
        background-color: rgb(0, 0, 0);
        height: 100vh;
        width: 85vw;
        /* border-radius: 10px; */
        /* margin-top: -15%; */
        /* overflow: hidden !important; */
        z-index: 999 !important;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(1px);
    }

    .nav1 {
        padding-left: 20px;
        text-align: start;

    }

    .menu-items h3 {
        color: #fff;
        text-align: start;
        margin-left: 20%;
        margin-top: 20px;
        font-weight: 400;

    }
}

/* =================For all Tabs============= */

@media (min-width: 767px) and (max-width: 991px) {

    .navbar-main {
        height: 70px;
        max-width: 100%;
        /* border: 1px solid black; */
        display: flex;
        background: rgba(255, 255, 255, 0.8);
        /* backdrop-filter: saturate(180%) blur(10px); */
    }


    .nav-logo {
        text-align: start;
        padding-top: 15px;
        padding-left: 30px;
    }

    .nav-logo img {
        height: 40px;
        /* border: 1px solid black; */
        /* padding-top: 10px; */
    }


    .nav-heading {
        width: 60%;
        /* border: 1px solid black; */
        margin-left: 8%;
        display: flex;
    }

    .nav-content span {
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
    }

    .nav-content {
        /* border: 1px solid black; */
        width: 100%;
        height: 40px;
        /* margin: 18px; */
        font-size: 14px;
        font-weight: 300;
        margin-left: 3%;
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
    }



    .career-btn {
        /* border: 1px solid black; */
        width: 30%;
        height: 40px !important;
        margin-top: 20px;
        margin-left: 1%;
    }


    .btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 90%;
        height: 35px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-left: 8px;
        font-size: 16px;
    }

    .btn:hover {
        /* background-position: right center; change the direction of the change here */
        color: #fff !important;
        text-decoration: none;
    }

    .menu-btn {
        display: none;
    }

}

/* ================for small to large laptops========== */

@media (min-width: 992px) and (max-width: 1800px) {

    .navbar-main {
        height: 70px;
        max-width: 100%;
        /* border: 1px solid black; */
        display: flex;
        background: rgba(255, 255, 255, 0.8);
        /* backdrop-filter: saturate(180%) blur(10px); */
    }


    .nav-logo {
        text-align: start;
        padding-top: 15px;
        padding-left: 30px;
    }

    .nav-logo img {
        height: 40px;
        /* border: 1px solid black; */
        /* padding-top: 10px; */
    }


    .nav-heading {
        width: 65%;
        /* border: 1px solid black; */
        margin-left: 8%;
        display: flex;
    }

    .nav-content span {
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
    }

    .nav-content {
        /* border: 1px solid black; */
        width: 60%;
        height: 40px;
        /* margin: 18px; */
        font-size: 14px;
        font-weight: 300;
        margin-left: 3%;
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
    }



    .career-btn {
        /* border: 1px solid black; */
        width: 20%;
        height: 40px !important;
        margin-top: 20px;
        margin-left: 12%;
    }


    .btn {
        background-image: linear-gradient(to right, #0090E0 2%, #6DCBFF 51%, #1FA2FF 100%);
        width: 90%;
        height: 35px;
        text-align: center;
        font-weight: 400;
        background-size: 200% auto;
        color: white;
        box-shadow: 0 0 20px #eee;
        border-radius: 10px;
        display: block;
        margin-left: 8px;
        font-size: 16px;
    }

    .btn:hover {
        /* background-position: right center; change the direction of the change here */
        color: #fff !important;
        text-decoration: none;
    }

    .menu-btn {
        display: none;
    }

}