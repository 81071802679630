.why-ide-ide-container {
    /* border: 1px solid black; */
    padding-top: 4%;
}

.ideplanebg {
    text-align: end;
}

.ideplanebg img {
    height: 900px;
}

.slidediv {
    width: 80%;
    /* border: 1px solid black; */
    display: flex;
    margin-left: 10%;
    margin-top: -42%;
    z-index: 1;
    /* animation: slideRightToLeft 2s ease-in-out; */
}

.slidedivcontent {
    width: 40%;
    /* border: 1px solid black; */
    margin-top: 5%;
    overflow: hidden;

}

.slideanimationdiv {
    animation: slideRightToLeft 2s ease-in-out;
}

@keyframes slideRightToLeft {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.slidedivimage {
    width: 60%;
    /* border: 1px solid black; */
    margin-left: 100px;
}

.slidedivimage img {
    width: 100%;

}

.partner-content {
    width: 60%;
    margin-left: 20%;
    margin-top: -10px;
    /* border: 1px solid black; */
}

.partner-content p {
    font-size: 24px;
    line-height: 42px;
    /* word-spacing: 1px; */
    text-align: start;
    color: rgb(77, 77, 77);
}

.whyide-counter {
    margin-top: -0%;
}


.counter-wrapper {
    width: 60%;
    border: 3px solid transparent;
    margin-left: 17%;
    border-radius: 30px;
    display: flex;
    padding: 50px;
    margin-top: 5%;
    justify-content: space-between;
    box-shadow: 2px 2px 5px gray;
    animation: colorChange 5s linear infinite;
    background-image: url("../../assets/images/homepage-images/blureffect.svg");
    /* background-repeat: repeat-x; */
    /* object-fit: cover; */
    /* background: rgba(255, 255, 255, 0.1); */
    backdrop-filter: blur(10px);
}

/* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .counter-wrapper {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
    }
} */

.counter-div {
    width: 20%;
    /* border: 1px solid black; */
    height: 150px;
    margin-top: 0px;
}

.numbers {
    display: flex;
    justify-content: space-around;
}

.number {
    margin-right: 10px;
    /* margin-left: 10px; */
}

.number .counter {
    font-weight: 700;
    font-size: 75px;
    /* margin: 0px 0; */
    justify-content: center !important;
    margin-left: -20px;
}

.number span {
    font-size: 20px;
    display: block;
    font-weight: 500;

}

.spanplus {
    font-size: 64px;
    /* margin-left: -150px; */
    font-weight: bold;
    color: #0090E0;
}

.spanplus1 {
    font-size: 64px;
    margin-left: -250px;
    font-weight: bold;
    color: #0090E0;
}

.spanplus2 {
    font-size: 64px;
    margin-left: -300px;
    font-weight: bold;
    color: #0090E0;
}

.spank {
    font-size: 64px;
    /* margin-left: -230px; */
    font-weight: bold;
    margin-top: 20px;
}

.spank1 {
    font-size: 64px;
    margin-left: -200px;
    font-weight: bold;
    margin-top: 20px;
}

.blur-image-ide {
    text-align: start;
    margin-top: -200px;
    margin-left: -150px;
}

.blur-image-ide img {
    height: 400px;
}

#searchele1 {
    text-align: end;
    margin-right: 12%;
}

#bagele1 {
    text-align: start;
    margin-left: 12%;
}

.elements {
    animation: moveElements 5s infinite;
}

@keyframes moveElements {

    /* 0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(15px); 
    }
    100% {
      transform: translateX(0);
    } */
    0% {
        transform: translate(-2%, -2%);
    }

    25% {
        transform: translateX(-3vw) translateY(-3%);
    }

    50% {
        transform: translateX(-1vw) translateY(-3vh);
    }

    75% {
        transform: translateX(-1%) translateY(-3vh);
    }

    100% {
        transform: translate(-1%, -3%);
    }
}

.center-line {
    text-align: center;
}

.center-line img {
    height: 1150px;
}

.publisher-tag {
    /* border: 1px solid black; */
    text-align: end;
    margin-right: -8.2%;
}

.publisher-tag img {
    height: 120px;
    text-align: end;
}

.advertizer-tag {
    /* border: 1px solid black; */
    text-align: start;
    margin-left: -2.3%;
    margin-top: 8.8%;
}

.advertizer-tag img {
    height: 120px;
}

/* .publisher-section{
    background-image: url("../../assets/images/why-ide-images/Line\ 11.svg");
    background-repeat:no-repeat;
} */
.publishers {
    width: 49.5%;
    /* border: 1px solid black; */
    margin-top: 2%;
}

.advertizer {
    width: 49.5%;
    /* border: 1px solid black; */
    margin-top: 2%;
    margin-left: 42px;
}

.line {
    width: 1%;
    /* border: 1px solid black; */

}

.publisher-advertizer-section {
    display: flex;
}

.publisher-content1 {
    display: flex;
    /* border:1px solid black; */
    margin-top: 5%;
    width: 50%;
    /* text-align:end !important; */
    margin-left: 53%;
}

.advertizer-content1 {
    display: flex;
    /* border:1px solid black; */
    margin-top: 5%;
    width: 50%;
    /* text-align:end !important; */
    margin-left: 50px;
}

.publishercontentimage img {
    height: 250px;
}

.advertizercontentimage img {
    height: 250px;
}

.publisherarrowimage img {
    margin-top: 100%;
}

.advertizerarrowimage img {
    margin-top: 100%;
}

.why-ide-ide-container-mob {
    display: none;
}

.counter-wrapper-mobide {
    display: none;
}

.footerdiv {
    margin-top: -100px;
}

.hidden {
    display: none;
}
.countermobide{
    display: none;
}

.publisher-advertizer-section-mob {
    display: none;
}

@media (max-width:450px) {
    .why-ide-ide-container {
        display: none;
    }

    .why-ide-ide-container-mob {
        display: block;
        padding-top: 8.5%;
        /* border: 1px solid black; */

    }

    .ideplanebgmob {
        text-align: end;
        margin-top: 20px;
    }

    .ideplanebgmob img {
        height: 350px;
    }

    .publisher-advertizer-section {
        display: none;
    }

    .slidedivmob {
        width: 90%;
        /* border: 1px solid black; */
        display: block;
        margin-left: 5%;
        margin-top: -90%;
    }

    .slidedivcontentmob {
        width: 100%;
        /* border: 1px solid black; */
        margin-top: 15%;
        overflow: hidden;

    }

    .slidedivimagemob {
        width: 100%;
        /* border: 1px solid black; */
        height: 250px;
    }

    .slidedivimagemob img {
        width: 100%;

    }

    .partner-contentmob {
        width: 80%;
        margin-left: 20%;
        margin-top: -12px;
        /* border: 1px solid black; */
    }

    .partner-contentmob p {
        font-size: 18px;
        line-height: 30px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    /* ===========counter css start================== */
    .counter-wrapper {
        display: none;
    }

    .whyide-counter {
        display: none;
    }
    .countermobide{
        display: block;
        margin-top: -45%;
    }

    .counter-wrapper-mobide {
        display: block;
        width: 70%;
        border: 2px solid transparent;
        margin-left: 7%;
        border-radius: 20px;
        display: flex;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: -52%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    #bagele1 {
        display: none;
    }

    .counter-div {
        width: 90%;
        /* border: 1px solid black; */
        height: 140px;
        margin-top: 20px;
        margin-left: 5%;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 30px;
        /* margin: 0px 0; */
        text-align: center;
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 16px;
        display: block;
        font-weight: 400;
    }

    #searchele1 {
        display: none;
    }

    .publisher-advertizer-section-mob {
        display: block;
        margin-top: 15%;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .footerdiv {
        margin-top: 50px;
    }

    /* ===========counter css end================== */


}

@media (min-width: 450px) and (max-width: 576px) {
    .why-ide-ide-container {
        display: none;
    }

    .why-ide-ide-container-mob {
        display: block;
        padding-top: 8.5%;
        /* border: 1px solid black; */

    }

    .ideplanebgmob {
        text-align: end;
        margin-top: 20px;
    }

    .ideplanebgmob img {
        height: 440px;
    }

    .publisher-advertizer-section {
        display: none;
    }

    .slidedivmob {
        width: 90%;
        /* border: 1px solid black; */
        display: block;
        margin-left: 5%;
        margin-top: -75%;
    }

    .slidedivcontentmob {
        width: 100%;
        /* border: 1px solid black; */
        margin-top: 0%;
        overflow: hidden;

    }
.whyide-counter{
    display: none;
}

.countermobide{
    display: block;
    margin-top: -45%;
}

.counter-wrapper-mobide {
    display: block;
    width: 70%;
    border: 2px solid transparent;
    margin-left: 7%;
    border-radius: 20px;
    display: flex;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 0px;
    margin-top: -52%;
    justify-content: space-between;
    box-shadow: 2px 2px 5px gray;
    animation: colorChange 5s linear infinite;
    background-image: url("../../assets/images/homepage-images/blureffect.svg");
    /* background-repeat: repeat-x; */
    /* object-fit: cover; */
    /* background: rgba(255, 255, 255, 0.1); */
    /* backdrop-filter: blur(10px); */
}

    .slidedivimagemob {
        width: 100%;
        /* border: 1px solid black; */
        height: 250px;
    }

    .slidedivimagemob img {
        width: 100%;

    }

    .partner-contentmob {
        width: 80%;
        margin-left: 20%;
        margin-top: -12px;
        /* border: 1px solid black; */
    }

    .partner-contentmob p {
        font-size: 18px;
        line-height: 30px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    /* ===========counter css start================== */
    .counter-wrapper {
        display: none;
    }

    .counter-wrapper-mobide {
        display: block;
        width: 70%;
        border: 2px solid transparent;
        margin-left: 9%;
        border-radius: 20px;
        display: flex;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: -52%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    #bagele1 {
        display: none;
    }

    .counter-div {
        width: 90%;
        /* border: 1px solid black; */
        height: 140px;
        margin-top: 20px;
        margin-left: 5%;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 30px;
        /* margin: 0px 0; */
        text-align: center;
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 16px;
        display: block;
        font-weight: 400;
    }

    #searchele1 {
        display: none;
    }

    .publisher-advertizer-section-mob {
        display: block;
        margin-top: 15%;
    }

    .publisher-advertizer-section-mob img {
        height: 700px;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .footerdiv {
        margin-top: 50px;
    }

    .slideanimationdiv {
        margin-top: 180px !important;
    }

    /* ===========counter css end================== */


}

@media (min-width: 576px) and (max-width: 668px) {
    .why-ide-ide-container {
        display: none;
    }

    .why-ide-ide-container-mob {
        display: block;
        padding-top: 8.5%;
        /* border: 1px solid black; */

    }

    .ideplanebgmob {
        text-align: end;
        margin-top: -20px;
    }

    .ideplanebgmob img {
        height: 600px;
    }

    .publisher-advertizer-section {
        display: none;
    }

    .slidedivmob {
        width: 90%;
        /* border: 1px solid black; */
        display: block;
        margin-left: 5%;
        margin-top: -83%;
    }

    .slidedivcontentmob {
        width: 100%;
        /* border: 1px solid black; */
        margin-top: 0%;
        overflow: hidden;

    }

    .slidedivimagemob {
        width: 100%;
        /* border: 1px solid black; */
        height: 250px;
    }

    .slidedivimagemob img {
        width: 100%;

    }

    .partner-contentmob {
        width: 80%;
        margin-left: 20%;
        margin-top: -12px;
        /* border: 1px solid black; */
    }

    .partner-contentmob p {
        font-size: 18px;
        line-height: 30px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    /* ===========counter css start================== */
    .counter-wrapper {
        display: none;
    }
    .whyide-counter{
        display: none;
    }
    .countermobide{
        display: block;
        margin-top: -45%;
    }

    .counter-wrapper-mobide {
        display: block;
        width: 70%;
        border: 2px solid transparent;
        margin-left: 7%;
        border-radius: 20px;
        display: flex;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: -52%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .counter-wrapper-mobide {
        display: block;
        width: 70%;
        border: 2px solid transparent;
        margin-left: 9%;
        border-radius: 20px;
        display: flex;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: -45%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    #bagele1 {
        display: none;
    }

    .counter-div {
        width: 90%;
        /* border: 1px solid black; */
        height: 140px;
        margin-top: 20px;
        margin-left: 5%;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 30px;
        /* margin: 0px 0; */
        text-align: center;
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 16px;
        display: block;
        font-weight: 400;
    }

    #searchele1 {
        display: none;
    }

    .publisher-advertizer-section-mob {
        display: block;
        margin-top: 15%;
    }

    .publisher-advertizer-section-mob img {
        height: 700px;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .footerdiv {
        margin-top: 50px;
    }

    .slideanimationdiv {
        margin-top: 310px !important;
    }

    /* ===========counter css end================== */

}

@media (min-width: 668px) and (max-width: 768px) {
    .why-ide-ide-container {
        display: none;
    }

    .why-ide-ide-container-mob {
        display: block;
        padding-top: 8.5%;
        /* border: 1px solid black; */

    }

    .ideplanebgmob {
        text-align: end;
        margin-top: -20px;
    }

    .ideplanebgmob img {
        height: 600px;
    }
    .whyide-counter{
        display: none;
    }
    .publisher-advertizer-section {
        display: none;
    }

    .slidedivmob {
        width: 90%;
        /* border: 1px solid black; */
        display: block;
        margin-left: 5%;
        margin-top: -75%;
    }

    .slidedivcontentmob {
        width: 100%;
        /* border: 1px solid black; */
        margin-top: 0%;
        overflow: hidden;

    }

    .slidedivimagemob {
        width: 100%;
        /* border: 1px solid black; */
        height: 250px;
    }

    .slidedivimagemob img {
        width: 100%;

    }

    .partner-contentmob {
        width: 80%;
        margin-left: 20%;
        margin-top: -12px;
        /* border: 1px solid black; */
    }

    .partner-contentmob p {
        font-size: 18px;
        line-height: 30px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    /* ===========counter css start================== */
    .counter-wrapper {
        display: none;
    }

    .counter-wrapper-mobide {
        display: block;
        width: 70%;
        border: 2px solid transparent;
        margin-left: 11%;
        border-radius: 20px;
        display: flex;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: -40% !important;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    .countermobide{
        display: block;
        margin-top: -45%;
    }

    .counter-wrapper-mobide {
        display: block;
        width: 70%;
        border: 2px solid transparent;
        margin-left: 7%;
        border-radius: 20px;
        display: flex;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-top: -52%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        /* backdrop-filter: blur(10px); */
    }

    #bagele1 {
        display: none;
    }

    .counter-div {
        width: 90%;
        /* border: 1px solid black; */
        height: 140px;
        margin-top: 20px;
        margin-left: 5%;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 30px;
        /* margin: 0px 0; */
        text-align: center;
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 16px;
        display: block;
        font-weight: 400;
    }

    #searchele1 {
        display: none;
    }

    .publisher-advertizer-section-mob {
        display: block;
        margin-top: 15%;
    }

    .publisher-advertizer-section-mob img {
        height: 800px;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .footerdiv {
        margin-top: 50px;
    }

    .slideanimationdiv {
        margin-top: 350px !important;
    }

    /* ===========counter css end================== */


}

@media (min-width: 769px) and (max-width: 886px) {
    .why-ide-ide-container {
        /* border: 1px solid black; */
        padding-top: 8%;
    }

    .ideplanebg {
        text-align: end;
        margin-top: 20px;
    }

    .ideplanebg img {
        height: 500px;
    }

    .slidediv {
        width: 90%;
        /* border: 1px solid black; */
        display: flex;
        margin-left: 4%;
        margin-top: -47%;
        height: 300px;

    }

    .slidetextmaindiv {
        height: 80px;
        /* border: 1px solid black; */
    }

    .slidetextmaindiv span {
        font-size: 25px !important;
    }

    .slidetextmaindiv h1 {
        font-size: 26px !important;
        margin-left: 10px !important;
    }

    .slidedivcontent {
        width: 60%;
        /* border: 1px solid black; */
        margin-top: 4%;
        overflow: hidden;

    }

    .slideanimationdiv {
        animation: slideRightToLeft 2s ease-in-out;
    }

    @keyframes slideRightToLeft {
        from {
            transform: translateX(100%);
            opacity: 0;
        }

        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    .slidedivimage {
        width: 75%;
        /* border: 1px solid black; */
    }

    .slidedivimage img {
        width: 100%;
        margin-left: 0px;
    }

    .partner-content {
        width: 90%;
        margin-left: 5%;
        margin-top: -15px;
        /* border: 1px solid black; */
        height: 150px;
    }

    .partner-content p {
        font-size: 20px;
        line-height: 32px;
        word-spacing: 0px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    .whyide-counter {
        margin-top: -5%;
    }


    .counter-wrapper {
        width: 80%;
        border: 3px solid transparent;
        margin-left: 4%;
        border-radius: 30px;
        display: flex;
        padding: 50px;
        margin-top: 5%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 60px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 18px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-ide {
        text-align: start;
        margin-top: -200px;
        margin-left: -150px;
    }

    .blur-image-ide img {
        height: 400px;
    }

    #searchele1 {
        text-align: end;
        margin-right: 12%;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .center-line {
        text-align: center;
    }

    .center-line img {
        height: 900px;
    }

    .publisher-tag {
        /* border: 1px solid black; */
        text-align: end;
        margin-right: -16%;
    }

    .publisher-tag img {
        height: 100px;
        text-align: end;
    }

    .advertizer-tag {
        /* border: 1px solid black; */
        text-align: start;
        margin-left: -2%;
        margin-top: 10.7%;
    }

    .advertizer-tag img {
        height: 100px;
    }

    /* .publisher-section{
        background-image: url("../../assets/images/why-ide-images/Line\ 11.svg");
        background-repeat:no-repeat;
    } */
    .publishers {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 7%;
    }

    .advertizer {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 10%;
        margin-left: 30px;
    }

    .line {
        width: 1%;
        /* border: 1px solid black; */

    }

    .publisher-advertizer-section {
        display: flex;
    }

    .publisher-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 15%;
        width: 80%;
        /* text-align:end !important; */
        margin-left: 24%;
    }

    .advertizer-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 15%;
        width: 70%;
        /* text-align:end !important; */
        margin-left: 70px;
    }

    .publishercontentimage img {
        height: 160px;
    }

    .advertizercontentimage img {
        height: 160px;
    }

    .publisherarrowimage img {
        margin-top: 100%;
    }

    .advertizerarrowimage img {
        margin-top: 100%;
    }

    .why-ide-ide-container-mob {
        display: none;
    }

    .counter-wrapper-mobide {
        display: none;
    }

    .footerdiv {
        margin-top: -100px;
    }

    .publisher-advertizer-section-mob {
        display: none;
    }
}

@media (min-width: 886px) and (max-width: 991px) {
    .why-ide-ide-container {
        /* border: 1px solid black; */
        padding-top: 8%;
    }

    .ideplanebg {
        text-align: end;
        margin-top: 20px;
    }

    .ideplanebg img {
        height: 500px;
    }

    .slidediv {
        width: 90%;
        /* border: 1px solid black; */
        display: flex;
        margin-left: 4%;
        margin-top: -47%;
        height: 300px;

    }

    .slidetextmaindiv {
        height: 80px;
        /* border: 1px solid black; */
    }

    .slidetextmaindiv span {
        font-size: 25px !important;
    }

    .slidetextmaindiv h1 {
        font-size: 26px !important;
        margin-left: 10px !important;
    }

    .slidedivcontent {
        width: 60%;
        /* border: 1px solid black; */
        margin-top: 4%;
        overflow: hidden;

    }

    .slideanimationdiv {
        animation: slideRightToLeft 2s ease-in-out;
    }

    @keyframes slideRightToLeft {
        from {
            transform: translateX(100%);
            opacity: 0;
        }

        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    .slidedivimage {
        width: 75%;
        /* border: 1px solid black; */
    }

    .slidedivimage img {
        width: 100%;
        margin-left: 0px;
    }

    .partner-content {
        width: 90%;
        margin-left: 5%;
        margin-top: -15px;
        /* border: 1px solid black; */
        height: 150px;
    }

    .partner-content p {
        font-size: 20px;
        line-height: 32px;
        word-spacing: 0px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    .whyide-counter {
        margin-top: -5%;
    }


    .counter-wrapper {
        width: 80%;
        border: 3px solid transparent;
        margin-left: 5%;
        border-radius: 30px;
        display: flex;
        padding: 50px;
        margin-top: 5%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 60px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 18px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-ide {
        text-align: start;
        margin-top: -200px;
        margin-left: -150px;
    }

    .blur-image-ide img {
        height: 400px;
    }

    #searchele1 {
        text-align: end;
        margin-right: 12%;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .center-line {
        text-align: center;
    }

    .center-line img {
        height: 1150px;
    }

    .publisher-tag {
        /* border: 1px solid black; */
        text-align: end;
        margin-right: -17%;
    }

    .publisher-tag img {
        height: 100px;
        text-align: end;
    }

    .advertizer-tag {
        /* border: 1px solid black; */
        text-align: start;
        margin-left: -2%;
        margin-top: 10.5%;
    }

    .advertizer-tag img {
        height: 100px;
    }

    /* .publisher-section{
        background-image: url("../../assets/images/why-ide-images/Line\ 11.svg");
        background-repeat:no-repeat;
    } */
    .publishers {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 4%;
    }

    .advertizer {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 6%;
        margin-left: 42px;
    }

    .line {
        width: 1%;
        /* border: 1px solid black; */

    }

    .publisher-advertizer-section {
        display: flex;
    }

    .publisher-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 23%;
        width: 70%;
        /* text-align:end !important; */
        margin-left: 24%;
    }

    .advertizer-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 23%;
        width: 70%;
        /* text-align:end !important; */
        margin-left: 70px;
    }

    .publishercontentimage img {
        height: 180px;
    }

    .advertizercontentimage img {
        height: 180px;
    }

    .publisherarrowimage img {
        margin-top: 100%;
    }

    .advertizerarrowimage img {
        margin-top: 100%;
    }

    .why-ide-ide-container-mob {
        display: none;
    }

    .counter-wrapper-mobide {
        display: none;
    }

    .footerdiv {
        margin-top: -100px;
    }

    .publisher-advertizer-section-mob {
        display: none;
    }
}

@media (min-width: 991px) and (max-width: 1100px) {
    .why-ide-ide-container {
        /* border: 1px solid black; */
        padding-top: 4%;
    }

    .ideplanebg {
        text-align: end;
        margin-top: 20px;
    }

    .ideplanebg img {
        height: 700px;
    }

    .slidediv {
        width: 90%;
        /* border: 1px solid black; */
        display: flex;
        margin-left: 5%;
        margin-top: -55%;

    }

    .slidedivcontent {
        width: 50%;
        /* border: 1px solid black; */
        margin-top: 4%;
        overflow: hidden;

    }

    .slideanimationdiv {
        animation: slideRightToLeft 2s ease-in-out;
    }

    @keyframes slideRightToLeft {
        from {
            transform: translateX(100%);
            opacity: 0;
        }

        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    .slidedivimage {
        width: 80%;
        /* border: 1px solid black; */
    }

    .slidedivimage img {
        width: 100%;
        margin-left: 0px;
    }

    .partner-content {
        width: 80%;
        margin-left: 20%;
        margin-top: -30px;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 22px;
        line-height: 32px;
        word-spacing: 0px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    .whyide-counter {
        margin-top: 0%;
    }


    .counter-wrapper {
        width: 80%;
        border: 3px solid transparent;
        margin-left: 5%;
        border-radius: 30px;
        display: flex;
        padding: 50px;
        margin-top: 10%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 75px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 20px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-ide {
        text-align: start;
        margin-top: -200px;
        margin-left: -150px;
    }

    .blur-image-ide img {
        height: 400px;
    }

    #searchele1 {
        text-align: end;
        margin-right: 12%;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .center-line {
        text-align: center;
    }

    .center-line img {
        height: 1150px;
    }

    .publisher-tag {
        /* border: 1px solid black; */
        text-align: end;
        margin-right: -16.2%;
    }

    .publisher-tag img {
        height: 120px;
        text-align: end;
    }

    .advertizer-tag {
        /* border: 1px solid black; */
        text-align: start;
        margin-left: -2.3%;
        margin-top: 11.3%;
    }

    .advertizer-tag img {
        height: 120px;
    }

    /* .publisher-section{
        background-image: url("../../assets/images/why-ide-images/Line\ 11.svg");
        background-repeat:no-repeat;
    } */
    .publishers {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 4%;
    }

    .advertizer {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 6%;
        margin-left: 42px;
    }

    .line {
        width: 1%;
        /* border: 1px solid black; */

    }

    .publisher-advertizer-section {
        display: flex;
    }

    .publisher-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 15%;
        width: 70%;
        /* text-align:end !important; */
        margin-left: 27%;
    }

    .advertizer-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 15%;
        width: 70%;
        /* text-align:end !important; */
        margin-left: 70px;
    }

    .publishercontentimage img {
        height: 200px;
    }

    .advertizercontentimage img {
        height: 200px;
    }

    .publisherarrowimage img {
        margin-top: 100%;
    }

    .advertizerarrowimage img {
        margin-top: 100%;
    }

    .why-ide-ide-container-mob {
        display: none;
    }

    .counter-wrapper-mobide {
        display: none;
    }

    .footerdiv {
        margin-top: -100px;
    }

    .publisher-advertizer-section-mob {
        display: none;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .why-ide-ide-container {
        /* border: 1px solid black; */
        padding-top: 4%;
    }

    .ideplanebg {
        text-align: end;
        margin-top: 20px;
    }

    .ideplanebg img {
        height: 700px;
    }

    .slidediv {
        width: 87%;
        /* border: 1px solid black; */
        display: flex;
        margin-left: 5%;
        margin-top: -52%;

    }

    .slidedivcontent {
        width: 50%;
        /* border: 1px solid black; */
        margin-top: 1%;
        overflow: hidden;

    }

    .slideanimationdiv {
        animation: slideRightToLeft 2s ease-in-out;
    }

    @keyframes slideRightToLeft {
        from {
            transform: translateX(100%);
            opacity: 0;
        }

        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    .slidedivimage {
        width: 75%;
        /* border: 1px solid black; */
    }

    .slidedivimage img {
        width: 100%;
        margin-left: 0px;
    }

    .partner-content {
        width: 80%;
        margin-left: 20%;
        margin-top: -15px;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 22px;
        line-height: 35px;
        word-spacing: 0px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    .whyide-counter {
        margin-top: 0%;
    }


    .counter-wrapper {
        width: 70%;
        border: 3px solid transparent;
        margin-left: 10%;
        border-radius: 30px;
        display: flex;
        padding: 50px;
        margin-top: 8%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 75px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 20px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-ide {
        text-align: start;
        margin-top: -200px;
        margin-left: -150px;
    }

    .blur-image-ide img {
        height: 400px;
    }

    #searchele1 {
        text-align: end;
        margin-right: 12%;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .center-line {
        text-align: center;
    }

    .center-line img {
        height: 1150px;
    }

    .publisher-tag {
        /* border: 1px solid black; */
        text-align: end;
        margin-right: -14.3%;
    }

    .publisher-tag img {
        height: 120px;
        text-align: end;
    }

    .advertizer-tag {
        /* border: 1px solid black; */
        text-align: start;
        margin-left: -2.3%;
        margin-top: 10%;
    }

    .advertizer-tag img {
        height: 120px;
    }

    /* .publisher-section{
        background-image: url("../../assets/images/why-ide-images/Line\ 11.svg");
        background-repeat:no-repeat;
    } */
    .publishers {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 4%;
    }

    .advertizer {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 6%;
        margin-left: 42px;
    }

    .line {
        width: 1%;
        /* border: 1px solid black; */

    }

    .publisher-advertizer-section {
        display: flex;
    }

    .publisher-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 12%;
        width: 50%;
        /* text-align:end !important; */
        margin-left: 38%;
    }

    .advertizer-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 12%;
        width: 50%;
        /* text-align:end !important; */
        margin-left: 70px;
    }

    .publishercontentimage img {
        height: 200px;
    }

    .advertizercontentimage img {
        height: 200px;
    }

    .publisherarrowimage img {
        margin-top: 100%;
    }

    .advertizerarrowimage img {
        margin-top: 100%;
    }

    .why-ide-ide-container-mob {
        display: none;
    }

    .counter-wrapper-mobide {
        display: none;
    }

    .footerdiv {
        margin-top: -100px;
    }

    .publisher-advertizer-section-mob {
        display: none;
    }
}

@media (min-width: 1200px) and (max-width: 1350px) {
    .why-ide-ide-container {
        /* border: 1px solid black; */
        padding-top: 4%;
    }

    .ideplanebg {
        text-align: end;
        margin-top: 20px;
    }

    .ideplanebg img {
        height: 700px;
    }

    .slidediv {
        width: 87%;
        /* border: 1px solid black; */
        display: flex;
        margin-left: 5%;
        margin-top: -48%;

    }

    .slidedivcontent {
        width: 40%;
        /* border: 1px solid black; */
        margin-top: 4%;
        overflow: hidden;

    }

    .slideanimationdiv {
        animation: slideRightToLeft 2s ease-in-out;
    }

    @keyframes slideRightToLeft {
        from {
            transform: translateX(100%);
            opacity: 0;
        }

        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    .slidedivimage {
        width: 75%;
        /* border: 1px solid black; */
    }

    .slidedivimage img {
        width: 95%;
        margin-left: 80px;
    }

    .partner-content {
        width: 80%;
        margin-left: 20%;
        margin-top: -10px;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 24px;
        line-height: 38px;
        word-spacing: 0px;
        text-align: start;
        color: rgb(77, 77, 77);
    }

    .whyide-counter {
        margin-top: 0%;
    }


    .counter-wrapper {
        width: 70%;
        border: 3px solid transparent;
        margin-left: 10%;
        border-radius: 30px;
        display: flex;
        padding: 50px;
        margin-top: 5%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 75px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 20px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-ide {
        text-align: start;
        margin-top: -200px;
        margin-left: -150px;
    }

    .blur-image-ide img {
        height: 400px;
    }

    #searchele1 {
        text-align: end;
        margin-right: 12%;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .center-line {
        text-align: center;
    }

    .center-line img {
        height: 1150px;
    }

    .publisher-tag {
        /* border: 1px solid black; */
        text-align: end;
        margin-right: -12.8%;
    }

    .publisher-tag img {
        height: 120px;
        text-align: end;
    }

    .advertizer-tag {
        /* border: 1px solid black; */
        text-align: start;
        margin-left: -2.3%;
        margin-top: 10%;
    }

    .advertizer-tag img {
        height: 120px;
    }

    /* .publisher-section{
        background-image: url("../../assets/images/why-ide-images/Line\ 11.svg");
        background-repeat:no-repeat;
    } */
    .publishers {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 4%;
    }

    .advertizer {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 5.3%;
        margin-left: 42px;
    }

    .line {
        width: 1%;
        /* border: 1px solid black; */

    }

    .publisher-advertizer-section {
        display: flex;
    }

    .publisher-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 12%;
        width: 50%;
        /* text-align:end !important; */
        margin-left: 42%;
    }

    .advertizer-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 12%;
        width: 50%;
        /* text-align:end !important; */
        margin-left: 70px;
    }

    .publishercontentimage img {
        height: 200px;
    }

    .advertizercontentimage img {
        height: 200px;
    }

    .publisherarrowimage img {
        margin-top: 100%;
    }

    .advertizerarrowimage img {
        margin-top: 100%;
    }

    .why-ide-ide-container-mob {
        display: none;
    }

    .counter-wrapper-mobide {
        display: none;
    }

    .footerdiv {
        margin-top: -100px;
    }

    .publisher-advertizer-section-mob {
        display: none;
    }
}

@media (min-width: 1350px) and (max-width: 1500px) {
    .why-ide-ide-container {
        /* border: 1px solid black; */
        padding-top: 4%;
    }

    .ideplanebg {
        text-align: end;
        margin-top: 20px;
    }

    .ideplanebg img {
        height: 700px;
    }

    .slidediv {
        width: 80%;
        /* border: 1px solid black; */
        display: flex;
        margin-left: 5%;
        margin-top: -43%;

    }

    .slidedivcontent {
        width: 40%;
        /* border: 1px solid black; */
        margin-top: 5%;
        overflow: hidden;

    }

    .slideanimationdiv {
        animation: slideRightToLeft 2s ease-in-out;
    }

    @keyframes slideRightToLeft {
        from {
            transform: translateX(100%);
            opacity: 0;
        }

        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    .slidedivimage {
        width: 75%;
        /* border: 1px solid black; */
    }

    .slidedivimage img {
        width: 95%;
        margin-left: 80px;
    }

    .partner-content {
        width: 60%;
        margin-left: 20%;
        margin-top: -10px;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 24px;
        line-height: 42px;
        word-spacing: 1px;
        text-align: justify;
        color: rgb(77, 77, 77);
    }

    .whyide-counter {
        margin-top: 0%;
    }


    .counter-wrapper {
        width: 70%;
        border: 3px solid transparent;
        margin-left: 10%;
        border-radius: 30px;
        display: flex;
        padding: 50px;
        margin-top: 5%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 75px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 20px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-ide {
        text-align: start;
        margin-top: -200px;
        margin-left: -150px;
    }

    .blur-image-ide img {
        height: 400px;
    }

    #searchele1 {
        text-align: end;
        margin-right: 12%;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .center-line {
        text-align: center;
    }

    .center-line img {
        height: 1150px;
    }

    .publisher-tag {
        /* border: 1px solid black; */
        text-align: end;
        margin-right: -11.5%;
    }

    .publisher-tag img {
        height: 120px;
        text-align: end;
    }

    .advertizer-tag {
        /* border: 1px solid black; */
        text-align: start;
        margin-left: -2.3%;
        margin-top: 8.8%;
    }

    .advertizer-tag img {
        height: 120px;
    }

    /* .publisher-section{
        background-image: url("../../assets/images/why-ide-images/Line\ 11.svg");
        background-repeat:no-repeat;
    } */
    .publishers {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 4%;
    }

    .advertizer {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 5.3%;
        margin-left: 42px;
    }

    .line {
        width: 1%;
        /* border: 1px solid black; */

    }

    .publisher-advertizer-section {
        display: flex;
    }

    .publisher-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 12%;
        width: 50%;
        /* text-align:end !important; */
        margin-left: 45%;
    }

    .advertizer-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 12%;
        width: 50%;
        /* text-align:end !important; */
        margin-left: 70px;
    }

    .publishercontentimage img {
        height: 200px;
    }

    .advertizercontentimage img {
        height: 200px;
    }

    .publisherarrowimage img {
        margin-top: 100%;
    }

    .advertizerarrowimage img {
        margin-top: 100%;
    }

    .why-ide-ide-container-mob {
        display: none;
    }

    .counter-wrapper-mobide {
        display: none;
    }

    .footerdiv {
        margin-top: -100px;
    }

    .publisher-advertizer-section-mob {
        display: none;
    }
}

@media (min-width: 1500px) and (max-width: 1700px) {
    .why-ide-ide-container {
        /* border: 1px solid black; */
        padding-top: 4%;
    }

    .ideplanebg {
        text-align: end;
    }

    .ideplanebg img {
        height: 800px;
    }

    .slidediv {
        width: 80%;
        /* border: 1px solid black; */
        display: flex;
        margin-left: 10%;
        margin-top: -40%;
    }

    .slidedivcontent {
        width: 40%;
        /* border: 1px solid black; */
        margin-top: 5%;
        overflow: hidden;

    }

    .slideanimationdiv {
        animation: slideRightToLeft 2s ease-in-out;
    }

    @keyframes slideRightToLeft {
        from {
            transform: translateX(100%);
            opacity: 0;
        }

        to {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    .slidedivimage {
        width: 60%;
        /* border: 1px solid black; */
    }

    .slidedivimage img {
        width: 90%;
        margin-left: 100px;
    }

    .partner-content {
        width: 60%;
        margin-left: 20%;
        margin-top: -10px;
        /* border: 1px solid black; */
    }

    .partner-content p {
        font-size: 24px;
        line-height: 42px;
        word-spacing: 1px;
        text-align: justify;
        color: rgb(77, 77, 77);
    }

    .whyide-counter {
        margin-top: -5%;
    }


    .counter-wrapper {
        width: 60%;
        border: 3px solid transparent;
        margin-left: 17%;
        border-radius: 30px;
        display: flex;
        padding: 50px;
        margin-top: 5%;
        justify-content: space-between;
        box-shadow: 2px 2px 5px gray;
        animation: colorChange 5s linear infinite;
        background-image: url("../../assets/images/homepage-images/blureffect.svg");
        /* background-repeat: repeat-x; */
        /* object-fit: cover; */
        /* background: rgba(255, 255, 255, 0.1); */
        backdrop-filter: blur(10px);
    }

    /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .counter-wrapper {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(5px);
        }
    } */

    .counter-div {
        width: 20%;
        /* border: 1px solid black; */
        height: 150px;
        margin-top: 0px;
    }

    .numbers {
        display: flex;
        justify-content: space-around;
    }

    .number {
        margin-right: 10px;
        /* margin-left: 10px; */
    }

    .number .counter {
        font-weight: 700;
        font-size: 75px;
        /* margin: 0px 0; */
        justify-content: center !important;
        margin-left: -20px;
    }

    .number span {
        font-size: 20px;
        display: block;
        font-weight: 500;

    }

    .spanplus {
        font-size: 64px;
        /* margin-left: -150px; */
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus1 {
        font-size: 64px;
        margin-left: -250px;
        font-weight: bold;
        color: #0090E0;
    }

    .spanplus2 {
        font-size: 64px;
        margin-left: -300px;
        font-weight: bold;
        color: #0090E0;
    }

    .spank {
        font-size: 64px;
        /* margin-left: -230px; */
        font-weight: bold;
        margin-top: 20px;
    }

    .spank1 {
        font-size: 64px;
        margin-left: -200px;
        font-weight: bold;
        margin-top: 20px;
    }

    .blur-image-ide {
        text-align: start;
        margin-top: -200px;
        margin-left: -150px;
    }

    .blur-image-ide img {
        height: 400px;
    }

    #searchele1 {
        text-align: end;
        margin-right: 12%;
    }

    #bagele1 {
        text-align: start;
        margin-left: 12%;
    }

    .elements {
        animation: moveElements 5s infinite;
    }

    @keyframes moveElements {

        /* 0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(15px); 
        }
        100% {
          transform: translateX(0);
        } */
        0% {
            transform: translate(-2%, -2%);
        }

        25% {
            transform: translateX(-3vw) translateY(-3%);
        }

        50% {
            transform: translateX(-1vw) translateY(-3vh);
        }

        75% {
            transform: translateX(-1%) translateY(-3vh);
        }

        100% {
            transform: translate(-1%, -3%);
        }
    }

    .center-line {
        text-align: center;
    }

    .center-line img {
        height: 1150px;
    }

    .publisher-tag {
        /* border: 1px solid black; */
        text-align: end;
        margin-right: -10%;
    }

    .publisher-tag img {
        height: 120px;
        text-align: end;
    }

    .advertizer-tag {
        /* border: 1px solid black; */
        text-align: start;
        margin-left: -2.3%;
        margin-top: 8.8%;
    }

    .advertizer-tag img {
        height: 120px;
    }

    /* .publisher-section{
        background-image: url("../../assets/images/why-ide-images/Line\ 11.svg");
        background-repeat:no-repeat;
    } */
    .publishers {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 4%;
    }

    .advertizer {
        width: 49.5%;
        /* border: 1px solid black; */
        margin-top: 5.2%;
        margin-left: 42px;
    }

    .line {
        width: 1%;
        /* border: 1px solid black; */

    }

    .publisher-advertizer-section {
        display: flex;
    }

    .publisher-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 5%;
        width: 50%;
        /* text-align:end !important; */
        margin-left: 40%;
    }

    .advertizer-content1 {
        display: flex;
        /* border:1px solid black; */
        margin-top: 5%;
        width: 50%;
        /* text-align:end !important; */
        margin-left: 50px;
    }

    .publishercontentimage img {
        height: 250px;
    }

    .advertizercontentimage img {
        height: 250px;
    }

    .publisherarrowimage img {
        margin-top: 100%;
    }

    .advertizerarrowimage img {
        margin-top: 100%;
    }

    .why-ide-ide-container-mob {
        display: none;
    }

    .counter-wrapper-mobide {
        display: none;
    }

    .footerdiv {
        margin-top: -100px;
    }

    .publisher-advertizer-section-mob {
        display: none;
    }
}