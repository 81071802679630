/* Default text size */



.Main-container {
    margin: 35px;
    text-align: center;
   
}


.responsive-text {
    font-size: 250px;
    color: rgb(225, 189, 189);
    text-shadow: 10px 25px 8px #E5E5E5;
    margin-top: 8px;
}

/* Responsive text size for smaller screens */
@media (max-width: 768px) {
    .responsive-text {
        font-size: 150px;
    }
}