/* cardslide.css */

.Footer-corosul {


  position: relative;
  /* border: 1px solid; */
  /* height: 35vh; */
}


.Footer-slidertitle {
  position: absolute;
  top: -6%;
  left: 35%;
  /* color: #000; */
  font-family: Rubik;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 1.5rem */
  display: none;

}

.Footerpartner-text {
  color: #5195D2;
  font-family: Rubik;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;


}

.Footer-slider {
  height: 16vh;
  /* Use viewport height for full screen */
  width: 100%;
  /* Full width */
  overflow: hidden;
  /* Hide overflowing content */
  position: relative;
  margin: auto;
  /* border: 1px solid ; */
  display: flex;
  /* margin-top: 25px; */
  justify-content: center;
  /* background-color: rgba(156, 207, 235, 0.4); */
}

.Footer-slider2 {
  /* height: 200px; */
  /* Use viewport height for full screen */
  width: 100%;
  /* Full width */
  overflow: hidden;
  /* Hide overflowing content */
  position: relative;
  margin: auto;
  /* border: 1px solid ; */
  display: flex;
  justify-content: center;
  display: none;
}

.Footer-slidershadow {
  height: 12.5rem;
  width: 31rem;
  /* border: 1px solid red; */
  background: #9ccfeb;
  border-radius: 25%;

  /* position: absolute;
  filter: blur(16.25rem); */


}

.Footer-slide-track {
  /* margin-top: 5%; */
  display: flex;
  width: 100%;
  /* Adjust based on the number of images */
  animation: scroll 40s linear infinite ;

  /* border: 1px solid ; */
}

.Footer-slide-track2 {
  display: flex;
  width: 100%;
  /* Adjust based on the number of images */
  animation: scroll 50s linear infinite;
  animation-direction:normal;
  /* border: 1px solid ; */
}

.Footer-slide {
  height: 8rem;
  width: 120px;
  /* Each slide takes up 100% of the slider's width */
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
    /* Start with a gap of 100% */
  }

  100% {
    transform: translateX(-100%);
    /* Continue with a gap at the end */
  }
}

.Footer-slide>img {
  width: 100%;
  height: auto;

  object-fit: contain;
  /* Maintain image aspect ratio and fit within the slide */
  transition: transform 2s;
  box-shadow: 0px 0px 2px darkgray;
  border-radius: 5px;
  /* border: 0.1px solid gray; */
}

.Footer-slide>img:hover {
  transform: translateZ(20px);
}




@media (max-width: 450px) {
  .Footer-slide-track {
    margin-top: -4% !important;
    display: flex;
    width: 100%;
    animation: scroll 20s linear infinite;
    /* border: 1px solid; */
  }
}

@media (max-width: 768px) {

  .Footer-slider2 {
    height: 200px;
    /* Use viewport height for full screen */
    width: 100%;
    /* Full width */
    overflow: hidden;
    /* Hide overflowing content */
    position: relative;
    margin: auto;
    /* border: 1px solid ; */
    display: flex;
    justify-content: center;

  }
  .Footer-slide {
    height: 5.5rem !important;
    width: 250px;
    /* Each slide takes up 100% of the slider's width */
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
  }
  .Footer-slide>img {
    width: 78px !important;
    height: 78px !important;
    box-shadow: 0px 0px 2px darkgray !important;
    z-index: 0;
  }

  .Footer-slidertitle {

    display: block;

  }

  .footerphone {
    display: none !important;
  }





  @keyframes scroll {
    0% {
      transform: translateX(100%);
      /* Start with a gap of 100% */
    }

    100% {
      transform: translateX(-100%);
      /* Continue with a gap at the end */
    }
  }
}

@media (max-width: 600px) {
  .Footer-slider{
    height: 22vh;
   
}
.Footer-slide>img {
  width: 100px;
  height: 100px;
  overflow: visible;
  /* margin-top: 40px; */
}
.Footer-slide-track {
  margin-top: -4% ;
  display: flex;
  width: 100%;
  animation: scroll 20s linear infinite;
  /* border: 1px solid; */
}
.Footer-slidertitle{
  margin-top: 35px;
}
}
@media (min-width: 769px) and (max-width: 991px) {
  .Footer-slider2 {
    height: 200px;
    /* Use viewport height for full screen */
    width: 100%;
    /* Full width */
    overflow: hidden;
    /* Hide overflowing content */
    position: relative;
    margin: auto;
    /* border: 1px solid ; */
    display: flex;
    justify-content: center;

  }
  .Footer-slide {
    height: 8.5rem !important;
    width: 257px !important;
    display: flex;
    align-items: center;
    padding: 4px;
    perspective: 100px;
}
  .Footer-slide>img {
    
    box-shadow: 0px 0px 2px darkgray !important;
    z-index: 0;
  }

}


@media (min-width: 992px) and (max-width: 1500px) {
  
.Footer-corosul {


  position: relative;
  /* border: 1px solid; */
  /* height: 35vh; */
}


.Footer-slidertitle {
  position: absolute;
  top: -6%;
  left: 35%;
  /* color: #000; */
  font-family: Rubik;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 1.5rem */
  display: none;

}

.Footerpartner-text {
  color: #5195D2;
  font-family: Rubik;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;


}

.Footer-slider {
  height: 16vh;
  /* Use viewport height for full screen */
  width: 100%;
  /* Full width */
  overflow: hidden;
  /* Hide overflowing content */
  position: relative;
  margin: auto;
  /* border: 1px solid ; */
  display: flex;
  /* margin-top: 25px; */
  justify-content: center;
  /* background-color: rgba(156, 207, 235, 0.4); */
}

.Footer-slider2 {
  /* height: 200px; */
  /* Use viewport height for full screen */
  width: 100%;
  /* Full width */
  overflow: hidden;
  /* Hide overflowing content */
  position: relative;
  margin: auto;
  /* border: 1px solid ; */
  display: flex;
  justify-content: center;
  display: none;
}

.Footer-slidershadow {
  height: 12.5rem;
  width: 31rem;
  /* border: 1px solid red; */
  background: #9ccfeb;
  border-radius: 25%;

  /* position: absolute;
  filter: blur(16.25rem); */


}

.Footer-slide-track {
  /* margin-top: 5%; */
  display: flex;
  width: 100%;
  /* Adjust based on the number of images */
  animation: scroll 40s linear infinite ;

  /* border: 1px solid ; */
}

.Footer-slide-track2 {
  display: flex;
  width: 100%;
  /* Adjust based on the number of images */
  animation: scroll 50s linear infinite;
  animation-direction:normal;
  /* border: 1px solid ; */
}

.Footer-slide {
  height: 8.5rem;
  width: 250px;
  /* Each slide takes up 100% of the slider's width */
  display: flex;
  align-items: center;
  padding: 6px;
  perspective: 100px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
    /* Start with a gap of 100% */
  }

  100% {
    transform: translateX(-100%);
    /* Continue with a gap at the end */
  }
}

.Footer-slide>img {
  width: 100%;
  height: auto;

  object-fit: contain;
  /* Maintain image aspect ratio and fit within the slide */
  transition: transform 2s;
  box-shadow: 0px 0px 2px darkgray;
  border-radius: 5px;
  /* border: 0.1px solid gray; */
}

.Footer-slide>img:hover {
  transform: translateZ(20px);
}



}
/* 
@media (max-width: 1024px) {
  .Footer-slider{
    height: 22vh;
   
}
.Footer-slide>img {
  width: 78px;
  height: 78px;
}
.Footer-slide-track {
  margin-top: 5%;
  display: flex;
  width: 100%;
  animation: scroll 20s linear infinite;
}
.Footer-slidertitle{
  margin-top: 35px;
}
} */